<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0"> 
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
              <br>
              <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">{{cellar.name}}</p>
              <b-row class="justify-content-center">
                <p class="t-title">overview of all units in {{cellar.name }}</p>
              </b-row>
              <!-- <b-row class="justify-content-center">
                <p class="t-subtitle">{{cellar_id}}</p>
              </b-row> -->
              <b-row class="justify-content-center">
                <p class="t-subtitle">to select one, click - to add one, click on the plus sign</p>
              </b-row>
              <b-row class="justify-content-center" style="margin:0">
                <b-col 
                    class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                    xs="12" sm="6" md="4" lg="2" xl="2"
                    v-on:click="onEdit()"
                    style ="display:block; margin:20px !important"
                >
                  <b-img
                    width=300px
                    height=200px
                    :src="require('@/assets/img/plus.png')"
                  >
                  </b-img>
                </b-col>
                
                <b-col
                  v-for="(niche, index) in niches"
                  v-bind:item="niche"
                  v-bind:index="index"
                  v-bind:key="niche._id"
                  class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                  xs="12" sm="6" md="4" lg="2" xl="2"
                  style ="display:block; margin:20px !important"
                >  
                  <b-row  
                    style="width:100%"
                    v-on:click="onSelect(niche._id)"
                  >
                    <b-col class="justify-content-center" style="height:100%;width:auto;padding:0;position:sticky">
                      <div v-if="niche.image_files[0]" 
                        class="c-image-part" 
                        v-bind:style="{ 'background-image': 'url(' + niche.image_files[0].dataURL +')' }"
                      >
                      </div>
                      <div v-else
                        class="c-image-part"
                      />
                      <div class="c-text-part">
                        <p class="c-title" v-if="niche.code">{{niche.code}} - {{niche.name}}</p>
                        <p class="c-title" v-else>{{niche.name}}</p>
                        <p class="c-subtitle" v-if="niche.description">{{niche.description}}</p>
                        <p class="c-bottles">{{niche.number_of_bottles}}</p>
                      </div>
                      <b-button pill
                        class="edit-button"
                        v-on:click.stop="onEdit(niche._id)"
                        v-b-tooltip.hover title="edit unit"
                      >
                        <font-awesome-icon class="edit-symbol" icon="edit" />
                      </b-button>
                      <b-button pill
                        v-if="niche.entry_ids.length == 0"
                        class="delete-button"
                        v-on:click.stop="onDelete(niche._id)"
                        v-b-tooltip.hover title="delete unit"
                      >
                        <font-awesome-icon class="delete-symbol" icon="trash-alt" />
                      </b-button>
                    </b-col>
                  </b-row> 
                </b-col>
              </b-row>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>

    <b-modal id="deleteNiche" ref="deleteNiche" title="delete unit" @ok="deleteNiche"> 
      <b-row v-if="current_index >=0">
        <div style="width:300px;margin-left:auto;margin-right:auto">
        <b-col
          class="w-tile m-2 d-inline-flex justify-content-center"
          style ="display:block; margin:20px !important"
        >
            <div v-if="niches[current_index].image_files[0]" 
              class="c-image-part" 
              v-bind:style="{'background-image': 'url(' + niches[current_index].image_files[0].dataURL +')' }"
            >
            </div>
            <div v-else
              class="c-image-part"
            />           
            <div class="c-text-part">
              <p class="c-title" v-if="niches[current_index].code">{{niches[current_index].code}} - {{niches[current_index].name}}</p>
              <p class="c-title" v-else>{{niches[current_index].name}}</p>
              <p class="c-subtitle" v-if="niches[current_index].description">{{niches[current_index].description}}</p>
              <p class="c-bottles">{{niches[current_index].number_of_bottles}}</p>
            </div>
        </b-col>
        </div>
      </b-row>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO DELETE THIS UNIT?</p>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import Menu from './MenuDesktop.vue';

  import Cellars from '../Cellars.js'
  import Niches from '../Niches.js'

  export default {
    name: "Cellar",
    components: {
      NoAccess,
      Menu
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },
      currentNiches() {
        return this.$store.state.niches
      },
      currentNichesLoaded() {
        // Hack to be reviewed later
        // return this.$store.state.niches_loaded
        return false
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        cellar: {},
        cellar_id:0,

        niches: [],

        current_index:-1,

        inEdit: false,
      }
    }, 

    async mounted() {
      let local_debug = false

      if (local_debug || this.debug) console.log( "")
      if (local_debug || this.debug) console.log( "Cellar.mounted: START")

      let time_0 = new Date().getTime()
      
      // Load the Cellar
      if (this.currentCellarLoaded) {
        if (local_debug || this.debug) console.log("Cellar.mounted: CURRENT CELLAR LOADED")
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (local_debug || this.debug) console.log("Cellar.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      }
      else {
        if (local_debug || this.debug) console.log("Cellar.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
        this.cellar_id = this.$route.query.cellar_id
        await Cellars.getone(this.cellar_id)
        .then(response => {
          this.cellar = response
          delete this.cellar._id
          if (local_debug || this.debug) console.log("Cellar.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
        })
        .catch(error =>{
          if (local_debug || this.debug) console.log("Cellar.mounted: catch Cellar.getone with error: ", error)
        })

        this.$store.commit('setCellar',this.cellar)
        this.$store.commit('setCellarID',this.cellar_id)
        this.$store.commit('setCellarLoaded',true)

        if (local_debug || this.debug) console.log("Cellar.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
      }

      let time_1 = new Date().getTime()
      console.log("Loading Cellar: ", time_1 - time_0, " ms")

      // Load the Niches
      if (local_debug || this.debug) console.log("Cellar.mounted: This cellar has ", this.cellar.niche_ids.length, " niches:");
      if (this.currentNichesLoaded) {
        if (local_debug || this.debug) console.log("Cellar.mounted: CURRENT NICHES LOADED")
        this.niches = this.currentNiches
        if (local_debug || this.debug) console.log("Cellar.mounted: niches ", this.niches, " succesfully retrieved from store.")
        let time_2 = new Date().getTime()
        console.log("Loading Niches from STORE: ", time_2 - time_1, " ms")
      } else {
        if (local_debug || this.debug) console.log("Cellar.mounted: CURRENT NICHES NOT LOADED - QUERYING THE DATABASE")
        if (typeof this.cellar.niche_ids.length !== 0) {
          for (let i=0; i < this.cellar.niche_ids.length; i++) {
            await Niches.getone(this.cellar.niche_ids[i])
            .then(response=> {
              if (typeof response == "object") {
                this.niches.push(response);
                if (local_debug || this.debug) console.log("Cellar.mounted: Niche ", this.niches[i], " succesfully fetched from DB");
              } else {
                if (local_debug || this.debug) console.log("Cellar.mounted: Niches i=", i , " is not an object");
              }
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("Cellar.mounted: await Niches i=", i , " ", error);
            })
          }
          this.$store.commit('setNiches',this.niches)
          this.$store.commit('setNichesLoaded',true)
        }
        let time_2 = new Date().getTime()
        console.log("Loading Niches from DB: ", time_2 - time_1, " ms")
      }

      this.niches.sort(function(a, b) {
          if(a.code < b.code) { return -1; }
          if(a.code > b.code) { return 1; }
          return 0;
      });

      this.isLoading = false
      if (local_debug || this.debug) console.log( "Cellar.mounted: START")
    },

    methods: {
      onSelect(niche_id){
        if ( ! this.inEdit ) {
          this.$store.commit('setPage','NicheShow')
          this.$router.push({path:'/NicheShow', query: {cellar_id:this.$route.query.cellar_id,niche_id:niche_id }});
        }
      },
      onEdit(niche_id){
        this.inEdit = true;
        this.$store.commit('setPage','NicheEdit')
        if (typeof niche_id === "undefined") {
          this.$router.push({path:'/NicheEdit', query: {cellar_id:this.$route.query.cellar_id}});
        } else {
          this.$router.push({path:'/NicheEdit', query: {cellar_id:this.$route.query.cellar_id,niche_id:niche_id }});
        }
      },
      async onDelete(niche_id){
        let local_debug = false
        if (local_debug || this.debug) console.log("")
        if (local_debug || this.debug) console.log("Cellar.onDelete: START niche_id = ", niche_id)
        this.current_index = -1
        for ( let i=0 ;i<this.niches.length ; i++ ) {
          if (this.niches[i]._id == niche_id) {
            this.current_index = i
          }
        }
        if (this.current_index == -1) {
          if (local_debug || this.debug) console.log("Cellar.onDelete: SEVERE ERROR - stopping here.")
          alert("Severe error")
          return
        }
        
        if (local_debug || this.debug) console.log("Cellar.onDelete: STOP this.current_index = ", this.current_index)
        await this.$bvModal.show('deleteNiche')
      },

      async deleteNiche(){
        let local_debug = false
        if (local_debug || this.debug) console.log("")
        if (local_debug || this.debug) console.log("Cellar.deleteNiche: START")
        let this_niche_id = this.niches[this.current_index]._id
        this.cellar_id = this.$route.query.cellar_id;

        await Niches.delete(this_niche_id)
        .then(response => {
          if (local_debug || this.debug) console.log("Cellar.deleteNiche: niche with id ", this_niche_id, " deleted with response : ", response)
        })
        .catch(error => {
          if (local_debug || this.debug) console.log('Cellar in deleteNiche() : catch await Niches.delete ERROR : ' + error);
        })

        // Update cellar in the database
        let split_index = this.cellar.niche_ids.indexOf(this_niche_id)
        if (local_debug || this.debug) console.log("Cellar.deleteNiche: split_index = ", split_index)
        this.cellar.niche_ids.splice(split_index,1)
        await Cellars.update(this.cellar_id,this.cellar)
        .then(response => {
          if (local_debug || this.debug) console.log("Cellar.deleteNiche: cellar with id ", this.cellar_id, " updated with response : ", response)
        })
        .catch(error => {
          if (local_debug || this.debug) console.log('Cellar in deleteNiche() : catch await Cellars.update ERROR : ' + error);
        })

        // Update cellar in the store
        this.$store.commit('setCellar',this.cellar)
        if (local_debug || this.debug) console.log("Cellar.deleteNiche: cellar ", this.cellar_id, " updated in store")

        this.niches.splice(this.current_index,1);
        this.niches.sort(function(a, b) {
            console.log(a.code,'/',b.code)
            if(a.code < b.code) { return -1; }
            if(a.code > b.code) { return 1; }
            return 0;
        });
        this.current_index = -1;

        if (local_debug || this.debug) console.log("Cellar.deleteNiche: STOP")
      },
    
      doNothing() {
      }, 
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.c-image-part {
  background-image: url("../assets/img/niche-picture.jpg")
}
</style>