// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';
import { API } from './API.js';

import axios from 'axios';

// const url = WEBSERVER + ':' + PORT + '/api/niches/'

const url = API + '/niches/'
const debug = false;

class Niches {
    // get niches
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(niche=> ({
                        ...niche,
                        name: niche.name
                    }))
                );
                if (debug) console.log("Niches.get: data = " + data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one niche
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Niches.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Niches.getone: ',error)
            })
    }

    // create niche
    static create(postdata) {
        if (debug) console.log('Niches.create - url: ',url)
        if (debug) console.log('Niches.create - postdata: ',postdata)
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Niches.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Niches.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('Niches.update: id=',id)
        if (debug) console.log('Niches.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('Niches.update: ',response)
                return id
            })
            .catch(error => {
                console.log('Niches.update: ',error)
            })
    }

    // delete niche
    static delete(id) {
        return axios.delete(`${url}${id}`)
    } 
}

export default Niches