<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <LibraryMobile  v-if="matches" />
    <LibraryDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import LibraryMobile from "./Library_Mobile.vue";
  import LibraryDesktop from "./Library_Desktop.vue";
 
  export default {
    name: 'Desktop',
    components: {
      MatchMedia,
      LibraryMobile,
      LibraryDesktop,
    },
  }
</script> 