<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col sm="10" style="padding:0" align="center"> 
            <br>
            <p v-if="entry.niche_id == 'archived'" class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">
              <span v-on:click="gotoArchive()" class="w-cursor-click" ><u>archive</u></span> 
              > {{entry.name}} {{entry.vintage}}
            </p>
            <p v-else class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">
              <span v-on:click="gotoCellar()" class="w-cursor-click" ><u>{{cellar.name}}</u></span> 
              > <span v-on:click="gotoNiche()" class="w-cursor-click"><u>{{niche.name}}</u></span> 
              > {{entry.name}} {{entry.vintage}}
            </p>
            <div style="width: 80%; margin: 10vh 10vw 0 10vw !important">
              <!-- <h1 class="preview-text"> {{ entry_id }}</h1>  -->
              <!-- <h1 class="preview-text"> {{ previousPage }}</h1> -->
              <h1 class="preview-title"> {{ entry.name }} {{ entry.vintage }}</h1> 
              <b-row>
                <b-col style="text-align:left;padding-left:15px">
                  <h5 class="preview-subtitle"> {{ entry.type }}</h5> 
                </b-col>
                <b-col>
                  <h1 v-if="entry.niche_id != 'archived'" class="preview-subtitle" style="text-align:right">in stock : {{this.entry.number}}</h1>
                  <h1 v-else class="preview-subtitle" style="text-align:right">archived: {{this.entry.archived_date}}</h1>
                </b-col>
              </b-row>
            </div>
            <div style="width: 80%; margin: 2vh 10vw 0 10vw !important">
              <b-row>
                <b-tabs class="w-tabs" justified fill>
                  <div class="w-tabbox">
                    <b-tab class="w-tab" title="wine" active>
                      <b-row>
                        <b-col md="4">
                          <b-row class="justify-content-center" v-if="this.entry.image_files && this.entry.image_files.length > 0">
                            <b-carousel
                              id="carousel-fade"
                              :interval="4000"
                              controls
                              indicators
                              background="#ffffff"
                              img-width="200"
                              img-height="300"
                              style="text-shadow: 1px 1px 2px #333;"
                            >
                              <b-carousel-slide v-for="image_file in this.entry.image_files" v-bind:key="image_file.dataURL">
                                <template v-slot:img>
                                  <b-img
                                    class="fluid-grow"
                                    max-width="100"
                                    height="300"
                                    v-bind:src="image_file.dataURL"
                                  />
                                </template>
                              </b-carousel-slide>
                            </b-carousel>
                          </b-row>
                          <b-row v-else>
                            <!-- <b-img center
                              class="fluid-grow"
                              max-width="100"
                              height="300"
                              :src="require('@/assets/img/bottle.png')"
                            ></b-img> -->
                            <b-img center v-if="entry.color == 'Red'"
                              class="fluid-grow"
                              max-width="100"
                              height="300"
                              :src="require('@/assets/img/bottle_red.png')"
                            ></b-img>
                            <b-img center v-else-if="entry.color == 'White'"
                            class="fluid-grow"
                              max-width="100"
                              height="300"
                              :src="require('@/assets/img/bottle_white.png')"
                            ></b-img>
                            <b-img center v-else-if="entry.color == 'Rosé'"
                            class="fluid-grow"
                              max-width="100"
                              height="300"
                              :src="require('@/assets/img/bottle_rose.png')"
                            ></b-img>
                            <b-img center v-else
                            class="fluid-grow"
                              max-width="100"
                              height="300"
                              :src="require('@/assets/img/bottle.png')"
                            ></b-img>
                          </b-row>  
                        </b-col> 
                        <b-col md="8">
                          <div>
                            <p class="preview-text"><span v-if="this.entry.region !=''">{{this.entry.region}}, </span>{{this.entry.country}}</p>
                            <p class="preview-text"><span v-if="this.entry.appellation != ''">{{this.entry.appellation}}, </span>{{this.entry.classification}}</p>
                          </div>
                          <div v-for="(value, key) in this.entry" v-bind:key="key">
                            <div v-if="key==='tasting_notes' && value.length>0">
                              <hr>
                              <p  class="w-text" style="font-style:italic">{{value}}</p>
                            </div>  
                            <div v-if="key==='grapes' || key==='vines' || key==='vinification' || key === 'yields' || key === 'aging' || key === 'alcohol'">
                              <p  class="w-text" v-if="value.length>0"><b>{{ key }}</b> : {{value}}</p>
                            </div>  
                          </div>

                          <div style="width:100%;position:absolute; bottom:0px; right: 15px">
                            <b-row>
                              <b-col style="text-align:left;padding-left:47px">
                                <b-button
                                  v-if="entry.niche_id != 'archived'"
                                  class="w-button-grey"  
                                  @click="showModal_transferEntry" 
                                  v-b-tooltip.hover title="transfer entry to other niche"
                                >
                                transfer
                              </b-button>
                              </b-col>
                              <b-col style="text-align:right">
                                <b-button v-on:click="onEdit()" class="w-button-green">edit</b-button>
                              </b-col>
                            </b-row>
                          </div>

                          <!-- <div style="position:absolute; bottom:0px; right: 15px">
                            <b-button
                              v-if="entry.niche_id != 'archived'"
                              class="w-button-grey"  
                              @click="showModal_transferEntry" 
                              v-b-tooltip.hover title="transfer entry to other niche"
                            >
                            transfer
                            </b-button>
                          </div> -->
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab class="w-tab" title="transactions">
                      <b-table 
                        ref="transactionsTable"
                        class="w-table"
                        sticky-header=true
                        selectable
                        select-mode="single"
                        :items="transactions" 
                        :fields="transaction_fields" 
                        @row-selected="transactionSelect"
                      >
                        <!-- <template #cell(action)="data">
                          <span v-html="data.value"></span>
                        </template> -->
                      </b-table>
                      <b-row v-if="entry.niche_id != 'archived'">
                        <b-col class="text-left">
                          <b-button v-if="transaction_id == 0 && entry.number > 0"  @click="showModal_removeBottles" class="w-button-pink">-</b-button>
                        </b-col>
                        <div v-if="transaction_id != 0 && transaction.number != 0">
                          <b-row>
                            <b-col class="text-center">
                              <b-img 
                                :src="require('@/assets/img/delete.png')" 
                                @click="showModal_deleteTransaction"
                                width="30px"
                                height="30px"
                                
                              >
                              <!-- v-b-tooltip.hover  class="w-tooltip" title="delete" -->
                              </b-img>
                            </b-col>
                            <b-col class="text-center">
                              actions
                            </b-col>
                            <b-col class="text-center">
                              <b-img 
                                :src="require('@/assets/img/edit.png')" 
                                @click="showModal_editTransaction" 
                                width="30px"
                                height="30px"
                                v-b-tooltip.hover  class="w-tooltip" title="edit transaction"
                              >
                              </b-img>
                            </b-col>
                          </b-row>
                        </div>
                        <b-col class="text-right">
                          <b-button v-if="transaction_id == 0" @click="showModal_addBottles" class="w-button-green">+</b-button>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab class="w-tab" title="tasting notes">
                      <b-table
                        ref="tasting_notesTable"
                        class="w-table"
                        selectable
                        select-mode="single"
                        :items="tasting_notes" 
                        :fields="tasting_note_fields" 
                        @row-selected="tasting_noteSelect"
                      >
                        <!-- <template #cell(action)="data">
                          <span v-html="data.value"></span>
                        </template> -->
                      </b-table>
                      <b-row v-if="entry.niche_id != 'archived'">
                        <b-col class="text-left">
                          <!-- <b-button v-if="tasting_note_id == 0 && entry.number > 0"  @click="showModal_removeBottles" class="w-button-pink">-</b-button> -->
                        </b-col>
                        <b-col class="text-center">
                          <b-img 
                            :src="require('@/assets/img/delete.png')"
                            v-if="tasting_note_id != 0" 
                            @click="showModal_deleteTastingNote"
                            width="30px"
                            height="30px"
                          >
                          </b-img>
                        </b-col>
                        <b-col class="text-center" v-if="tasting_note_id != 0">
                          action
                        </b-col>
                        <b-col class="text-center">
                          <b-img 
                            :src="require('@/assets/img/edit.png')"
                            v-if="tasting_note_id != 0"
                            @click="showModal_editTastingNote" 
                            width="30px"
                            height="30px"
                          >
                          </b-img>
                        </b-col>
                        <b-col class="text-right">
                          <b-button v-if="tasting_note_id == 0" @click="showModal_addTastingNote" class="w-button-green">add</b-button>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <!-- <b-tab class="w-tab" title="value">
                      <b-table
                        ref="tasting_notesTable"
                        class="w-table"
                        selectable
                        select-mode="single"
                        :items="tasting_notes" 
                        :fields="tasting_note_fields" 
                        @row-selected="tasting_noteSelect"
                      >
                      </b-table>
                      <b-row v-if="entry.niche_id != 'archived'">
                        <b-col class="text-left">
                        </b-col>
                        <b-col class="text-center">
                          <b-img 
                            :src="require('@/assets/img/delete.png')"
                            v-if="tasting_note_id != 0" 
                            @click="showModal_deleteTastingNote"
                            width="30px"
                            height="30px"
                          >
                          </b-img>
                        </b-col>
                        <b-col class="text-center" v-if="tasting_note_id != 0">
                          action
                        </b-col>
                        <b-col class="text-center">
                          <b-img 
                            :src="require('@/assets/img/edit.png')"
                            v-if="tasting_note_id != 0"
                            @click="showModal_editTastingNote" 
                            width="30px"
                            height="30px"
                          >
                          </b-img>
                        </b-col>
                        <b-col class="text-right">
                          <b-button v-if="tasting_note_id == 0" @click="showModal_addTastingNote" class="w-button-green">add</b-button>
                        </b-col>
                      </b-row>
                    </b-tab> -->
                  </div>
                </b-tabs>
              </b-row>
              <br>
              <b-row>
                <b-col style="text-align:left;padding-left:0px">
                  <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
                </b-col>
              </b-row>
              <br>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <!-- Modals -->
    <b-modal id="transferEntry" title="transfer entry" @ok="transferEntry" @cancel="cancelTransfer">
      <b-form aria-autocomplete="off">
        <b-row>
          <div style="width:300px;margin-left:auto;margin-right:auto">
            <b-col
              class="w-tile m-2 d-inline-flex justify-content-center"
              style ="display:block; margin:20px !important"
            >
              <b-row 
                style="width:100%"
              >
                <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                  <b-img center v-if="entry.image_files[0]"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="entry.image_files[0].dataURL" 
                  ></b-img>
                  <!-- <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img> -->
                  <div v-else>
                    <b-img center v-if="entry.color == 'Red'"
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle_red.png')"
                    ></b-img>
                    <b-img center v-else-if="entry.color == 'White'"
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle_white.png')"
                    ></b-img>
                    <b-img center v-else-if="entry.color == 'Rosé'"
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle_rose.png')"
                    ></b-img>
                    <b-img center v-else
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle.png')"
                    ></b-img>
                  </div>
                </b-col>
                <b-col cols="9" class="d-table-cell">
                  <div>
                    <p class="t-title">{{entry.vintage}}</p>
                    <p class="t-title2">{{entry.name}}</p>
                    <p class="t-subtitle" v-if="entry.type">{{entry.type}}</p>
                    <hr>
                    <p class="t-text" v-if="entry.appellation">{{entry.appellation}}</p>
                    <p class="t-text" v-if="entry.classification">{{entry.classification}}</p>
                    <p class="c-bottles">{{entry.number}}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
        <b-form-group>
          <label class="w-label" for="input-date">date</label>
          <b-form-datepicker 
            class="w-datepicker mb-2" 
            id="input-date" 
            v-model="transaction.date"
            v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
          >
          </b-form-datepicker>
          <b-form-invalid-feedback id="input-live-feedback">
            {{dateFeedback}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-fromNiche">from niche</label>
          <b-form-input
            id="input-fromNiche"
            class="w-input"
            v-model="formated_niche_name"
            disabled
          ></b-form-input>
        </b-form-group>        
        <b-form-group style="text-align:left">
          <label class="w-label w-required" for="nicheList">to niche</label>        
          <model-select
            class="w-select"
            :options="transfer_niches"
            v-model="to_niche_id"
            v-bind:class="{'form-control':true, 'is-invalid' : !this.nicheValid}"
            aria-describedby="input-live-help input-live-feedback"
          >
          </model-select>
          <b-form-invalid-feedback id="input-live-feedback">
            {{nicheFeedback}}
          </b-form-invalid-feedback>  
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="addBottles" title="add bottles" @ok="addBottles">
      <b-form aria-autocomplete="off">
        <b-form-group>
          <label class="w-label" for="input-date">date</label>
          <b-form-datepicker 
            class="w-datepicker mb-2" 
            id="input-date" 
            v-model="transaction.date"
            v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
          >
          </b-form-datepicker>
          <b-form-invalid-feedback id="input-live-feedback">
            {{dateFeedback}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-number">number</label>
          <b-form-spinbutton 
            class="w-spinbutton" 
            id="numberSpin" 
            v-model="transaction.number" 
            v-bind:min="this.min_number" 
            v-bind:max="this.max_number"
            v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
          >
          </b-form-spinbutton>
          <b-form-invalid-feedback id="input-live-feedback">
            {{numberFeedback}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-description">description</label>
          <b-form-input
            id="input-description"
            class="w-input"
            v-model="transaction.description"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="enter description"
              ></b-form-input>
        </b-form-group>
        <b-form-group>
          <label for="input-price">price</label>
          <b-form-input
            id="input-price"
            class="w-input"
            v-model="transaction.price"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="12.34 CUR, optional (incl || excl)"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="removeBottles" title="remove bottles" @ok="removeBottles">
      <b-form aria-autocomplete="off">
        <b-form-group>
          <label class="w-label" for="input-date">date</label>
          <b-form-datepicker 
            class="w-datepicker mb-2" 
            id="input-date" 
            v-model="transaction.date"
            v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
          >
          </b-form-datepicker>
          <b-form-invalid-feedback id="input-live-feedback">
            {{dateFeedback}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-number">number</label>
          <b-form-spinbutton 
            class="w-spinbutton" 
            id="numberSpin" 
            v-model="transaction.number" 
            v-bind:min="this.min_number" 
            v-bind:max="this.max_number"
            v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
          >
          </b-form-spinbutton>
          <b-form-invalid-feedback id="input-live-feedback">
            {{numberFeedback}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-description">description</label>
          <b-form-input
            id="input-description"
            class="w-input"
            v-model="transaction.description"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Enter description"
              ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="editTransaction" v-if="transaction_id !=0" title="edit transaction" @ok="editTransaction">
      <b-form aria-autocomplete="off">
        <b-form-group>
            <label class="w-label" for="input-number">date</label>
            <b-form-datepicker class="w-datepicker mb-2" id="input-dater" v-model="transaction.date"></b-form-datepicker>
            <b-form-invalid-feedback id="input-date">
              {{dateFeedback}}
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
            <label class="w-label" for="input-number">number</label>
            <b-form-spinbutton 
              class="w-spinbutton" 
              id="input-number" 
              v-model="transaction.number"
              v-if="transaction.number >0" 
              min="1" 
              max="1000"
            >
            </b-form-spinbutton>
            <b-form-spinbutton 
              class="w-spinbutton" 
              id="input-number" 
              v-model="transaction.number"
              v-else 
              v-bind:min="this.min_number" 
              v-bind:max="this.max_number"
            >
            </b-form-spinbutton>
        </b-form-group>
        <b-form-group>
            <label class="w-label" for="input-description">description</label>
              <b-form-input
                id="input-description"
                class="w-input"
                v-model="transaction.description"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Enter description"
              ></b-form-input>
        </b-form-group>
        <b-form-group v-if="transaction.number > 0 ">
            <label class="w-label" for="input-price">price</label>
            <b-form-input
              id="input-price"
              class="w-input"
              v-model="transaction.price"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Enter price"
            ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="deleteTransaction" v-if="transaction_id !=0" title="delete transaction" @ok="deleteTransaction">
      <p class="t-title " v-if="transaction.description">{{transaction.description}}</p>
      <p class="t-subtitle">{{formated_TA_date}}</p>
      <p class="w-text"><b>number :</b> {{transaction.number}}</p>
      <p class="w-text" v-if="transaction.price"><b>price :  </b>{{transaction.price}}</p>       
      <hr>
      <p class="t-title text-center"> ARE YOU SHURE YOU WANT TO DELETE THIS TRANSACTION?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="addTastingNote" title="add tasting note" @ok="addTastingNote">
      <b-form aria-autocomplete="off" >
        <b-form-group v-if="user_id != 0">
          <label class="w-label">owner: {{user.firstname}} {{user.lastname}}</label>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-date">date</label>
              <b-form-datepicker 
                class="w-datepicker mb-2" 
                id="input-date" 
                v-model="tasting_note.date"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
              >
              </b-form-datepicker>
              <b-form-invalid-feedback id="input-live-feedback">
                {{dateFeedback}}
              </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-name">name</label>
          <b-form-input
            id="input-name"
            class="w-input"
            v-model="tasting_note.name"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Enter name"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:0">
          <b-form-radio-group
            id="radiobuttons"
            class = "w-radiobuttons"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            buttons
          ></b-form-radio-group>
        </b-form-group>
        <hr style="margin-top:0">
        <div v-if="selected == 'short'">
          <b-form-group>
            <label class="w-label" for="input-tastingnote">tasting note</label>
            <b-form-textarea
              id="input-conclusions"
              class="w-textarea"
              rows="3"
              max-rows="10"
              v-model="tasting_note.conclusions"
              v-bind:class="{'form-control':true, 'is-invalid' : !this.tasting_note_isValid}"
              aria-describedby="input-live-help input-live-feedback"
            ></b-form-textarea>
            <b-form-invalid-feedback id="input-live-feedback">
            Enter at least 2 letters
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div v-else>
          <b-row style="margin-left:0px;margin-right:5px">
            <b-col class="text-left" style="padding:0">
              <b-img v-if="leftVisible"
                class="w-cursor-click"
                width=30px
                height=30px
                :src="require('@/assets/img/left.png')"
                @click="onLeft"
              >
              </b-img>
            </b-col>                
            <b-col cols="8" class="text-center" style="padding:0">
              <p v-if="this.pageCount == 0" 
                class="current-page w-cursor-default"
              >
                <b>appearance</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onAppearance"
              >
                appearance -
              </p>

              <p v-if="this.pageCount == 1" 
                class="current-page w-cursor-default"
              >
                <b>nose</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onNose"
              >
                nose -
              </p>

              <p v-if="this.pageCount == 2" 
                class="current-page w-cursor-click"
              >
                <b>palate</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onPalate"
              >
                palate -
              </p>

              <p v-if="this.pageCount == 3" 
                class="current-page w-cursor-click"
              >
                <b>conclusions</b>
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onAftertaste"
              >
                conclusions
              </p>

              <p class="w-smalltext" style="text-align:center;">page {{this.pageCount+1}}/{{this.maxPage}}</p>
            </b-col>
            <b-col class="text-right" style="padding:0">
              <b-img v-if="rightVisible"
                class="w-cursor-click"
                width=30px
                height=30px
                :src="require('@/assets/img/right.png')"
                @click="onRight"
              >
              </b-img>
            </b-col>
            <br>                
          </b-row>
          <b-row style="margin-left:0px;margin-right:5px"> 
            <b-form-group v-if="pageCount == 0" class="w-page"> 
              <b-form-textarea
                id="input-appearance"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.appearance"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter appearance"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group v-if="pageCount == 1" class="w-page">
              <b-form-textarea
                id="input-nose"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.nose"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter nose"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group v-if="pageCount == 2" class="w-page">
              <b-form-textarea
                id="input-palate"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.palate"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter palate"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group v-if="pageCount == 3" class="w-page">
              <b-form-textarea
                id="input-conclusions"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.conclusions"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter conclusions"
              ></b-form-textarea>
            </b-form-group>
          </b-row>
        </div>
        <hr>
        <b-form-group>
          <b-row>
            <b-col>
              <label class="w-label" for="input-score">score</label> 
            </b-col>
            <b-col>
              <p class="item-title" style="text-align:right"><b>{{tasting_note.score}}/100</b></p>
            </b-col>
          </b-row>
          <range-slider
            class="w-slider"
            min="0"
            max="100"
            v-model="tasting_note.score"
          >
          </range-slider>
          <p class="item-title" style="text-align:right"><b>{{letter_score[Math.min(Math.round(tasting_note.score*15/100-0.50),14)]}}</b></p>
        </b-form-group>        
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="editTastingNote" v-if="tasting_note_id !=0" title="edit tasting note" @ok="editTastingNote">
      <b-form aria-autocomplete="off" >
        <b-form-group v-if="user_id != 0">
          <label class="w-label">owner: {{user.firstname}} {{user.lastname}}</label>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-date">date</label>
          <b-form-datepicker 
            class="w-datepicker mb-2" 
            id="input-date" 
            v-model="tasting_note.date"
            v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
          >
          </b-form-datepicker>
          <b-form-invalid-feedback id="input-live-feedback">
            {{dateFeedback}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label class="w-label" for="input-name">name</label>
          <b-form-input
            id="input-name"
            class="w-input"
            v-model="tasting_note.name"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Enter name"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom:0">
          <b-form-radio-group
            id="radiobuttons"
            class = "w-radiobuttons"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            buttons
          ></b-form-radio-group>
        </b-form-group>
        <hr style="margin-top:0">
        <div v-if="selected == 'short'">
          <b-form-group>
            <label class="w-label" for="input-tastingnote">tasting note</label>
            <b-form-textarea
              id="input-conclusions"
              class="w-textarea"
              rows="3"
              max-rows="10"
              v-model="tasting_note.conclusions"
              v-bind:class="{'form-control':true, 'is-invalid' : !this.tasting_note_isValid}"
              aria-describedby="input-live-help input-live-feedback"
            ></b-form-textarea>
            <b-form-invalid-feedback id="input-live-feedback">
            Enter at least 2 letters
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div v-else>
          <b-row style="margin-left:0px;margin-right:5px">
            <b-col class="text-left" style="padding:0">
              <b-img v-if="leftVisible"
                class="w-cursor-click"
                width=30px
                height=30px
                :src="require('@/assets/img/left.png')"
                @click="onLeft"
              >
              </b-img>
            </b-col>                
            <b-col cols="8" class="text-center" style="padding:0">
              <p v-if="this.pageCount == 0" 
                class="current-page w-cursor-default"
              >
                <b>appearance</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onAppearance"
              >
                appearance -
              </p>

              <p v-if="this.pageCount == 1" 
                class="current-page w-cursor-default"
              >
                <b>nose</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onNose"
              >
                nose -
              </p>

              <p v-if="this.pageCount == 2" 
                class="current-page w-cursor-click"
              >
                <b>palate</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onPalate"
              >
                palate -
              </p>

              <p v-if="this.pageCount == 3" 
                class="current-page w-cursor-click"
              >
                <b>conclusions</b>
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onAftertaste"
              >
                conclusions
              </p>

              <p class="w-smalltext" style="text-align:center;">page {{this.pageCount+1}}/{{this.maxPage}}</p>
            </b-col>
            <b-col class="text-right" style="padding:0">
              <b-img v-if="rightVisible"
                class="w-cursor-click"
                width=30px
                height=30px
                :src="require('@/assets/img/right.png')"
                @click="onRight"
              >
              </b-img>
            </b-col>
            <br>                
          </b-row>
          <b-row style="margin-left:0px;margin-right:5px"> 
            <b-form-group v-if="pageCount == 0" class="w-page"> 
              <b-form-textarea
                id="input-appearance"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.appearance"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter appearance"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group v-if="pageCount == 1" class="w-page">
              <b-form-textarea
                id="input-nose"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.nose"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter nose"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group v-if="pageCount == 2" class="w-page">
              <b-form-textarea
                id="input-palate"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.palate"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter palate"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group v-if="pageCount == 3" class="w-page">
              <b-form-textarea
                id="input-conclusions"
                class="w-textarea"
                rows="3"
                max-rows="10"
                v-model="tasting_note.conclusions"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="enter conclusions"
              ></b-form-textarea>
            </b-form-group>
          </b-row>
        </div>
        <hr>
        <b-form-group>
          <b-row>
            <b-col>
              <label class="w-label" for="input-score">score</label> 
            </b-col>
            <b-col>
              <p class="item-title" style="text-align:right"><b>{{tasting_note.score}}/100</b></p>
            </b-col>
          </b-row>
          <range-slider
            class="w-slider"
            min="0"
            max="100"
            v-model="tasting_note.score"
          >
          </range-slider>
          <p class="item-title" style="text-align:right"><b>{{letter_score[Math.min(Math.round(tasting_note.score*15/100-0.50),14)]}}</b></p>
        </b-form-group>        
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="deleteTastingNote" v-if="tasting_note_id !=0" title="delete tasting note" @ok="deleteTastingNote">
      <div>
        <!-- <p class="t-title">{{tasting_note.date}}</p> -->
        <p class="t-title">{{formated_TN_date}}</p>
        <p class="t-title" v-if="entry.type !=''">{{entry.name}} {{entry.vintage}}</p>
        <p class="t-title" v-else>{{entry.name}} {{entry.vintage}}</p>
        <p class="t-title " v-if="tasting_note.name">{{tasting_note.name}}</p>
        <p class="t-subtitle">{{tasting_note.conclusions}}</p>
        <p class="w-text"><b>score : </b>{{tasting_note.score}}</p>    
      </div>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO DELETE THIS TASTING NOTE?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              delete
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import Menu from './MenuDesktop.vue';

  import Entries from '../Entries.js'
  import Transactions from '../Transactions.js'
  import Tastings from '../Tastings.js'
  import TastingNotes from '../TastingNotes.js'
  import Niches from '../Niches.js'

  import { ModelSelect } from 'vue-search-select';
  import RangeSlider from "vue-range-slider";
  import "vue-range-slider/dist/vue-range-slider.css";

  import formatDate from '../assets/js/formatDate.js';

  export default {
    name: "EntryShow",
    components: {
      NoAccess,
      Menu,
      ModelSelect,
      RangeSlider
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },

      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },

      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },

      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },

      geography: function() {
        let this_geography = this.entry.country;
        if (this.entry.region != '') {
          this_geography = this.entry.region + ', ' + this_geography;
        }
        if (this.entry.appellation != '' ) {
          this_geography = this.entry.appellation + ', '+ this_geography;
        }
        return this_geography;
      },
      formated_TA_date() {
        let date = new Date(this.transaction.date)
        let Month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul','Aug','Sep','Oct','Nov','Dec']
        let d = date.getDate();
        let m = date.getMonth();
        let y = date.getFullYear();
        
        let format_date = d + ' ' + Month[m] + ' ' + y;
        return format_date
      },
      formated_TN_date() {
        let date = new Date(this.tasting_note.date)
        let Month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul','Aug','Sep','Oct','Nov','Dec']
        let d = date.getDate();
        let m = date.getMonth();
        let y = date.getFullYear();
        
        let format_date = d + ' ' + Month[m] + ' ' + y;
        return format_date
      },
      formated_niche_name() {
        return this.niche.code + ' - ' + this.niche.name
      },
    },
    data() {
      return {
        debug: false,

        isLoading: true,

        user:{},
        user_id:0,

        cellar:{},
        cellar_id:0,

        entry: {
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          aging:'',
          alcohol:'',
          image_files:[],
          wine_id:0,
          winery_id:0,

          number:1,
          vintage:0,
          date:'',
          name:'',

          cellar_id:0,
          niche_id:0,

          transaction_ids:[],
          tasting_note_ids:[],
        },
        entry_id:0,
        transactions:[],
        tasting_notes:[],

        tasting: {},
        tasting_id:0,

        niche:{},
        niche_id:0,

        to_niche:{},
        to_niche_id:0,

        niches:[],
        transfer_niches:[],

        // TRANSACTION
        selectedTransaction:'',
        transaction:{
          date:'',
          number:0,
          description:'',
          price:'',
          entry_id:0
        },
        transaction_id:0,
        transaction_type:'',
        
        min_number:-1000,
        max_number:1000,
        dateValid: true,
        dateFeedback: '',
        numberValid: true,
        numberFeedback: '',

        transaction_types:[
          {value:"add",text:"add"},
          {value:"remove",text:"remove"}
        ],
        
        transaction_fields: [
          {key: 'date',sortable: true,
            formatter: (value) => {
              return(formatDate(new Date(value)))}},
          {key: 'number',sortable: false, class: 'text-center'},
          {key: 'description',sortable: false},
          {key: 'price',sortable: false},
        ],

        // TASTING NOTE
        tasting_note:{
          name:'',
          date:'',
          owner:'',
          user_id:'',

          appearance:'',
          nose:'',
          palate:'',
          conclusions:'',
          score:0,

          entry_id:0,
          item_id:0,

          tasting_id:0,
          
          onWishList: false,
        },
        tasting_note_id:0,
        selectedTastingNote:'',

        tasting_note_fields: [
          {key: 'date',sortable: true,
            formatter: (value) => {
              return(formatDate(new Date(value)))}},
          {key: 'name',sortable: false},
          {key: 'score', sortable: false}
        ],

        selected: 'short',
        options: [
          { text: 'short', value: 'short' },
          { text: 'long', value: 'long' },
        ],

        nicheValid: true,
        nicheFeedback: "",

        pageCount: 0,
        maxPage: 4,
        leftVisible: false,
        rightVisible: true,

        scoreValid:true,
        scoreFeedback:'',
        letter_score:["E-","E","E+","D-","D","D+","C-","C","C+","B-","B","B+","A-","A","A+"],

        tasting_note_isValid : true,
      }
    }, 

    async mounted() {
      let local_debug = false
      
      if (local_debug || this.debug) console.log("EntryShow.mounted: START")

      window.onpopstate = function(event) {
        console.log("EntryShow.mounted: Browser Back button pressed - going onBack")
        this.onBack
      };

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      if (this.currentCellarLoaded) {
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (local_debug || this.debug) console.log("EntryShow.mounted: Cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      } else {
        console.log("EntryShow.mounted: FATAL ERROR cellar NOT loaded.")
      }

      let entry_id = this.$route.query.entry_id;
      if (typeof entry_id !== "undefined") {
        await Entries.getone(entry_id)
        .then(response=> {
          this.entry = response;
          this.entry_id = this.entry._id
          delete this.entry._id
          if (local_debug || this.debug) console.log("EntryShow.mounted: Entry ", this.entry, " with id ", this.entry_id, " succesfully fetched from DB");
        })
        .catch(error=> {
          if (local_debug || this.debug) console.log("EntryShow.mounted: await Entries.getone", error);
        })
      } else {
        if (local_debug || this.debug) console.log("EntryShow.mounted: ERROR NO entry_id = ", entry_id);
      }

      // All the niches
      this.niches=[];
      if (typeof this.cellar.niche_ids.length !== 0) {
        for (let i=0; i < this.cellar.niche_ids.length; i++) {
          await Niches.getone(this.cellar.niche_ids[i])
          .then(response=> {
            if (typeof response == "object" && response._id != this.niche_id) {
              this.niches.push({value:response._id,text:response.code + ' - ' + response.name})
              if (local_debug || this.debug) console.log("EntryShow.showModal_transferEntry: Niche ", this.niches[i], " succesfully fetched from DB");
            } else {
              if (local_debug || this.debug) console.log("EntryShow.showModal_transferEntry: Niches i=", i , " is not an object");
            }
          })
          .catch(error=> {
            if (local_debug || this.debug) console.log("EntryShow.showModal_transferEntry: await Niches i=", i , " ", error);
          })
        }
        if (this.niches.length > 0) {
          this.niches.sort((a,b)=>(a.text>b.text)?1:-1);
        }
      } 

      // Current Niche
      if (typeof this.entry.niche_id != "undefined" && this.entry.niche_id != "archived") {
        await Niches.getone(this.entry.niche_id)
        .then(response=> {
          this.niche = response;
          this.niche_id = this.niche._id
          delete this.niche._id
          if (local_debug || this.debug) console.log("EntryShow.mounted: Niche ", this.niche, " with id ", this.niche_id, " succesfully fetched from DB");
        })
        .catch(error=> {
          if (local_debug || this.debug) console.log("EntryShow.mounted: await Niches.getone", error);
        })
      } else {
        if (local_debug || this.debug) console.log("EntryShow.mounted: NO entry.niche_id = ", this.entry.niche_id);
      }

      // Add Transactions belonging to this Entry
      if (this.entry.transaction_ids.length > 0) {
        for (let i = 0 ; i < this.entry.transaction_ids.length ; i++ ) {
          if (local_debug || this.debug) console.log("EntryShow.mounted: this.entry.transaction_ids[",i,"] = ", this.entry.transaction_ids[i])
          if (this.entry.transaction_ids[i].length == 24) {
            await Transactions.getone(this.entry.transaction_ids[i])
            .then(response=> {
              //response.action='<input type="image" src="img/edit.688aa67d.png" alt="Edit" width="20" height="20" style="margin-right:5px" onclick="onEdit()"><input type="image" src="img/delete.85c19728.png" alt="Delete" width="20" height="20">'            
              this.transactions.push(response);
              if (local_debug || this.debug) console.log( "EntryShow.mounted: Transaction ", response, " succesfully added");
            })
            .catch(error=>{
              if (local_debug || this.debug) console.log("EntryShow.mounted: await Transactions", error);
            })
          } else {
            console.log("EntryShow.mounted: ERROR in this.entry_id = ", this.entry_id);
            console.log("EntryShow.mounted: ERROR in this.entry.transaction_ids[",i,"] = ", this.entry.transaction_ids[i]);
            console.log("EntryShow.mounted: AUTOCORRECT");
            this.entry.transaction_ids.splice(i,1)
          }
        }
        this.transactions.sort(function(a, b) {
          if(a.date < b.date) { return -1; }
          if(a.date > b.date) { return 1; }
          return 0;
        });
      } else {
        if (local_debug || this.debug) console.log("EntryShow.mounted: no transactions.");
      }

      if (typeof this.entry.niche_id != "undefined" && this.entry.niche_id != "archived") await this.recalcNumber();

      let today = new Date();
      // this.transaction.date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' +today.getDate();
      // this.tasting_note.date = today.getFullYear() + '-'+ (today.getMonth()+1) + '-' +today.getDate();

      this.transaction.date = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");
      this.tasting_note.date = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");

      // Add Tasting Notes belonging to this Entry
      if (this.entry.tasting_note_ids.length > 0) {
        for (let i = 0 ; i < this.entry.tasting_note_ids.length ; i++ ) {
          if (local_debug || this.debug) console.log("EntryShow.mounted: this.entry.tasting_note_ids[",i,"]=", this.entry.tasting_note_ids[i]);
          if (this.entry.tasting_note_ids[i] !== null) {
            await TastingNotes.getone(this.entry.tasting_note_ids[i])
            .then(response=> {
              this.tasting_notes.push(response);
              if (local_debug || this.debug) console.log( "EntryShow.mounted: TastingNotes ", response, " succesfully added");
            })
            .catch(error=>{
              if (local_debug || this.debug) console.log("EntryShow.mounted: await TastingNotes.getone", error);
            })
          } else {
            if (local_debug || this.debug) console.log("EntryShow.mounted: ERROR this.entry.tasting_note_ids[",i,"]=", this.entry.tasting_note_ids[i]);
          }
        }
        this.tasting_notes.sort(function(a, b) {
          if(a.date < b.date) { return -1; }
          if(a.date > b.date) { return 1; }
          return 0;
        });        
      } else {
        if (local_debug || this.debug) console.log("EntryShow.mounted: no tasting notes.");
      }

      // Load Cellar Tasting
      for ( let i = 0 ; i < this.currentUser.tasting_ids.length ; i++) {
        await Tastings.getone(this.currentUser.tasting_ids[i])
        .then(response => {
          if (response.cellar_id) {
            if (local_debug || this.debug) console.log("EntryShow.mounted: found", response._id, " cellar tasting.")
            this.tasting = response
            this.tasting_id = response._id
            delete this.tasting._id;
          }
        })
        .catch(error => {
          if (local_debug || this.debug) console.log("EntryShow.mounted: await Tastings.getone", error);
        })
        if (this.tasting_id != 0) {
          break
        }
      }

      this.isLoading = false
      if (local_debug || this.debug) console.log("EntryShow.mounted: STOP")
    },

    methods: {
      validateTransaction(){
        if ( this.transaction.date == '') {
          this.dateValid=false;
          this.dateFeedback='Choose a valid date';
        } else {
          this.dateValid=true;
          this.dateFeedback='';
          // format date yyyy-mm-dd
          let dateArray = this.transaction.date.split("-");
          if (dateArray[1].length == 1) {
            dateArray[1] = "0" + dateArray[1]
          }
          if (dateArray[2].length == 1) {
            dateArray[2] = "0" + dateArray[2]
          }
          this.transaction.date = dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2]
        }

        if (typeof this.transaction.number == "string") {
          this.transaction.number = parseInt(this.transaction.number)
        }
        
        if ( this.transaction.type == 'add' && this.transaction.number < 1) {
          this.numberValid=false;
          this.numberFeedback='You need to add a number greater than 0';
        } else if (this.transaction.type == 'remove' && this.transaction.number > -1){
          this.numberValid=false;
          this.numberFeedback='You need to remove a negative number smaller than 0';
        } else {
          this.numberValid=true;
        }

        this.transaction_isValid =  this.dateValid && this.numberValid;
      },
      validateTastingNote(){
        // if( this.tasting_note.conclusions.length > 1) {
        //     this.tasting_note_isValid = true;
        // } else {
        //     this.tasting_note_isValid = false
        // }
        this.tasting_note_isValid = true
      },
      showModal_transferEntry() {
        this.transfer_niches=[];
        if (this.niches.length !== 0) {
          for (let i=0; i < this.niches.length; i++) {
            if (this.niches[i].value != this.niche_id) {
              this.transfer_niches.push(this.niches[i])
              if (this.debug) console.log("EntryShow.showModal_transferEntry: Niche ", this.niches[i], " succesfully added to Transfer_Niches.");
            } 
          }
        }
        this.$root.$emit("bv::show::modal", "transferEntry");
      },
      showModal_addBottles() {
        this.min_number = 1;
        this.max_number = 1000;
        this.transaction.number = 1;
        this.$root.$emit("bv::show::modal", "addBottles");
      },
      showModal_removeBottles() {
        this.transaction.number = -1;
        this.min_number = -this.entry.number;
        this.max_number = -1;
        this.$root.$emit("bv::show::modal", "removeBottles");
      },
      showModal_editTransaction() {
        this.calc_min();
        this.max_number = -1;
        if (this.debug) console.log("EntryShow.showModal_editTransaction: min_number = ", this.min_number);
        if (this.debug) console.log("EntryShow.showModal_editTransaction: max_number = ", this.max_number);
        this.$root.$emit("bv::show::modal", "editTransaction");
      },
      showModal_deleteTransaction() {
        this.$root.$emit("bv::show::modal", "deleteTransaction");
      },
      showModal_addTastingNote() {
        this.tasting_noteReset();
        this.$root.$emit("bv::show::modal", "addTastingNote");
      },
      showModal_editTastingNote() {
        this.$root.$emit("bv::show::modal", "editTastingNote");
      },
      showModal_deleteTastingNote() {
        this.$root.$emit("bv::show::modal", "deleteTastingNote");
      },
      
      async transferEntry(bvModalEvt) {
        this.validate_to_niche()
        if (this.nicheValid) {
          // 1. get the to_niche
          await Niches.getone(this.to_niche_id)
          .then(response => {
            this.to_niche = response
            delete this.to_niche._id
            if (this.debug) console.log("EntryShow.transferEntry: to_niche ", this.to_niche, " succesfully retrieved from database.")
          })
          .catch(error => {
            if (this.debug) console.log("EntryShow.transferEntry: catch Niches.getone with ERROR - ", error)
          })

          // 2. change entry.niche_id - update entry
          this.entry.niche_id = this.to_niche_id
          await Entries.update(this.entry_id,this.entry)
          .then(response => {
            if (this.debug) console.log("EntryShow.transferEntry: entry ", this.entry, " with id ", this.entry_id, " succesfully updated with response ", response)
          })
          .catch(error => {
            if (this.debug) console.log("EntryShow.transferEntry: catch Entries.update with ERROR - ", error)
          })

          // 3. remove entry_id from the from_niche - update niche
          let index = this.niche.entry_ids.indexOf(this.entry_id);
          if (index >= 0) {
            this.niche.entry_ids.splice(index, 1);
          } else {
            if (this.debug) console.log("EntryShow.transferEntry: this.entry_id not found in this.niche.entry_ids")
          }
          this.niche.number_of_bottles =  this.niche.number_of_bottles - this.entry.number
          await Niches.update(this.niche_id, this.niche)
          .then(response => {
            if (this.debug) console.log("EntryShow.transferEntry: niche ", this.niche, " with id ", this.niche_id, " succesfully updated with response ", response)
          })
          .catch(error => {
            if (this.debug) console.log("EntryShow.transferEntry: catch Niches.update with ERROR - ", error)
          })          

          // 4. add entry_id to_niche - update to_niche
          this.to_niche.entry_ids.push(this.entry_id)
          this.to_niche.number_of_bottles =  this.to_niche.number_of_bottles - this.entry.number
          await Niches.update(this.to_niche_id, this.to_niche)
          .then(response => {
            if (this.debug) console.log("EntryShow.transferEntry: to_niche ", this.to_niche, " with id ", this.to_niche_id, " succesfully updated with response ", response)
          })
          .catch(error => {
            if (this.debug) console.log("EntryShow.transferEntry: catch Niches.update with ERROR - ", error)
          })

          this.niche_id = this.to_niche_id;
          this.niche = this.to_niche;
          this.to_niche_id = 0;
          this.to_niche = {};

          // 5. force reload niches in Cellar
          this.$store.commit('setNichesLoaded',false)
        } else {
          bvModalEvt.preventDefault();
        }
      },

      validate_to_niche(){
        if(this.to_niche_id == 0) {
          this.nicheValid = false;
          this.nicheFeedback = "select valid niche"
        }  
        else {
          this.nicheValid = true;
          this.nicheFeedback = ""
        }
      },

      cancelTransfer() {
        this.nicheValid = true;
        this.nicheFeedback = ""
      },

      async addBottles(bvModalEvt){
        if (this.debug) console.log("")
        if (this.debug) console.log("EntryShow.addBottles: START")

        this.validateTransaction();
        if (this.transaction_isValid) {
          this.transaction.entry_id = this.entry_id;
          await Transactions.create(this.transaction)
          .then(response=>{
            this.transaction_id = response;
            if (this.debug) console.log("EntryShow.addBottles: Transaction succesfully created : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("EntryShow.addBottles: catch await Transactions.create ERROR :", error);
          })

          //let this_transaction = this.transaction;  
          //Does not work by reference - you have to deepclone like below 
          this.transaction._id = this.transaction_id;
          this.transactions.push(JSON.parse(JSON.stringify(this.transaction)));
          delete this.transaction._id;
          this.entry.transaction_ids.push(this.transaction_id);
          await Entries.update(this.entry_id,this.entry)
          .then(response => {
            if (this.debug) console.log("EntryShow.addBottles : Entry succesfully updated ", response);
          })
          .catch(error => {
            if (this.debug) console.log("EntryShow.addBottles: catch await Entries.update ERROR :", error);
          })

          this.transactions.sort(function(a, b) {
            if(a.date < b.date) { return -1; }
            if(a.date > b.date) { return 1; }
            return 0;
          });
          
          await this.$refs.transactionsTable.refresh();
          await this.recalcNumber();
          this.transactionReset();
          this.$bvModal.hide('addBottles');
        } else {
          bvModalEvt.preventDefault();
        }
        
        if (this.debug) console.log("EntryShow.addBottles: STOP")
      },

      async removeBottles(bvModalEvt){
        if (this.debug) console.log("")
        if (this.debug) console.log("EntryShow.removeBottles: START")

        this.validateTransaction();
        if (this.transaction_isValid) {
          this.transaction.entry_id = this.entry_id;
          await Transactions.create(this.transaction)
          .then(response=>{
            this.transaction_id = response;
            if (this.debug) console.log("EntryShow.removeBottles : Transaction succesfully created : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("EntryShow.removeBottles: catch await Transactions.create ERROR :", error);
          })

          //let this_transaction = this.transaction;  
          //Does not work by reference - you have to deepclone like below 
          this.transaction._id = this.transaction_id;
          this.transactions.push(JSON.parse(JSON.stringify(this.transaction)));
          delete this.transaction._id;
          this.entry.transaction_ids.push(this.transaction_id);
          await Entries.update(this.entry_id,this.entry)
          .then(response => {
            if (this.debug) console.log("EntryShow.removeBottles : Entry succesfully updated ", response);
          })
          .catch(error => {
            if (this.debug) console.log("EntryShow.removeBottles: catch await Entries.update ERROR :", error);
          })

          this.transactions.sort(function(a, b) {
            if(a.date < b.date) { return -1; }
            if(a.date > b.date) { return 1; }
            return 0;
          });
          
          await this.$refs.transactionsTable.refresh();
          await this.recalcNumber();
          this.transactionReset();
          this.$bvModal.hide('addBottles');
        } else {
          bvModalEvt.preventDefault();
        }
                        
        if (this.debug) console.log("EntryShow.removeBottles: STOP")
      },
      
      async editTransaction(bvModalEvt){
        if (this.debug) console.log("")
        if (this.debug) console.log("EntryShow.editTransaction: START")

        this.validateTransaction();
        if (this.debug) console.log("EntryShow.editTransaction: this.transaction = ", this.transaction, " with id = ", this.transaction_id);
        if (this.transaction_isValid) {
          this.transaction.entry_id = this.entry_id;
          await Transactions.update(this.transaction_id,this.transaction)
          .then(response=>{
            if (this.debug) console.log("EntryShow.editTransaction: Transaction succesfully updated : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("EntryShow.editTransaction: catch await Transactions.update ERROR :", error);
          })

          for( var i = 0; i < this.transactions.length; i++){
            if ( this.transactions[i]._id === this.transaction_id) { 
              //this.transactions[i]=this.transaction;
              //Does not work by reference - you have to deepclone like below 
              this.transactions[i] = JSON.parse(JSON.stringify(this.transaction));
              this.transactions[i]._id = this.transaction_id;
              delete this.transaction._id;
            }
          }

          this.transactions.sort(function(a, b) {
            if(a.date < b.date) { return -1; }
            if(a.date > b.date) { return 1; }
            return 0;
          });
          
          await this.$refs.transactionsTable.refresh();
          await this.recalcNumber();
          this.transactionReset();
          this.$bvModal.hide('editTransaction');
        } else {
          bvModalEvt.preventDefault();
        }

        if (this.debug) console.log("EntryShow.editTransaction: STOP")
      },

      calc_min() {
        this.min_number = this.transaction.number - this.entry.number; 
      },

      async deleteTransaction(){
        if (this.debug) console.log("")
        if (this.debug) console.log("EntryShow.deleteTransaction: START")

        await Transactions.delete(this.transaction_id)
        .then(response=>{
          if (this.debug) console.log("EntryShow.deleteTransaction: Transaction succesfully deleted ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("EntryShow.deleteTransaction: catch await Transactions.delete ERROR :", error);
        })
        for( var i = 0; i < this.entry.transaction_ids.length; i++){
          if ( this.entry.transaction_ids[i] === this.transaction_id) {
            this.entry.transaction_ids.splice(i, 1);
          }
        }
        for( var i = 0; i < this.transactions.length; i++){
          if ( this.transactions[i]._id === this.transaction_id ) {
            this.transactions.splice(i,1);
          }
        }

        await Entries.update(this.entry_id,this.entry)
        .then(response=>{
          if (this.debug) console.log("EntryShow.deleteTransaction: Entry succesfully updated ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("EntryShow.deleteTransaction: catch await Entries.update ERROR :", error);
        })

        await this.$refs.transactionsTable.refresh();
        await this.recalcNumber();
        this.transactionReset();
        this.$bvModal.hide('deleteTransaction');

        if (this.debug) console.log("EntryShow.deleteTransaction: STOP")
      },

      async transactionSelect(selected_transactions) {
        if (selected_transactions.length > 0) { 
          await Transactions.getone(selected_transactions[0]._id)
          .then(response=>{
            this.transaction = response;
            this.transaction_id = this.transaction._id;
            delete this.transaction._id;
            if (this.debug) console.log("EntryShow.transactionSelect() : Selected Transaction ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("EntryShow.transactionSelect(): catch await Transactions.getone ERROR :", error);
          })
        } else {
          this.$refs.transactionsTable.clearSelected();
          this.transactionReset(); 
        }
      },

      transactionReset() {
        this.transaction_id = 0;

        let today = new Date();
        // this.transaction.date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' +today.getDate(); ONLY 1 digit for getMonth, getDate
        this.transaction.date = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");

        this.transaction.type='';
        this.transaction.number=0;
        this.transaction.description='';
        this.transaction.price='';
        this.transaction.entry_id=0;
      },

      async addTastingNote(bvModalEvt){
        let local_debug = true
        if (local_debug || this.debug) console.log("");
        if (local_debug || this.debug) console.log("EntryShow.addTastingNote: START");
        this.validateTastingNote();
        if (local_debug || this.debug) console.log("EntryShow.addTastingNote: tasting_note_isValid = ", this.tasting_note_isValid);
        if (this.tasting_note_isValid) {
          this.tasting_note.owner = this.currentUser.firstname + " " + this.currentUser.lastname
          this.tasting_note.user_id = this.currentUserID
          this.tasting_note.entry_id = this.entry_id;
          this.tasting_note.tasting_id = this.tasting_id;
          await TastingNotes.create(this.tasting_note)
          .then(response=>{
            this.tasting_note_id = response;
            if (local_debug || this.debug) console.log("EntryShow.addTastingNote: Tasting Note succesfully created : id = ", response);
          })
          .catch(error=>{
            if (local_debug || this.debug) console.log("EntryShow.addTastingNote: catch await TastingNotes.create ERROR :", error);
          })

          //let this_tasting_note = this.tasting_note;  
          //Does not work by reference - you have to deepclone like below
          this.tasting_note._id = this.tasting_note_id; 
          this.tasting_notes.push(JSON.parse(JSON.stringify(this.tasting_note)));
          delete this.tasting_note._id;
          this.entry.tasting_note_ids.push(this.tasting_note_id);
          await Entries.update(this.entry_id,this.entry)
          .then(response => {
            if (local_debug || this.debug) console.log("EntryShow.addTastingNote: Entry succesfully updated ", response);
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("EntryShow.addTastingNote: catch await Entries.update ERROR :", error);
          })

          this.tasting_notes.sort(function(a, b) {
            if(a.date < b.date) { return -1; }
            if(a.date > b.date) { return 1; }
            return 0;
          });
          
          // Add to cellar.tasting_id
          this.tasting.tasting_note_ids.push(this.tasting_note_id)
          await Tastings.update(this.tasting_id, this.tasting)
          .then(response => {
            if (local_debug || this.debug) console.log("EntryShow.addTastingNote: Tasting succesfully updated ", response);
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("EntryShow.addTastingNote: catch await Tastings.update ERROR :", error);
          })
          
          await this.$refs.tasting_notesTable.refresh();
          this.tasting_noteReset();
          this.$bvModal.hide('addTastingNote');
        } else {
          bvModalEvt.preventDefault();
        }
        if (local_debug || this.debug) console.log("EntryShow.addTastingNote: STOP");
      },
      
      async editTastingNote(bvModalEvt){
        this.validateTastingNote();
        if (this.tasting_note_isValid) {
          this.tasting_note.owner = this.currentUser.firstname + " " + this.currentUser.lastname
          this.tasting_note.user_id = this.currentUserID
          this.tasting_note.entry_id = this.entry_id;
          this.tasting_note.tasting_id = this.tasting_id;
          await TastingNotes.update(this.tasting_note_id,this.tasting_note)
          .then(response=>{
            if (this.debug) console.log("EntryShow.editTastingNote() : TastingNote succesfully updated : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("EntryShow.editTastingNote(): catch await TastingNotes.update ERROR :", error);
          })

          for( var i = 0; i < this.entry.tasting_note_ids.length; i++){
            if ( this.entry.tasting_note_ids[i] === this.tasting_note_id) { 
              //this.transactions[i]=this.transaction;
              //Does not work by reference - you have to deepclone like below 
              this.tasting_notes[i] = JSON.parse(JSON.stringify(this.tasting_note));
              this.tasting_notes[i]._id = this.tasting_note_id;
            }
          }

          this.tasting_notes.sort(function(a, b) {
            if(a.date < b.date) { return -1; }
            if(a.date > b.date) { return 1; }
            return 0;
          });      
          
          await this.$refs.tasting_notesTable.refresh();
          this.tasting_noteReset();
          this.$bvModal.hide('editTastingNote');
        } else {
          bvModalEvt.preventDefault();
        }
      },
      
      async deleteTastingNote(){
        // BUG this.tasting_note_id vanishes in this code
        let tasting_note_id =  this.tasting_note_id

        await TastingNotes.delete(tasting_note_id)
        .then(response=>{
          if (this.debug) console.log("EntryShow.deleteTastingNote : TastingNote succesfully deleted ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("EntryShow.deleteTastingNot : catch await Transactions.delete ERROR :", error);
        })
        for( let i = 0; i < this.entry.tasting_note_ids.length; i++){
          console.log("TMP1 tasting_note_id = ", tasting_note_id, " this.entry.tasting_note_ids[i] = ", this.entry.tasting_note_ids[i])
          if ( this.entry.tasting_note_ids[i] === tasting_note_id) { 
            this.entry.tasting_note_ids.splice(i, 1);
            this.tasting_notes.splice(i,1);
            console.log("TMP1 Bingo") 
          }
        }

        console.log("TMP1.25 tasting_note_id = ", tasting_note_id)

        await Entries.update(this.entry_id,this.entry)
        .then(response=>{
          if (this.debug) console.log("EntryShow.deleteTastingNote : Entry succesfully updated with response ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("EntryShow.deleteTastingNote : catch await Entries.update ERROR :", error);
        })

        console.log("TMP1.75 tasting_note_id = ", tasting_note_id)

        for( let i = 0; i < this.tasting.tasting_note_ids.length; i++){
          console.log("TMP2 tasting_note_id = ", tasting_note_id, " this.tasting.tasting_note_ids[i] = ", this.tasting.tasting_note_ids[i])
          if ( this.tasting.tasting_note_ids[i] === tasting_note_id) { 
            this.tasting.tasting_note_ids.splice(i, 1);
            console.log("TMP2 Bingo")
          }
        }
        await Tastings.update(this.tasting_id, this.tasting)
          .then(response => {
            if (this.debug) console.log("EntryShow.deleteTastingNote: Tasting succesfully updated with response ", response);
          })
          .catch(error => {
            if (this.debug) console.log("EntryShow.deleteTastingNote: catch await Tastings.update ERROR :", error);
          })

        await this.$refs.tasting_notesTable.refresh();
        await this.tasting_noteReset();
        this.$bvModal.hide('deleteTastingNote');
      },

      async tasting_noteSelect(selected_tasting_notes) {
        if (selected_tasting_notes.length > 0) {  
          await TastingNotes.getone(selected_tasting_notes[0]._id)
          .then(response=>{
            this.tasting_note = response;
            this.tasting_note_id = this.tasting_note._id;
            delete this.tasting_note._id;
            if (this.debug) console.log("EntryShow.tasting_noteSelect: Selected TastingNote ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("EntryShow.tasting_noteSelect: catch await TastingNote.getone ERROR :", error);
          })
        } else {
          this.$refs.tasting_notesTable.clearSelected();
          this.tasting_noteReset(); 
        }
      },

      async recalcNumber() {
        let sum = 0;
        for (let i=0; i < this.transactions.length; i++) {
          sum += this.transactions[i].number;
        }

        this.entry.number = sum;
        await Entries.update(this.entry_id,this.entry)
        .then(response=>{
          if (this.debug) console.log("EntryShow.recalcNumber: ===> succesfully updated Entry ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("EntryShow.recalcNumber: ===> catch await Entries.update ERROR :", error);
        }) 

        sum = 0
        for (let i = 0 ; i < this.niche.entry_ids.length ; i++) {
          await Entries.getone(this.niche.entry_ids[i])
          .then(response => {
            if (this.debug) console.log("EntryShow.recalcNumber: ===> succesfully found Entry ",response," in Niche ", this.niche.code, );
            sum += response.number
          })
          .catch(error => {
             if (this.debug) console.log("EntryShow.recalcNumber: ===> catch await Niches.getone ERROR :", error);
          })
        }

        if ( this.niche.number_of_bottles !== sum ) {
          if (this.debug) console.log("EntryShow.recalcNumber: discrepancie in niche ", this.niche.code , ": ", sum , " instead of ", this.niche.number_of_bottles,  "bottles -> CORRECTING")
          this.niche.number_of_bottles = sum
          await Niches.update(this.niche_id,this.niche)
          .then(response=>{
            if (this.debug) console.log("EntryShow.recalcNumber: ===> succesfully updated Niche ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("EntryShow.recalcNumber: ===> catch await Niches.update ERROR :", error);
          })
        } else {
          if (this.debug) console.log("NOTHING DONE since:  sum = ", sum , " === this.niche.number_of_bottles = ", this.niche.number_of_bottles)
        }
      },

      tasting_noteReset() {
        this.tasting_note_id = 0;
        this.selectedTastingNote = '';
        let today = new Date();
        // this.transaction.date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' +today.getDate(); ONLY 1 digit for getMonth, getDate
        this.tasting_note.date = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");

        this.tasting_note.owner = this.currentUser.firstname + " " + this.currentUser.lastname;
        this.tasting_note.user_id = this.currentUserID;
        this.tasting_note.name = this.entry.name;
        if (this.entry.type != '') {
          this.tasting_note.name = this.tasting_note.name + " ("+ this.entry.type +")"
        }
        if (this.entry.vintage != 0) {
          this.tasting_note.name = this.tasting_note.name + " " + this.entry.vintage
        }

        this.tasting_note.appearance='';
        this.tasting_note.nose='';
        this.tasting_note.palate='';
        this.tasting_note.conclusions='';
        this.tasting_note.score=0;
        this.tasting_note.entry_id=0;
      },

      onEdit() {
        this.$store.commit('setPage','EntryEdit')
        this.$router.push({path:'/EntryEdit', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.$route.query.niche_id, entry_id: this.$route.query.entry_id}});
      },

      onDelete() {
        alert("Delete pressed")
      },

      onBack() {
        let local_debug = false
        let goBack = this.previousPage
        if (local_debug) console.log("EntryShow.onBack: goBack to ", goBack)
        if (goBack == 'NicheShow' || goBack == 'EntryEdit') {
          this.$store.commit('setPage','NicheShow')
          this.$router.push({path:'/NicheShow', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.niche_id}});
        } else if (goBack == 'EntryShow') {
          this.$store.commit('setPage','EntryShow')
          this.$router.push({path:'/EntryShow', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.niche_id, entry_id:this.$route.query.entry_id}});
        } else if (goBack == 'Archive') {
          this.$store.commit('setPage','Archive')
          this.$router.push({path:'/Archive', query: {cellar_id:this.$route.query.cellar_id}});
        } else if (goBack == 'Search') {
          this.$store.commit('setPage','Search')
          this.$router.push({path:'/Search', query: {cellar_id:this.$route.query.cellar_id}});
        } else {
          alert("CHECK goBack = ",goBack," ???")
        }
      },

      gotoCellar() {
        this.$store.commit('setPage','Cellar')
        this.$router.push({path:'/Cellar', query: {cellar_id:this.$route.query.cellar_id }} );
      },

      gotoNiche() {
        this.$store.commit('setPage','NicheShow')
        this.$router.push({path:'/NicheShow', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.niche_id}});
      },

      gotoArchive() {
        this.$store.commit('setPage','Archive')
        this.$router.push({path:'/Archive', query: {cellar_id:this.$route.query.cellar_id }} );
      },

      onLeft() {
        this.pageCount--;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },
      onRight() {
        this.pageCount++;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },

      async onAppearance() {
        this.pageCount = 0;
        this.leftVisible = false;
        this.rightVisible = true;
      }, 
      async onNose() {
        this.pageCount = 1;
        this.leftVisible = true;
        this.rightVisible = true;
      },
      async onPalate() {
        this.pageCount = 2;
        this.leftVisible = true;
        this.rightVisible = true;
      },
      async onAftertaste() {
        this.pageCount = 3;
        this.leftVisible = true;
        this.rightVisible = false;
      },
            
      doNothing() {
      },  
    }
  }    
</script>
