<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <RegisterMobile  v-if="matches" />
    <RegisterDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import RegisterMobile from "./Register_Mobile.vue";
  import RegisterDesktop from "./Register_Desktop.vue";
 
  export default {
    name: 'Register',
    components: {
      MatchMedia,
      RegisterMobile,
      RegisterDesktop,
    },
  }
</script> 