import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login'
import Register from '@/components/Register'
import RegistrationReceived from '@/components/RegistrationReceived'
import Confirmation from '@/components/Confirmation'

import AddCellar2Registration from '@/components/AddCellar2Registration'

import Profile from '@/components/Profile'
import Dashboard from '@/components/Dashboard'

import Search from '@/components/Search'
import Library from '@/components/Library'
import Cellar from '@/components/Cellar'

import WineShow from '@/components/WineShow'

import NicheShow from '@/components/NicheShow'
import NicheEdit from '@/components/NicheEdit'

import EntryEdit from '@/components/EntryEdit'
import EntryShow from '@/components/EntryShow'

import WishList from '@/components/WishList'
import BottleEdit from '@/components/BottleEdit'

import Archive from '@/components/Archive'

import Tools from '@/components/Tools'

import AboutUs from '@/components/AboutUs'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/ResetPassword'

import Terms from '@/components/Terms'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/Register',
            name: 'Register',
            component: Register
        },
        {
            path: '/RegistrationReceived',
            name: 'RegistrationReceived',
            component: RegistrationReceived
        },
        {
            path: '/Confirmation',
            name: 'Confirmation',
            component: Confirmation
        },
        {
            path: '/AddCellar2Registration',
            name: 'AddCellar2Registration',
            component: AddCellar2Registration
        },
        {
            path: '/Search',
            name: 'Search',
            component: Search
        },
        {
            path: '/Library',
            name: 'Library',
            component: Library
        },
        {
            path: '/Cellar',
            name: 'Cellar',
            component: Cellar
        },
        {
            path: '/Profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/Dashboard',
            name: 'Dashboard',
            component: Dashboard
        },
        {
            path: '/NicheEdit',
            name: 'NicheEdit',
            component: NicheEdit
        },
        {
            path: '/NicheShow',
            name: 'NicheShow',
            component: NicheShow
        },
        {
            path: '/WineShow',
            name: 'WineShow',
            component: WineShow
        },
        {
            path: '/EntryEdit',
            name: 'EntryEdit',
            component: EntryEdit
        },
        {
            path: '/EntryShow',
            name: 'EntryShow',
            component: EntryShow
        },
        {
            path: '/WishList',
            name: 'WishList',
            component: WishList
        },
        {
            path: '/BottleEdit',
            name: 'BottleEdit',
            component: BottleEdit
        },
        {
            path: '/Archive',
            name: 'Archive',
            component: Archive
        },
        {
            path: '/Tools',
            name: 'Tools',
            component: Tools
        },        
        {
            path: '/AboutUs',
            name: 'AboutUs',
            component:AboutUs
        },  
        {   
            path: '/ForgotPassword', 
            name: 'ForgotPassword',
            component: ForgotPassword 
        },
        {   
            path: '/ResetPassword', 
            name: 'ResetPassword',
            component: ResetPassword 
        },
        {
            path: '/Terms',
            name: 'Terms',
            component: Terms
        }, 

    ],
    mode: 'history'
})