<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <NicheEditMobile  v-if="matches" />
    <NicheEditDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import NicheEditMobile from "./NicheEdit_Mobile.vue";
  import NicheEditDesktop from "./NicheEdit_Desktop.vue";
 
  export default {
    name: 'Desktop',
    components: {
      MatchMedia,
      NicheEditMobile,
      NicheEditDesktop,
    },
  }
</script> 