<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container v-if="this.inSession" fluid>
        <div v-if="wine_id !=0">
          <b-row>
            <b-col style="padding:0" align="center">
              <div class="menu-top" style="text-align:center">
                <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
              </div>
              <div>
                <img class="menu-overlay"
                  :src="require('@/assets/img/menu.png')"
                  v-b-toggle.menu-sidebar
                >
              </div>
              <hr>
            </b-col>
          </b-row>
          <b-row class="justify-content-center" v-if="wine.image_files.length > 0">
            <b-carousel
              id="carousel-fade"
              :interval="4000"
              controls
              indicators
              background="#ffffff"
              img-width="100"
              img-height="300"
              style="width:max-content;height:auto"
            >
              <b-carousel-slide v-for="image_file in wine.image_files" v-bind:key="image_file.dataURL">
                <template v-slot:img>
                  <b-img   
                    v-bind:src="image_file.dataURL"
                    style="height:50vw;position:relative;right:0px"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </b-row>
          <b-row v-else>
            <b-img center
              style="height:50vw;position:relative;right:0px"
              src="../../img/bottle.png"
            ></b-img>
          </b-row> 
          <b-row style="padding-left:15px;padding-right:15px">
            <div>
              <div>
                <h1 class="preview-title">{{this.wine.name}}</h1> 
                <h5 class="preview-subtitle">{{this.wine.type}}</h5>
                <hr>
                <p class="preview-text"><span v-if="this.wine.region !=''">{{this.wine.region}},</span> {{this.wine.country}}</p>
                <p class="preview-text"><span v-if="this.wine.appellation !=''">{{this.wine.appellation}},</span> {{this.wine.classification}}</p>
              </div>
              <div v-for="(value, key) in this.wine" v-bind:key="key">
                <div v-if="key==='tasting_notes' && value.length>0">
                  <hr>
                  <p  class="w-text" style="font-style:italic">{{value}}</p>
                </div>  
                <div v-if="key==='grapes' || key==='vines' || key==='vinification' || key === 'yields'">
                  <p  class="w-text" v-if="value.length>0"><b>{{ key }}</b> : {{value}}</p>
                </div>
              </div>
            </div>
          </b-row>
          <b-row>
            <b-col style="text-align:center;style=padding-right:15px">
              <b-button v-on:click="onAdd()" class="w-button-green-full">add to unit</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="c_wine_id !=0">
          <b-row>
            <b-col style="padding:0" align="center">
              <div class="menu-top" style="text-align:center">
                <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
              </div>
              <div>
                <img class="menu-overlay"
                  :src="require('@/assets/img/menu.png')"
                  v-b-toggle.menu-sidebar
                >
              </div>
              <hr>
            </b-col>
          </b-row>
          <b-row class="justify-content-center" v-if="c_wine.image_files.length > 0">
            <b-carousel
              id="carousel-fade"
              :interval="4000"
              controls
              indicators
              background="#ffffff"
              img-width="100"
              img-height="300"
              style="width:max-content;height:auto"
            >
              <b-carousel-slide v-for="image_file in c_wine.image_files" v-bind:key="image_file.dataURL">
                <template v-slot:img>
                  <b-img   
                    v-bind:src="image_file.dataURL"
                    style="height:50vw;position:relative;right:0px"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </b-row>
          <b-row v-else>
            <b-img center
              style="height:50vw;position:relative;right:0px"
              src="../../img/bottle.png"
            ></b-img>
          </b-row> 
          <b-row style="padding-left:15px;padding-right:15px">
            <div>
              <div>
                <h1 class="preview-title">{{this.c_wine.name}}</h1> 
                <h5 class="preview-subtitle">{{this.c_wine.type}}</h5>
                <hr>
                <p class="preview-text"><span v-if="this.c_wine.region !=''">{{this.c_wine.region}},</span> {{this.c_wine.country}}</p>
                <p class="preview-text"><span v-if="this.c_wine.appellation !=''">{{this.c_wine.appellation}},</span> {{this.c_wine.classification}}</p>
              </div>
              <div v-for="(value, key) in this.c_wine" v-bind:key="key">
                <div v-if="key==='tasting_notes' && value.length>0">
                  <hr>
                  <p  class="w-text" style="font-style:italic">{{value}}</p>
                </div>  
                <div v-if="key==='grapes' || key==='vines' || key==='vinification' || key === 'yields'">
                  <p  class="w-text" v-if="value.length>0"><b>{{ key }}</b> : {{value}}</p>
                </div>
              </div>
            </div>
          </b-row>
          <b-row>
            <b-col style="text-align:center;style=padding-right:15px">
              <b-button v-on:click="onAdd()" class="w-button-green-full">add to unit</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <p class="t-title">ERROR NO VALID wine_id NOR c_wine_id</p>>
        </div>
        <br>
        <b-row>
          <b-col style="text-align:center;style=padding-right:15px">
            <b-button v-on:click="onBack()" class="w-button-pink-full">back</b-button>
          </b-col>
        </b-row>
        <br>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="w-menu-header" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>        
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <b-modal id="addEntry" ref="addEntry" title="add wine to ..." @ok="onSave">
      <p class="t-title text-left">NICHE: {{this.niche.code}} - {{this.niche.name}}</p> 
      <b-row style="width:100%">
        <div style="width:300px;margin-left:auto;margin-right:auto">
          <b-col
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; margin:20px !important"
          >
            <b-row v-if="wine_id !=0"
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="wine.image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="wine.image_files[0].dataURL" 
                ></b-img>
                <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  src="../../img/bottle.png"
                ></b-img>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{wine.name}}</p>
                  <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                  <hr>
                  <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                  <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                  <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                </div>
              </b-col>
            </b-row>
            <b-row v-else-if="c_wine_id !=0"
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="c_wine.image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="c_wine.image_files[0].dataURL" 
                ></b-img>
                <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  src="../../img/bottle.png"
                ></b-img>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{c_wine.name}}</p>
                  <p class="t-subtitle" v-if="c_wine.type">{{c_wine.type}}</p>
                  <hr>
                  <p class="t-text" v-if="c_wine.appellation">{{c_wine.appellation}}</p>
                  <p class="t-text" v-if="c_wine.classification">{{c_wine.classification}}</p>
                  <p class="t-smalltext" v-if="c_wine.grapes">{{c_wine.grapes}}</p>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>
              <p class="t-title">ERROR NO VALID wine_id NOR c_wine_id</p>
            </b-row>
          </b-col>
        </div>
      </b-row>
      <b-row>
        <b-container fluid >
          <b-form autocomplete="off">
            <b-row>
              <b-col>
                <b-form-group style="text-align:left">
                    <label class="w-label w-required" for="input-number">number</label>
                      <b-form-spinbutton v-if="!entryExists" 
                        class="w-spinbutton" 
                        id="input-number" 
                        v-model="transaction.number"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                        aria-describedby="input-live-help input-live-feedback" 
                        min="1" max="1000"
                      >
                      </b-form-spinbutton>
                      <b-form-spinbutton v-else 
                        class="w-spinbutton" 
                        id="input-number" 
                        v-model="transaction.number"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                        aria-describedby="input-live-help input-live-feedback" 
                        min="1" max="1000"
                        readonly
                      >
                      </b-form-spinbutton>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{numberFeedback}}
                      </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>  
            <b-row>
              <b-col>
                <b-form-group style="text-align:left">
                    <label class="w-label w-required" for="input-date">date</label>
                    <b-form-datepicker 
                      class="w-datepicker mb-2" 
                      id="input-date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      v-model="transaction.date"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    >
                    </b-form-datepicker>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{dateFeedback}}
                    </b-form-invalid-feedback>
                </b-form-group>
              </b-col>                        
            </b-row>
            <b-row>
              <b-col>
                <b-form-group style="text-align:left">
                  <label class="w-label w-required" for="vintageList">vintage</label>         
                  <model-select 
                    id="vintageList"
                    class = "w-select"
                    :options="vintages"
                    v-model="entry.vintage"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                    @input="inputVintage"
                  >
                  </model-select>             
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{vintageFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-price">price</label>
                  <b-form-input
                    id="input-price"
                    class="w-input"
                    v-model="transaction.price"
                    ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-occasion">occasion</label>
                  <b-form-input
                    id="input-occasion"
                    class="w-input"
                    v-model="transaction.description"
                    ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>              
          </b-form>                      
        </b-container>        
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding-left:0;padding-right:0">
            <b-button class="w-button-pink-full" @click="cancel()">
              cancel
            </b-button>
          </b-col>
        </b-row>
        <b-row style="width:100%">
          <b-col class="text-right" style="padding-left:0;padding-right:0">
            <b-button class="w-button-green-full" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>    
    </b-modal>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile.vue'

  import Wines from '../Wines.js';
  import C_Wines from '../C_Wines.js';
  
  import Cellars from '../Cellars.js';
  import Niches from '../Niches.js';
  import Entries from '../Entries.js';
  import Transactions from '../Transactions.js'
  
  export default {
    name: 'WineShow',
    components: {
      NoAccess,
      Menu,
      ModelSelect,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },

      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      }
    },
    data() {
      return {
        debug: false,

        cellar_id:0,
        cellar: {},

        niche_id:0,
        niche:{},
        niches:[],

        vintages: [],

        entry: {
            name:'',
            type:'',
            country_id:0,
            country:'',
            region_id:0,
            region:'',
            appellation_id:0,
            appellation:'',
            classification_id:0,
            classification:'',
            sparkling:false,
            color:'',
            grapes:'',
            vines:'',
            yields:'',
            vinification:'',
            image_files:[],
            wine_id:0,
            winery_id:0,
            
            number:0,
            vintage:0,

            cellar_id:0,
            niche_id:0,

            transaction_ids:[],
            tasting_note_ids:[],
        },
        entry_id:0,
        entryExists: false,

        transaction:{
          date:'',
          type:'',
          number:0,
          description:'',
          price:'',
          entry_id:0
        },
        transaction_id:0,

        // Validation Logic
        numberValid : true,
        numberFeedback : '',
        dateValid : true,
        dateFeedback : '',
        vintageValid : true,
        vintageFeedback : '',
        nicheValid: true,
        nicheFeedback: "",

        isValid:true,

        isLoading : true,
        wine: {
          image_files:[],
        },
        wine_id: 0,

        c_wine: {
          image_files:[],
        },
        c_wine_id:0,
      }
    },
  async mounted(){
    if (this.debug) console.log('WineShow.mounted: START');

    // Load the Cellar
    if (this.currentCellarLoaded) {
      if (this.debug) console.log("WineShow.mounted: CURRENT CELLAR LOADED")
      this.cellar = this.currentCellar
      this.cellar_id = this.currentCellarID
      if (this.debug) console.log("WineShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
    }
    else {
      if (this.debug) console.log("WineShow.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
      this.cellar_id = this.$route.query.cellar_id
      await Cellars.getone(this.cellar_id)
      .then(response => {
        this.cellar = response
        delete this.cellar._id
        if (this.debug) console.log("WineShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
      })
      .catch(error =>{
        if (this.debug) console.log("WineShow.mounted: catch Cellar.getone with error: ", error)
      })

      this.$store.commit('setCellar',this.cellar)
      this.$store.commit('setCellarID',this.cellar_id)
      this.$store.commit('setCellarLoaded',true)

      if (this.debug) console.log("WineShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
    }

    // Load the niches list
    if (typeof this.cellar.niche_ids.length !== 0) {
      for (let i=0; i < this.cellar.niche_ids.length; i++) {
        await Niches.getone(this.cellar.niche_ids[i])
        .then(response=> {
          if (typeof response == "object") {
            this.niches.push({value:response._id,text:response.code + ' - ' + response.name})
            if (this.debug) console.log("WineShow.mounted: Niche ", this.niches[i], " succesfully fetched from DB");
          } else {
            if (this.debug) console.log("WineShow.mounted: Niches i=", i , " is not an object");
          }
        })
        .catch(error=> {
          if (this.debug) console.log("WineShow.mounted: await Niches i=", i , " ", error);
        })
      }
      if (this.niches.length > 0) {
        this.niches.sort((a,b)=>(a.text>b.text)?1:-1);
      }   
    }

    // Prepare the input fields
    var today = new Date();
    var this_year = today.getFullYear();
    for (let i = this_year; i > this_year-100; i--) {
      this.vintages.push({value:i,text:i});
    }
    this.transaction.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    
    // Load the wine
    if (this.debug) console.log("wine has this.$route.query.wine_id = ",this.$route.query.wine_id," and this.$route.query.c_wine_id = ",this.$route.query.c_wine_id)
    if (typeof this.$route.query.wine_id != "undefined") {
      this.wine_id = this.$route.query.wine_id
      await Wines.getone(this.wine_id)
      .then(response => {
        this.wine = response;
        delete this.wine._id;
        if (this.debug) console.log("WineShow.mounted: Wine ", this.wine, " succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log('WineShow.mounted: Catch await Wines.getone - ', error);
      })
    } else if (typeof this.$route.query.c_wine_id != "undefined") {
      this.c_wine_id = this.$route.query.c_wine_id
      await C_Wines.getone(this.c_wine_id)
      .then(response => {
        this.c_wine = response;
        delete this.c_wine._id;
        if (this.debug) console.log("WineShow.mounted: C_Wine ", this.c_wine, " succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log('WineShow.mounted: Catch await C_Wines.getone - ', error);
      })
    } else {
      alert("SEVERE ERROR: wine has this.$route.query.wine_id = ",this.$route.query.wine_id," and this.$route.query.c_wine_id = ",this.$route.query.c_wine_id)
      return
    }
    
    // Load the niche
    if (typeof this.$route.query.niche_id != "undefined") {
      this.niche_id = this.$route.query.niche_id
      await Niches.getone(this.niche_id)
      .then(response => {
        this.niche = response;
        delete this.niche._id;
        if (this.debug) console.log("WineShow in mounted: Niche " + this.niche + " succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log("WineShow in mounted: await Niches.getone ", error);
      })
    }

    this.isLoading = false;
    if (this.debug) console.log('WineShow.mounted: STOP');
  },
  methods: {
    onBack() {
      let local_debug = false
      if (this.previousPage == "WineShow") {
      this.$store.commit('setPage','Library')
      this.$router.push({path:'/Library', query: {cellar_id:this.$route.query.cellar_id }})
      } else {
        if (local_debug) console.log("WineShow.onBack: START: this.previousPage = ", this.previousPage)
        let path = '/' + this.previousPage;
        this.$store.commit('setPage',this.previousPage)
        this.$router.push({path:path, query: {cellar_id:this.$route.query.cellar_id, niche_id:this.$route.query.niche_id }})
      }
      if (local_debug) console.log("WineShow.onBack: STOP")
    },
    async onAdd() {
      if (this.debug) console.log("Add Pressed")
      await this.$bvModal.show('addEntry')
    },
    validate_form(){
      if ( this.transaction.date == '') {
        this.dateValid=false;
        this.dateFeedback='Choose a valid date';
      } else {
        this.dateValid=true;
        this.dateFeedback='';
        // format date yyyy-mm-dd
        let dateArray = this.transaction.date.split("-");
        if (dateArray[1].length == 1) {
          dateArray[1] = "0" + dateArray[1]
        }
        if (dateArray[2].length == 1) {
          dateArray[2] = "0" + dateArray[2]
        }
        this.transaction.date = dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2]
      }

      if ( this.niche_id == 0 ) {
        this.nicheValid = false
        this.nicheFeedback = "select valid niche"
      } else {
        this.nicheValid = true
        this.nicheFeedback = ""
      }

      if( this.transaction.number <= 0) {
          this.numberValid = false;
          this.numberFeedback = "Select valid number"
      } else {
          this.numberValid = true
          this.numberFeedback = ""
      }

      if(this.entry.vintage == 0) {
        this.vintageValid = false;
        this.vintageFeedback = "Select valid vintage"
      } 
      else {
        this.vintageValid = true;
        this.vintageFeedback = ""
      }
      
      this.isValid = this.nicheValid && this.numberValid && this.vintageValid;
    },
    async inputVintage(value) {
      this.entry.vintage = value;
      if (this.debug) console.log("WineShow in inputVintage - vintage = ",this.entry.vintage)
    },

    async getEntry_inNiche(entry){ 
      let local_debug = false  
      if (local_debug || this.debug) console.log("");
      if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: START");
      let test_entry_id = 0;
      let test_entry = {};
      for(let i=0; i < this.niche.entry_ids.length; i ++) {
        await Entries.getone(this.niche.entry_ids[i])
        .then(response => {
          test_entry = response;
          test_entry_id = response._id;
          delete test_entry._id
        })
        .catch(error => {
          if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: await Entries.getone ", error);
        })
        if (entry.wine_id !=0 && test_entry.wine_id === entry.wine_id && test_entry.vintage === entry.vintage) {
          if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: entry.wine_id=",entry.wine_id," entry.vintage=",entry.vintage, " EXISTS");
          this.entry_id = test_entry_id;
          this.entry = test_entry;
          if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: STOP");
          return test_entry_id;
        } else if (entry.c_wine_id !=0 && test_entry.c_wine_id === entry.c_wine_id && test_entry.vintage === entry.vintage) {
          if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: entry.c_wine_id=",entry.c_wine_id," entry.vintage=",entry.vintage, " EXISTS");
          this.entry_id = test_entry_id;
          this.entry = test_entry;
          if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: STOP");
          return test_entry_id;
        } 
      }
      if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: entry.wine_id=",entry.wine_id," entry.vintage=",entry.vintage, " DOES NOT EXISTS");
      if (local_debug || this.debug) console.log("====> WineShow.getEntry_inNiche: STOP");
      return 0;
    },

    async onSave(event) {
      let local_debug = false
      if (local_debug || this.debug) console.log("");
      if (local_debug || this.debug) console.log("WineShow.onSave: START");
      //Reading the form
      this.validate_form();
      if (!this.isValid) {
        if (local_debug || this.debug) console.log("WineShow.onSave: Returning without Save because validation form failed.")
        event.preventDefault()
        return
      }
      if (local_debug || this.debug) console.log("WineShow.onSave: entry_id = ", this.entry_id)
      if (local_debug || this.debug) console.log("WineShow.onSave: entry = ", this.entry.name, ' ', this.entry.vintage)
      this.entry.cellar_id = this.cellar_id;
      this.entry.niche_id = this.niche_id;
      if (this.wine_id != 0) {
        this.entry.wine_id = this.wine_id;
        this.entry.c_wine_id = 0
      } else if (this.c_wine_id !=0) {
        this.entry.c_wine_id = this.c_wine_id;
        this.entry.wine_id = 0
      } else {
        alert("SEVERE ERROR: NO VALID wine_id NOR c_wine_id")
      }

      // Load niche
      await Niches.getone(this.niche_id)
      .then(response => {
        this.niche = response
        delete this.niche._id
        if (local_debug || this.debug) console.log("WineShow.onSave: niche ", this.niche, " with id ", this.niche_id, " succesfully fetched from DB.")
      })
      .catch(error => {
        if (local_debug || this.debug) console.log("WineShow.onSave: catch Niches.getone with error: ", error)
      })

      // Check if entry already exists in database 
      await this.getEntry_inNiche(this.entry);
      if ( this.entry_id == 0 ) {
        if (local_debug || this.debug) console.log("WineShow.onSave: Entry does NOT exist - we create it");
        
        if (this.wine_id !=0) {
          if (local_debug || this.debug) console.log("WineShow.onSave: It's a wine with wine_id = ", this.wine_id);
          let keys = Object.keys(this.wine);
          for (let i = 0; i < keys.length; i++) {
            this.entry[keys[i]] = this.wine[keys[i]];
          }
          if (local_debug || this.debug) console.log("WineShow.onSave: Copied this.wine to this.entry = ", this.entry);
        } else if (this.c_wine_id !=0) {
          if (local_debug || this.debug) console.log("WineShow.onSave: It's a c_wine with c_wine_id = ", this.c_wine_id);
          let keys = Object.keys(this.c_wine);
          for (let i = 0; i < keys.length; i++) {
            this.entry[keys[i]] = this.c_wine[keys[i]];
          }
          if (local_debug || this.debug) console.log("WineShow.onSave: Copied this.c_wine to this.entry = ", this.entry);
        }
        // Updating a transaction from form data
        this.entry.number = this.transaction.number;

        // Creating Entry
        await Entries.create(this.entry)
        .then(response=>{
          this.entry_id = response;
          if (local_debug || this.debug) console.log("WineShow.onSave: Entry succesfully created : id = ", response);
        })
        .catch(error=>{
          if (local_debug || this.debug) console.log("WineShow.onSave: catch await Entries.create ERROR :", error);
        }) 

        // Adding this.entry_id to the list of this.niche
        if (local_debug || this.debug) console.log("WineShow.onSave: Adding entry to entry_ids from niche ", this.niche.name)       
        this.niche.entry_ids.push(this.entry_id);
        await Niches.update(this.niche_id,this.niche)
        .then(response=>{
          if (local_debug || this.debug) console.log("WineShow.onSave: Niche succesfully added entry : id = ", response);
        })
        .catch(error=>{
          if (local_debug || this.debug) console.log("WineShow.onSave: catch await Niche.update ", error);
        })  
        
        // Adding this.entry_id to the list of this.cellar
        if (local_debug || this.debug) console.log("WineShow.onSave: Adding entry to entry_ids from cellar ", this.cellar.name)
        this.cellar.entry_ids.push(this.entry_id);
        await Cellars.update(this.cellar_id,this.cellar)
        .then(response=>{
          if (local_debug || this.debug) console.log("WineShow.onSave: Cellar succesfully added niche : id = ", response);
        })
        .catch(error=>{
          if (local_debug || this.debug) console.log("WineShow.onSave: catch await Cellar.update ", error);
        })  
      } else {
        if (local_debug || this.debug) console.log("WineShow.onSave: Entry DOES exist - we update it");
        if (local_debug || this.debug) console.log("WineShow.onSave: Entry DOES exist: id = ", this.entry_id, " ", this.entry);
        this.entry.number = this.entry.number + this.transaction.number;
      }
        
      this.transaction.entry_id = this.entry_id;
      if (local_debug || this.debug) console.log("WineShow.onSave: this.transaction = ", this.transaction)
      await Transactions.create(this.transaction)
      .then(response=>{
        this.transaction_id = response;
        if (local_debug || this.debug) console.log("WineShow.onSave: Transaction succesfully created : id = ", response);
      })
      .catch(error=>{
        if (local_debug || this.debug) console.log("WineShow.onSave: catch await Transaction.create ERROR :", error);
      })
      this.entry.transaction_ids.push(this.transaction_id);
      
      if (local_debug || this.debug) console.log("WineShow.onSave: UPDATE ENTRY with id: ", this.entry_id, " ",this.entry);
      await Entries.update(this.entry_id, this.entry)
      .then(response=>{
        if (local_debug || this.debug) console.log("WineShow.onSave: Entry succesfully updated : id = ", response);
      })
      .catch(error=>{
        if (local_debug || this.debug) console.log("WineShow.onSave: catch await Entry.update ERROR :", error);
      })

      if (local_debug || this.debug) console.log("WineShow.onSave: Going back to niche :", this.niche.name);
      
      this.$store.commit('setPage','NicheShow')
      this.$router.push({path:'/NicheShow', query: {cellar_id:this.cellar_id,niche_id:this.niche_id }});
      await this.$forceUpdate();
      if (local_debug || this.debug) console.log("WineShow.onSave: STOP");
    },
  }
}
</script>

<style scoped>

</style>