<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <ToolsMobile  v-if="matches" />
    <ToolsDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import ToolsMobile from "./Tools_Mobile.vue";
  import ToolsDesktop from "./Tools_Desktop.vue";
 
  export default {
    name: 'Desktop',
    components: {
      MatchMedia,
      ToolsMobile,
      ToolsDesktop,
    },
  }
</script> 