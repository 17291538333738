<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <MenuDesktop/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <div style="width: 80%; margin: 2vh 10vw 0 10vw !important">
              <b-row>
                <br>
                <b-tabs class="w-tabs" v-model="tabIndex" fill>
                  <div v-if="entry_id == 0" class="w-tabbox">
                    <b-tab class="w-tab" title="from database" active>
                      <LibrarySearchDesktop/>
                    </b-tab>
                    <!-- <b-tab class="w-tab" title="from barcode reader">
                      <BarCodeSearch/>
                    </b-tab> -->
                    <b-tab class="w-tab" title="from scratch">
                      <FullDefineDesktop/>
                    </b-tab>
                  </div>
                  <div v-else class="w-tabbox">
                    <b-tab v-if="entry.wine_id == 0" class="w-tab" title="from scratch">
                      <FullDefineDesktop/>
                    </b-tab>
                    <b-tab v-else class="w-tab" title="from database" active>
                      <WineEdit/>
                    </b-tab>
                  </div>
                </b-tabs>
              </b-row>
              <br>
              <b-row>
                <b-col style="text-align:left;padding-left:15px">
                  <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
                </b-col>
              </b-row>
              <br>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import MenuDesktop from './MenuDesktop.vue';

  import LibrarySearchDesktop from './LibrarySearch_Desktop.vue';
  // import BarCodeSearch from './BarCodeSearch.vue';
  import FullDefineDesktop from './FullDefine_Desktop.vue';
  import WineEdit from './WineEdit_Desktop.vue';

  import Entries from '../Entries.js'

  export default {
    name: 'EntryEdit',
    components:{
      NoAccess,
      MenuDesktop,
      LibrarySearchDesktop,
      // BarCodeSearch,
      FullDefineDesktop,
      WineEdit,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
    },
    data() {
      return {
        debug: true,

        isLoading: true,
        tabIndex:0,
      }
    },
    async mounted() {
      this.entry_id = this.$route.query.entry_id;
      if (typeof this.entry_id !== "undefined") {
        await Entries.getone(this.entry_id)
        .then(response=> {
          this.entry = response;
          delete this.entry._id
          if (this.debug) console.log( "EntryEdit.mounted: Entry ", this.entry , " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("EntryEdit.mounted: await Entries.getone ", error);
        })
      } else { 
        this.entry_id=0;
        if (this.debug) console.log("EntryEdit.mounted: entry_id does not exist yet - new entry");
      }

      this.isLoading = false;
    },
    methods: {
      onBack() {
        let local_debug = false
        
        let goBack = this.previousPage
        if (local_debug || this.debug) console.log("EntryEdit.onBack: goBack to ", goBack)
        if (goBack == 'NicheShow' || goBack == 'EntryEdit') {
          this.$store.commit('setPage','NicheShow')
          this.$router.push({path:'/NicheShow', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.$route.query.niche_id}});
        } else if (goBack == 'EntryShow') {
          this.$store.commit('setPage','EntryShow')
          this.$router.push({path:'/EntryShow', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.niche_id, entry_id:this.$route.query.entry_id}});
        } else {
          alert("CHECK goBack = ",goBack," ???")
        }
      },
            
      doNothing() {
      },
    }
  }
</script>
