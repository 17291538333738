import { API } from './API.js';

import axios from 'axios';

const url = API + '/wines_search/';
const debug = false;

class Wines_Search {
    static count(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Wines_Search.count: ',response.data.count)
                return response.data.count
            })
            .catch(error => {
                console.log('Wines_Search.count: ',error)
            })
        }

    static list(postdata) {
    return axios.post(url, postdata)
        .then(response => {
            if (debug) console.log('Wines_Search.list: ',response.data.list)
            return response.data.list
        })
        .catch(error => {
            console.log('Wines_Search.list: ',error)
        })
    }
}

export default Wines_Search