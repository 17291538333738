<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <br>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">archive</p>
            <b-row class="justify-content-center">
              <p class="t-title">overview of bottles you once had</p>
            </b-row>
            <b-row class="justify-content-center">
              <p class="t-subtitle">to select one, click</p>
            </b-row>  
            <b-row class="justify-content-center" style="margin:0">              
              <b-col
                v-for="(entry, index) in entries"
                v-bind:item="entry"
                v-bind:index="index"
                v-bind:key="entry._id" 
                class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                xs="12" sm="6" md="4" lg="2" xl="2"
                style ="display:block; min-width:300px; margin:20px !important"
              >
                <b-row 
                  style="width:100%"
                  v-on:click="onSelect(entry._id)"
                >
                  <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                    <b-img center v-if="entry.image_files[0]"
                      style="height:100%;width:auto;position:absolute;right:0px"
                      v-bind:src="entry.image_files[0].dataURL" 
                    ></b-img>
                    <b-img center v-else
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle.png')"
                    ></b-img>
                  </b-col>
                  <b-col cols="9" class="d-table-cell">
                    <div>
                      <p class="t-title">{{entry.vintage}}</p>
                      <p class="t-title2">{{entry.name}}</p>
                      <p class="t-subtitle" v-if="entry.type">{{entry.type}}</p>
                      <p class="t-subtitle" style="color:white" v-else>bogus text here</p>
                      <hr style="margin:5px 0 5px 0">
                      <p class="t-text" v-if="entry.appellation">{{entry.appellation}}</p>
                      <p class="t-smalltext" v-if="entry.classification">{{entry.classification}}</p>
                      <p class="t-smalltext" v-if="entry.color">{{entry.color}}</p>
                      <p class="c-bottles" style="font-size: 11px">archived: {{entry.archived_date}}</p>
                      <!-- <p class="c-bottles" style="font-size: 11px">archived: {{formatDate(entry.archived_date)}}</p> -->
                    </div>
                    <b-button pill
                      v-if="entry.number == 0"
                      style="top:10px"
                      class="delete-button"
                      v-on:click.stop="onDelete(index)"
                      v-b-tooltip.hover title="delete entry"
                    >
                      <font-awesome-icon class="delete-symbol" icon="trash-alt" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <br>
          </b-col>
        </b-row>
        <br>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>

    <b-modal id="deleteEntry" ref="deleteEntry" title="delete entry" @ok="deleteEntry"> 
      <b-row v-if="current_index >=0">
        <div style="width:300px;margin-left:auto;margin-right:auto">
          <b-col
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; margin:20px !important"
          >
            <b-row 
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="entries[current_index].image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="entries[current_index].image_files[0].dataURL" 
                ></b-img>
                <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  :src="require('@/assets/img/bottle.png')"
                ></b-img>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{entries[current_index].vintage}}</p>
                  <p class="t-title2">{{entries[current_index].name}}</p>
                  <p class="t-subtitle" v-if="entries[current_index].type">{{entries[current_index].type}}</p>
                  <hr>
                  <p class="t-text" v-if="entries[current_index].appellation">{{entries[current_index].appellation}}</p>
                  <p class="t-text" v-if="entries[current_index].classification">{{entries[current_index].classification}}</p>
                  <p class="c-bottles">{{entries[current_index].number}}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-row>
      <p class="t-subtitle text-center">deleting the entry is also deleting all transactions and tasting notes of the entry</p>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO DELETE THIS ENTRY?</p>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>  
  import NoAccess from './NoAccess.vue';
  import Menu from './MenuDesktop.vue';

  import Cellars from '../Cellars.js'
  import Entries from '../Entries.js'

  import Transactions from '../Transactions.js'
  import TastingNotes from '../TastingNotes.js'

  import formatDate from '../assets/js/formatDate.js';

  export default {
    name: "Archive",
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },

      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },
    },
    data() {
      return {
        debug: false,
        
        isLoading: true,

        cellar: {},
        cellar_id:0,
        
        entries: [],
        error: '',
        isValid: true,

        current_index: -1,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("Archive.mounted: START")

      // Load the Cellar
      if (this.currentCellarLoaded) {
        if (this.debug) console.log("Archive.mounted: CURRENT CELLAR LOADED")
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (this.debug) console.log("Archive.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      }
      else {
        if (this.debug) console.log("Archive.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
        this.cellar_id = this.$route.query.cellar_id
        await Cellars.getone(this.cellar_id)
        .then(response => {
          this.cellar = response
          delete this.cellar._id
          if (this.debug) console.log("Archive.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
        })
        .catch(error =>{
          if (this.debug) console.log("Archive.mounted: catch Cellar.getone with error: ", error)
        })

        this.$store.commit('setCellar',this.cellar)
        this.$store.commit('setCellarID',this.cellar_id)
        this.$store.commit('setCellarLoaded',true)

        if (this.debug) console.log("Archive.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
      }

      // Load the archived entries
      if (this.cellar_id != 0) {
        for (let i=0 ; i<this.cellar.archive_ids.length ; i++) {
          await Entries.getone(this.cellar.archive_ids[i])
          .then(response=> {
            if (response != "") {
              this.entries.push(response);
              if (this.debug) console.log( "Archive.mounted: Entry ", this.entries[i], " succesfully fetched from DB");
            } else {
              console.log("Archive.mounted: ERROR entry with id=",this.cellar.archive_ids[i], " DOES NOT EXIST.")
            }          
          })
          .catch(error=> {
            if (this.debug) console.log("Archive.mounted: await Entries i=", i , " ", error);
          })
        }
        this.entries.sort(function(a, b) {
          if(a.archived_date < b.archived_date) { return 1; }
          if(a.archived_date > b.archived_date) { return -1; }
          return 0;
        });
      }

      this.isLoading = false
      if (this.debug) console.log( "Archive.mounted: STOP")
    },
    methods: {
      onSelect(entry_id) {
        if (this.debug) console.log("Archive in onSelect: entry_id = ", entry_id);
        this.$store.commit('setPage','EntryShow')
        this.$router.push({path:'/EntryShow', query: {cellar_id:this.$route.query.cellar_id,niche_id:this.$route.query.niche_id, entry_id:entry_id }});
      },

      async onDelete(i) {
        this.current_index = i
        if (this.debug) console.log("Delete Pressed i = "+ i)
        await this.$bvModal.show('deleteEntry')
      },
      
      async deleteEntry(){
        if (this.debug) console.log("")
        if (this.debug) console.log("Archive.deleteEntry: START")
        let this_entry_id = this.entries[this.current_index]._id
        if (this.debug) console.log("Archive.deleteEntry: this_entry_id=",this_entry_id)

        // Delete all correcponding tranactions of the entry in the database
        for (let i = 0 ; i < this.entries[this.current_index].transaction_ids.length ; i++) {
          await Transactions.delete(this.entries[this.current_index].transaction_ids[i])
          .then(response => {
            if (this.debug) console.log("Archive.deleteEntry: entry transaction with id ", this.entries[this.current_index].transaction_ids[i], " deleted with response : ", response)
          })
          .catch(error => {
            if (this.debug) console.log('Archive.deleteEntry: catch await Transactions.delete ERROR : ' + error);
          })
        }
        // Delete all corresponding tasting notes of the entry in the database
        for (let i = 0 ; i < this.entries[this.current_index].tasting_note_ids.length ; i++) {
          await TastingNotes.delete(this.entries[this.current_index].tasting_note_ids[i])
          .then(response => {
            if (this.debug) console.log("Archive.deleteEntry: entry tasting note with id ", this.entries[this.current_index].tasting_note_ids[i], " deleted with response : ", response)
          })
          .catch(error => {
            if (this.debug) console.log('Archive.deleteEntry: catch await TastingNotes.delete ERROR : ' + error);
          })
        }
        
        // Delete the entry in the database
        await Entries.delete(this_entry_id)
        .then(response => {
          if (this.debug) console.log("Archive.deleteEntry: entry with id ", this_entry_id, " deleted with response : ", response)
        })
        .catch(error => {
          if (this.debug) console.log('Archive.deleteEntry: catch await Entries.delete ERROR : ' + error);
        })

        // Update cellar archive in the database
        for (let i=0 ; i < this.cellar.archive_ids.length ; i++) {
          if (this.cellar.archive_ids[i] == this_entry_id) {
            this.cellar.archive_ids.splice(i,1)
            break
          }
        }
        if (this.debug) console.log("Archive.deleteEntry: entry with id ", this_entry_id, " removed from this.cellar.archive_ids")
        
        await Cellars.update(this.cellar_id,this.cellar)
        .then(response => {
          if (this.debug) console.log("Archive.deleteEntry: cellar ",this.cellar," with id ", this.cellar_id, " updated with response : ", response)
        })
        .catch(error => {
          if (this.debug) console.log('Archive.deleteEntry: catch await Cellars.update ERROR : ' + error);
        })

        // Update cellar in the store
        this.$store.commit('setCellar',this.cellar)
        if (this.debug) console.log("Cellar.deleteNiche: cellar ", this.cellar_id, " updated in store")

        // Update Entries locally
        if (this.debug) console.log("Archive.deleteEntry: entry with id ", this.entries[this.current_index]._id, " removed from entries")
        this.entries.splice(this.current_index,1);
        this.current_index = -1;

        if (this.debug) console.log("Archive.deleteEntry: STOP")
      },  
    }
  }    
</script>

<style scoped>
.w-bottomright {
  position:absolute;
  bottom: 10px;
  right: 5px;
  margin:0;
}
.w-big {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.w-text {
  font-size: 10px;
  margin:0;
}
</style> 