<template>
  <div>
  <b-container fluid class="space-2 space-3--lg"> 
    <b-form autocomplete="off">
      <b-row>
        <b-col style="padding:0" align="center">
          <div class="menu-top">
            <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>LOVER</p>
          </div>
          <hr>
          <div id="avatar" class="w-box text-center" style="width:180px;height:180px"> 
            <b-avatar v-if="user.image_files.length" 
              size="100px" 
              v-bind:src="user.image_files[0].dataURL"
            >
            </b-avatar>
            <b-avatar v-else 
              size="100px" 
              :src="require('@/assets/img/profile-picture.jpg')"
            >
            </b-avatar>
            <p ref="pictureComment" class="profile-name">{{pictureComment}}</p>
            <div class="dropbox-overlay text-center" style="width:180px;height:180px">
              <vue-dropzone 
                ref="imgDropzone" id="profiledropzone" 
                :options="dropzoneOptions"
                @vdropzone-thumbnail="vfileAdded" 
              >
              </vue-dropzone>    
            </div>
          </div>
          <b-tooltip target="avatar" triggers="hover">
            click or drag and drop your picture here
          </b-tooltip>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-left:10px" class="d-table">
          <div class="m-box">
            <h1 class="w-subtitle" style="text-align:right">personal information</h1>
            <b-form-group>
              <label class="w-label w-required" for="input-name">first name</label>
              <b-form-input
              id="input-firstname"
              class = "w-input"
              v-model="user.firstname"
              @change="changeName"
              v-bind:class="{'is-invalid' : !this.firstnameValid}"
              aria-describedby="input-live-help input-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{firstnameFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>
                      
            <b-form-group>
              <label class="w-label w-required" for="input-lastname">last name</label>
              <b-form-input
              id="input-lastname"
              class = "w-input"
              v-model="user.lastname"
              @change="changeName"
              v-bind:class="{'is-invalid' : !this.lastnameValid}"
              aria-describedby="input-live-help input-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{lastnameFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group style="text-align:left;margin-bottom:0">
              <label class="w-label w-required" for="genderList">gender</label>                                    
              <model-select
                class="w-select"
                :options="genders"
                v-model="user.gender"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.genderValid}"
              >
              </model-select>
              <b-form-invalid-feedback id="input-live-feedback">
                {{genderFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <label class="w-label w-required" for="input-birthdate">date of birth</label>
              <b-form-input
              id="input-birthdate"
              class = "w-input"
              v-model="user.birthdate"
              v-bind:class="{'is-invalid' : !this.birthdateValid}"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="YYYY-MM-DD"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{birthdateFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="m-box">
            <h1 class="w-subtitle" style="text-align:right">wyne information</h1>
            <b-form-group>
              <label class="w-label w-required" for="input-name">cellar name</label>
              <b-form-input
              id="input-cellarname"
              class = "w-input"
              v-model="cellar.name"
              v-bind:class="{'is-invalid' : !this.cellarnameValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{cellarnameFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="m-box">
            <h1 class="w-subtitle" style="text-align:right">account information</h1>
            <b-form-group> 
              <label class="w-label w-required" for="input-name">email</label>
              <b-form-input
              id="input-email"
              class = "w-input"
              v-model="user.email"
              v-bind:class="{'is-invalid' : !this.emailValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{emailFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

          <b-form-group>
            <label class="w-label w-required" for="input-name">password</label>
            <b-form-input
              id="input-password"
              class = "w-input"
              type="password"
              v-model="password"
              v-bind:class="{'is-invalid' : !this.passwordValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
            </b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              {{passwordFeedback}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label class="w-label w-required" for="input-name">confirm password</label>
              <b-form-input
              id="input-confirmpassword"
              class = "w-input"
              type="password"
              v-model="confirmpassword"
              v-bind:class="{'is-invalid' : !this.passwordValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{passwordFeedback}}
              </b-form-invalid-feedback>
          </b-form-group>
          <p class="w-label">This WyneLover account grants access to www.wynecellar.com and www.wynetaster.com.</p>
          <b-form-checkbox 
            id="toggle"
            v-model="user.hasAgreedTerms"
            v-bind:class="{'is-invalid' : !this.termsValid}"
          >
            <p class="w-label">I agree to <span class="w-cursor-click" @click.stop="showTerms"><u>WyneLover Terms of Use and Privacy Policy.</u></span></p>
          </b-form-checkbox>
          <b-form-invalid-feedback id="input-live-feedback">
            {{termsFeedback}}
          </b-form-invalid-feedback>
          </div>
          <div class="m-box">
            <b-button class="w-button-green-full" v-on:click="onRegister()">register</b-button>
            <p style="height:5px"></p>
            <b-button class="w-button-pink-full" v-on:click="onBack()">back</b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';
  import leapYear from '../assets/js/leapYear.js';

  import { ModelSelect } from 'vue-search-select';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  import Users from '../Users.js';
  import UserID_from_Email from '../UserID_from_Email.js';
  import Confirm from '../Confirm.js';
  import Cellars from '../Cellars.js';
  import Tastings from '../Tastings.js';

  export default {
    name: 'Register',
    components:{
      ModelSelect,
      vueDropzone: vue2Dropzone,
    },
    data() {
      return {
        debug: false,

        //Working Objects
        user: {
            firstname: '',
            lastname: '',
            gender:'',
            birthdate:'',
            email: '',
            hashed_password: '',
            profile_picture: '',
            cellar_id: '',
            image_files: [],
            tasting_ids: [],
            isConfirmed: false,
            hasAgreedTerms: false,
            showWelcome: true,
        },
        user_id:0,

        cellar: {
            name : '',
            user_id : '',
            niche_ids:[],
            entry_ids:[],
            archive_ids:[],
            image_files: [],
            number_of_bottles: 0,
        },
        cellar_id:0,

        tasting: {
          name:'',
          organiser:'',
          date:'',
          image_files: [],
          user_id:0,
          tasting_note_ids:[],
          bottle_ids:[],
          fromCellar:true,
        },
        tasting_id:0,

        pictureComment: 'name comes here',

        // Validation Logic
        firstnameValid : true,
        firstnameFeedback : '',

        lastnameValid : true,
        lastnameFeedback : '',

        cellarnameValid : true,
        cellarnameFeedback : '',

        genderValid : true,
        genderFeedback : '',

        birthdateValid : true,
        birthdateFeedback : '',

        confirmpassword : '',
        password : '',

        emailValid : true,
        emailFeedback : '',

        passwordValid : true,
        passwordFeedback : '',

        termsValid : true,
        termsFeedback : '',

        formValid : false,

        genders:[{text:'Male',value:'M'},{text:'Female',value:'F'},{text:'Non-Binary',value:'X'}],

        // dropzone variables
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : '',
          maxFilesize: 15,
        },
      }
    },
    async mounted() {

    },
    methods: {
        validateEmail() {
          let hasAt = false;
          let hasDot = false;
          for (let i=0;i < this.user.email.length;i++) {
              if (this.user.email[i] === '@') {
                  hasAt = true;
              }
              if (this.user.email[i] === '.') {
                  hasDot = true;
              }
          }
          this.emailValid = hasAt && hasDot
          if ( ! this.emailValid) {
              this.emailValid = false
              this.emailFeedback='this is an invalid email format';
          }
        },

        validatebirthdate() {
          this.birthdateValid = true
          this.birthdateFeedback=''

          if (this.user.birthdate.length != 10) {
            this.birthdateValid = false
            this.birthdateFeedback='date should be of format YYYY-MM-DD';
            return
          }
          let arr = this.user.birthdate.split("-");
          let year = parseInt(arr[0])
          let month = parseInt(arr[1])
          let day = parseInt(arr[2])

          let this_year =  new Date().getFullYear();
          let d30 = [4,6,9,11]

          if ( day < 1 || day > 31 ) {
            this.birthdateValid = false
            this.birthdateFeedback='invalid day';
          }
          else if ( d30.includes(month) && day > 30) {
            this.birthdateValid = false
            this.birthdateFeedback='invalid day';
          }
          else if ( month==2 ) {
             if ( leapYear(year) ) {
              if ( day > 29 ) {
                this.birthdateValid = false
                this.birthdateFeedback='invalid day';
              }
             } else {
              if ( day > 28 ) {
                this.birthdateValid = false
                this.birthdateFeedback='invalid day';
              }
             } 
          }

          if ( month < 1 || month > 12 ) {
            this.birthdateValid = false
            if (this.birthdateFeedback.length ==0 ) {
              this.birthdateValid = false
              this.birthdateFeedback='invalid month';
            } else {
              this.birthdateValid = false
              this.birthdateFeedback = this.birthdateFeedback + ' - invalid month'
            }
          } 

          if ( this_year - year > 122 || year > this_year) {
            if (this.birthdateFeedback.length ==0 ) {
              this.birthdateValid = false
              this.birthdateFeedback='invalid year';
            } else {
              this.birthdateValid = false
              this.birthdateFeedback = this.birthdateFeedback + ' - invalid year'
            }
            console.log("TMP: birthdateValid = ", this.birthdateValid)
          }
        },

        validatePassword() {
          let min8char = false   
          let hasUpper = false
          let hasLower = false
          let hasDigit = false
          if ( this.password.length > 7) {
            min8char = true
          }
          for (let i=0; i < this.password.length;i++) {
            let c = this.password[i]
            if (c >= '0' && c <= '9') {
              hasDigit = true
            } else {
              if (c === c.toUpperCase() ) {
                hasUpper = true
              }
              if (c === c.toLowerCase() ) {
                hasLower = true
              }
            }
          }
          this.passwordValid = min8char && hasUpper && hasLower && hasDigit
          if ( !this.passwordValid ) {
            this.passwordFeedback = 'password needs at least'
            if ( !min8char) {
              this.passwordFeedback += ' 8 characters'
            }
            if( !hasUpper ) {
              this.passwordFeedback += ' one uppercase'
            }
            if( !hasLower ) {
              this.passwordFeedback += ' one lowercase'
            }
            if( !hasDigit) {
              this.passwordFeedback += ' one digit'
            }
          }
          if (this.debug) console.log("validatePassword: START")
          if (this.debug) console.log("validatePassword: min8char = ", min8char)
          if (this.debug) console.log("validatePassword: hasUpper = ", hasUpper)
          if (this.debug) console.log("validatePassword: hasLower = ", hasLower)
          if (this.debug) console.log("validatePassword: hasDigit = ", hasDigit)
          if (this.debug) console.log("validatePassword: --------------------")
          if (this.debug) console.log("validatePassword: new_passwordFeedback = ", this.new_passwordFeedback)
          if (this.debug) console.log("validatePassword: STOP")
        },
        
        validateTerms() {
          if (!this.user.hasAgreedTerms) {
            this.termsValid = false
            this.termsFeedback = 'you have to agree upon the terms to continue'
          } else {
            this.termsValid = true
            this.termsFeedback = ''
          }
        },
        
        async validate(){
          //chack validity of name fields
          if (this.user.firstname.length < 1) {
            this.firstnameValid = false
            this.firstnameFeedback = 'first name should be at least 1 character long'
          } else {
             this.firstnameValid = true
          }

          if (this.user.lastname.length < 2) {
            this.lastnameValid = false
            this.lastnameFeedback = 'last name should be at least 2 characters long'
          } else {
             this.lastnameValid = true
          }

          //check validity of gender
          if (this.user.gender.length == 0) {
            this.genderValid = false
            this.genderFeedback = 'select gender'
          } else {
             this.genderValid = true
          }

          //check validity of birthdate
          this.validatebirthdate()

          //check validity of cellar name
          if (this.cellar.name.length < 3) {
            this.cellarnameValid = false
            this.cellarnameFeedback = 'cellar Name should be at least 3 characters long'
          } else {
             this.cellarnameValid = true
          }
          
          //check validity of email field
          this.validateEmail()
          
          //check if email is already know
          //var userExists = await Users.doesExists(this.user.email)
          let challenge = {"email": this.user.email};
          let userExists = false;
          await UserID_from_Email.getToken(challenge)
          .then(response => {
            if (response === 0) {
              if (this.debug) console.log("Register in validate: user with email ", this.user.email, " has no id and does NOT exists in DB");
              userExists = false;
            } else {
              if (this.debug) console.log("Register in validate: user with email ", this.user.email, " has id = ", response, " and exists in DB");
              userExists = true;
            }
          })
          .catch(error => {
            userExists = true;
            if (this.debug) console.log("Register in validate: await UserID_from_Email - ", error);
          })

          if (userExists === false ) {
            if (this.debug) console.log("Register in validate: User " + this.user.email + " does not exists => creating")
            //check validaty of password
            this.validatePassword()
            
            if (this.password !== this.confirmpassword) {
              this.passwordValid = false
              this.passwordFeedback = 'the passwords did not correspond. Try again.'
            }
            
          } else {
            this.emailValid = false
            this.emailFeedback = 'User ' + this.user.email + ' exists. Please Login'
          }
          this.validateTerms()
          this.formValid =  this.firstnameValid && this.lastnameValid && this.genderValid && this.birthdateValid && this.cellarnameValid && this.emailValid && this.passwordValid && this.termsValid
        },

        async onRegister() {
          if (this.debug) console.log("Register in onRegister: Register pressed");
          this.user.email = this.user.email.toLowerCase();
          this.firstnameFeedback = ""
          this.lastnameFeedback = ""
          this.birthdateFeedback = ""
          this.cellarnameFeedback = ""
          this.emailFeedback = ""
          this.passwordFeedback = ""
          this.termsFeedback = ""

          await this.validate()
          if (this.debug) console.log("Register in onRegister: form.valid=",this.formValid);
          
          if (this.formValid) {
            // HERE COMES THE DB STUFF
            if (this.debug) console.log('Register in onRegister: Extracted this.user from the form :', this.user)
            
            this.user.hashed_password = this.CryptoJS.SHA256(this.password).toString();
            if ( this.user.image_files.length > 0) {
              this.user.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
            }
            await Users.create(this.user)
            .then(response=>{
              if (this.debug) console.log("Register in onRegister: user successfully created - ", response);
              this.user_id = response
            })
            .catch(error=>{
               if (this.debug) console.log("Register in onRegister: catch in Users.create - ERROR - ", error);
            })

            this.cellar.user_id = this.user_id;
            await Cellars.create(this.cellar)
            .then(response =>{
              if (this.debug) console.log("Register in onRegister: Cellar successfully created - ", response);
              this.cellar_id = response
            })
            .catch(error => {
              if (this.debug) console.log("Register in onRegister: catch in Cellars.create - ", error);
            })

            this.tasting.name = 'My Cellar Tastings';
            this.tasting.fromCellar = true;
            this.tasting.organiser = this.user.firstname + ' ' + this.user.lastname;
            this.tasting.user_id = this.user_id;
            await Tastings.create(this.tasting)
            .then(response => {
              if (this.debug) console.log("Register in onRegister: Tasting successfully created - ", response);
              this.tasting_id = response
            })
            .catch(error => {
              if (this.debug) console.log("Register in onRegister: catch in Tastings.create - ", error);
            })

            this.user.cellar_id = this.cellar_id;
            this.user.tasting_ids[0] = this.tasting_id;            
            await Users.update(this.user_id,this.user)
            .then(response =>{
              if (this.debug) console.log("Register in onRegister: user successfully updated - ", response);
            })
            .catch(error => {
              if (this.debug) console.log("Register in onRegister: catch in Users.update - ", error);
            })

            this.$store.commit('setUser',this.user)
            
            await Confirm.sendEmail(this.user_id)
            .then(response=>{
              if (this.debug) console.log("Register in onRegister: ",this.user.email," has been succesfully registered ", response)
              this.$router.push({path:'/RegistrationReceived', query: {user_id:this.user_id}} );  
            })
            .catch(error => {
              if (this.debug) console.log('Register in onRegister: ', error)
            })
          }
        },

        changeName() {
          this.pictureComment = '';
          if (this.user.firstname.length > 0) {
            this.pictureComment += this.user.firstname;
          }
          if (this.user.lastname.length > 0) {
            if (this.user.firstname.length >0) {
              this.pictureComment += ' ';
            }
            this.pictureComment += this.user.lastname;
          }
        },

        showTerms() {
          this.$store.commit('setPage','Terms')
          this.$router.push({path:'/Terms'});
        },

        onBack() {
          this.$store.commit('setPage','Login')
          this.$router.push({path:'/'});
        },

        async vfileAdded(new_image_file) {
          this.pictureComment = 'loading';
          this.$refs.pictureComment.$forceUpdate;
          if (this.user.image_files.length > 0) {
            var old_image_file = this.user.image_files.pop();
            this.$refs.imgDropzone.removeFile(old_image_file);
          }
          let org_base64 = new_image_file.dataURL;
          let cc_base64 = await cc64(org_base64,2,3);
          new_image_file.dataURL = cc_base64;
          this.user.image_files.push(new_image_file);

          this.user.profile_picture = await cc64(org_base64,1,1);
          this.$refs.imgDropzone.removeFile(new_image_file);
          this.changeName();
        },

        template: function () {
         return `<div class="dz-preview">
                </div>`;
        },
    }
  }
</script>

<style scoped>
</style>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>

.bottom-center-logo {
  left: 12.5%;
}

/* Custom Dropzone */
#profiledropzone {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;
  border-style: none;
  border-radius: 5px;

  color:#696969;

  transition: background-color .2s linear;
}

#profiledropzone .dz-message {
  position: absolute;
  color:#696969;
  bottom:-50px;
  font-size: 12px;
}

#profiledropzone .dz-preview {
  width: 110px;
  display: inline-block
}

#profiledropzone .dz-preview .dz-image {
  position: relative;
  margin-top: 60px;
  width: 80px;
  height: 80px;
  margin-left: 20px;
  margin-bottom: 20px;  
  top:-55px;
}
#profiledropzone .dz-preview .dz-image > img {
  width: 100%;
}

#profiledropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 70% !important;
  background-size: contain;
}

#profiledropzone .dz-preview .dz-details {
  color: white;
  transition: opacity .2s linear;
  text-align: center;
}
#profiledropzone .dz-success-mark, .dz-error-mark, .dz-remove {
  display: none;
}

#profiledropzone .dz-thumbnail{
  height:100% !important;
  width:auto !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
}
.thumbnail-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  color:white;
  background-color: brown;
}

#profiledropzone .dz-preview .dz-image:hover .thumbnail-overlay {
  opacity: 0.7;
}

#profiledropzone .remove {
  text-align: center;
  color:brown;
  font-size: 0.5 rem !important;
}

#profiledropzone .dz-filename {
  font-size: 0.5 rem !important;
}
</style>
