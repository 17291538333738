<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <MenuDesktop/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <br>
            <p v-if="bottle.name" class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">
              <span v-on:click="gotoWishlist()" class="w-cursor-click" ><u>wishlist</u></span> 
              > old bottle
            </p>
            <p v-else class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">
              <span v-on:click="gotoWishlist()" class="w-cursor-click" ><u>wishlist</u></span> 
              > new bottle
            </p>            
            <div style="width: 80%; margin: 2vh 10vw 0 10vw !important">
              <b-row>
                <br>
                <b-tabs class="w-tabs" v-model="tabIndex" fill>
                  <div class="w-tabbox">
                    <b-tab v-if="false" class="w-tab" title="from database" active>
                      <LibrarySearchDesktop/>
                    </b-tab>
                    <b-tab class="w-tab" title="from scratch">
                      <FullDefineDesktop/>
                    </b-tab>
                  </div>
                </b-tabs>
              </b-row>
              <br>
              <b-row>
                <b-col style="text-align:left;padding-left:15px">
                  <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
                </b-col>
              </b-row>
              <br>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import MenuDesktop from './MenuDesktop.vue';

  import LibrarySearchDesktop from './LibrarySearch_Desktop.vue';
  // import BarCodeSearch from './BarCodeSearch.vue';
  import FullDefineDesktop from './FullDefine_WishList_Desktop.vue';

  export default {
    name: 'BottleEdit',
    components:{
      NoAccess,
      MenuDesktop,
      LibrarySearchDesktop,
      // BarCodeSearch,
      FullDefineDesktop,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      Wishlist() {
        return this.$store.state.wishlist
      },
      WishlistID() {
        return this.$store.state.wishlist_id
      },
      Bottle() {
        return this.$store.state.bottle
      },
      BottleID() {
        return this.$store.state.bottle_id
      },
      CWine() {
        return this.$store.state.c_wine
      },
      CWineID() {
        return this.$store.state.c_wine_id
      },
    },
    data() {
      return {
        debug: false,

        isLoading: true,
        tabIndex:0,
      }
    },
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("=====> BottleEdit.mounted: START")
      // Read the STORE
      this.wishlist = this.Wishlist;
      this.wishlist_id = this.WishlistID;
      if (this.debug) console.log("=====> BottleEdit.mounted: store read wishlist =", JSON.stringify(this.wishlist, null, 2), "wishlist_id =", this.wishlist_id);

      this.bottle = this.Bottle;
      this.bottle_id = this.BottleID;
      if (this.debug) console.log("=====> BottleEdit.mounted: store read bottle =", JSON.stringify(this.bottle,null,2), "bottle_id =", this.bottle_id);

      this.c_wine = this.CWine;
      this.c_wine_id = this.CWineID;
      if (this.debug) console.log("=====> BottleEdit.mounted: store read c_wine =", JSON.stringify(this.c_wine,null,2), "c_wine_id =", this.c_wine_id);

      if (this.debug) console.log("=====> BottleEdit.mounted: STOP")
      this.isLoading = false;
    },
    methods: {
      onBack() {
        this.$router.push({path:'/WishList'});
      },
      gotoWishlist() {
        this.$store.commit('setPage','Wishlist')
        this.$router.push({path:'/Wishlist'});
      },
    }
  }
</script>
