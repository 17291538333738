<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-tabs class="w-tabs" justified fill>
            <div v-if="entry_id == 0" class="w-tabbox">
              <b-tab class="w-tab" title="from database" active>
                <LibrarySearchMobile/>
              </b-tab>
              <!-- <b-tab class="w-tab" title="from barcode reader">
                <BarCodeSearch/>
              </b-tab> -->
              <b-tab class="w-tab" title="from scratch">
                <FullDefineMobile/>
              </b-tab>
            </div>
            <div v-else class="w-tabbox" style="border-shadow: 0 0 0 0">
              <b-tab v-if="entry.wine_id == 0" class="w-tab" title="from scratch">
                <FullDefineMobile/>
              </b-tab>
              <b-tab v-else class="w-tab" title="from database" active>
                <WineEdit/>
              </b-tab>
            </div>
          </b-tabs>
        </b-row>        
        <b-row>
          <b-col style="text-align:center;padding-left:15px;padding-right:15px">
            <b-button v-on:click="onBack()" class="w-button-pink-full">back</b-button>
          </b-col>
        </b-row>
        <br>          
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <MenuMobile/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import MenuMobile from './MenuMobile.vue';

  import LibrarySearchMobile from './LibrarySearch_Mobile.vue';
  // import BarCodeSearch from './BarCodeSearch.vue';
  import FullDefineMobile from './FullDefine_Mobile.vue';
  import WineEdit from './WineEdit_Mobile.vue';

  import Entries from '../Entries.js'

  export default {
    name: 'EntryEdit',
    components:{
      NoAccess,
      MenuMobile,
      LibrarySearchMobile,
      // BarCodeSearch,
      FullDefineMobile,
      WineEdit,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
    },
    data() {
      return {
        debug: false,

        isLoading: true,
        tabIndex:0,
      }
    },
    async mounted() {
      this.entry_id = this.$route.query.entry_id;
      if (typeof this.entry_id !== "undefined") {
        await Entries.getone(this.entry_id)
        .then(response=> {
          this.entry = response;
          delete this.entry._id
          if (this.debug) console.log( "EntryEdit in mounted: Entry ", this.entry , " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("EntryEdit in mounted: await Entries.getone ", error);
        })
      } else { 
        this.entry_id=0;
        if (this.debug) console.log("EntryEdit in mounted: entry_id does not exist yet - new entry");
      }

      this.isLoading = false;
    },
    methods: {
      onBack() {
        let local_debug = false
        
        let goBack = this.previousPage
        if (local_debug || this.debug) console.log("EntryEdit.onBack: goBack to ", goBack)
        if (goBack == 'NicheShow' || goBack == 'EntryEdit') {
          this.$store.commit('setPage','NicheShow')
          this.$router.push({path:'/NicheShow', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.$route.query.niche_id}});
        } else if (goBack == 'EntryShow') {
          this.$store.commit('setPage','EntryShow')
          this.$router.push({path:'/EntryShow', query: {cellar_id:this.$route.query.cellar_id, niche_id: this.niche_id, entry_id:this.$route.query.entry_id}});
        } else {
          alert("CHECK goBack = ",goBack," ???")
        }
      },
    }
  }
</script>
