<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>
        <p class="w-pagetitle" style="margin-left:20px;margin-right:5px; text-align:right;">search</p>
        <b-row class="justify-content-center">
          <p class="t-title">search bottles in {{cellar.name}}</p>
        </b-row>
        <b-row>
          <b-col md="10" style="padding:0" align="center">
            <CellarSearch_Mobile/>
          </b-col>
        </b-row>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <MenuMobile/>
          </template>
        </b-sidebar>      
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import MenuMobile from './MenuMobile.vue';
  import CellarSearch_Mobile from './CellarSearch_Mobile.vue';

  export default {
    name: "Search",
    components: {
      NoAccess,
      MenuMobile,
      CellarSearch_Mobile,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("Search.mounted: START")
      if (this.currentCellarLoaded) {
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (this.debug) console.log("Search.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      } else {
        console.log("Search.mounted: FATAL ERROR cellar NOT loaded.")
      }
      this.isLoading = false;
      if (this.debug) console.log("Search.mounted: STOP")
    },
    methods:{
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>