import { API } from './API.js';
import axios from 'axios';

const url = API + '/c_wine_from_entry_id/';

const debug = false;

class C_Wine_from_Entry_id {
    static get(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('C_Wine_from_Entry_id.get: ',response.data)
                return response.data
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    // Handle 404 Not Found error
                    if (debug) console.log('C_Wine_from_Entry_id.get: Entry not found (404)');
                    return undefined; // or some default value
                } else {
                    // Handle other errors
                    if (debug) console.log('C_Wine_from_Entry_id.get: ', error);
                    throw error; // Re-throw if you want to handle it further up the chain
                }
            });
    }
}

export default C_Wine_from_Entry_id