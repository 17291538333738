// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';
import { API } from './API.js';

import axios from 'axios';

// const url = WEBSERVER + ':'+ PORT + '/api/users/'
const url = API + '/users/'

class Connection {
    static test() {
        return new Promise ( async (resolve, reject) => {
            try {
                // console.log('Connection.test: url = ', url)
                const res = await axios.get(url);
                resolve(true);
                console.log('Connection.test: Connection OK')
            } catch(err) {
                resolve(false);
                console.log('Connection.test: No Connection')
            }

        })
    }
}

export default Connection