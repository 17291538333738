
export default function leapYear(year) {
    let return_value = false
    
    if ( (year%4 == 0) && !(year%100 == 0)) {
        return_value = true
    }
    if ( year%400 == 0 ) {
        return_value = true
    }

    return return_value;
}