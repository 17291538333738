<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
          <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <div class="welcome-overlay">
            <h1 class="main-title"><b><span style="color: rgb(195,147,130)">WYNE</span></b>LOVER</h1>
            <p class="main-subtitle">an open digital wine experience initiative</p>
          </div>  
        </b-col>
      </b-row> 
      <br>
      <br>
      <br>
      <b-row>
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" class="text-right">
          <p class="w-title">Hey {{user.firstname}} {{user.lastname}}, </p>
          <p class="w-text">We received a request to add user <strong>{{user.email}}</strong> to the WyneLover community.</p>
          <p class="w-text">This will allow you to use WyneCellar (www.wynecellar.com) and WyneTaster (www.wynetaster.com).</p>
          <p class="w-text">If this is correct, please push the <strong>confirm</strong> button below</P>
          <br>
          <p class="w-subtitle">Welcome to the WyneLover community!</p> 
          <b-button v-on:click="onStart()" class="w-button-green">confirm</b-button>
        </b-col>
      </b-row>  
    </div>
  </div>
</template>

<script>
  import Users from '../Users.js';
  import Wineries from '../Wineries.js';

  export default {
    name: 'Confirmation',
    data() {
      return {
        debug: false,

        isLoading: true,

        user: {
        },
        profile_picture : '',
        user_id:0,

        winery: {
        },
        winery_id:0,
      }
    },

    async mounted() {
      if (this.debug) console.log('Confirmation.mounted');

      if ( typeof this.$route.query.user_id !== "undefined") {
        await Users.getone(this.$route.query.user_id)
        .then(response=>{
          this.user = response;
          this.user_id = response._id;
          delete this.user._id
          if (this.debug) console.log("Confirmation.mounted: User ", this.user, " succesfully fetched from DB")
        })
        .catch(error=>{
          if (this.debug) console.log( "Confirmation.mounted: catch await Users.getone: ", error);
        })

        if (typeof this.user === "undefined" || this.user ==="") {
          let this_error = "User undefined"
          if (this.debug) console.log("Confirmation.mounted: ", this_error)
          this.$router.push({path:'/Error',query: {error:this_error}} );  
        }

        if (typeof this.user.winery_id !== "undefined" && this.user.winery_id.length == 24) {
          await Wineries.getone(this.user.winery_id)
          .then(response=>{
            this.winery = response;
            this.winery_id = response._id;
            delete this.winery._id;
            if (this.debug) console.log("Confirmation.mounted: Winery ", this.winery, " succesfully fetched from DB")
          })
          .catch(error=>{
            if (this.debug) console.log( "Confirmation.mounted: catch await Wineries.getone: ", error);
          })
        } else {
          if (this.debug) console.log( "Confirmation.mounted: ERROR : No winery found.");
        }
      } else {
        if (this.debug) console.log( "Confirmation.mounted: ERROR : No user found.");
      }

      this.isLoading = false;
    },
    methods: {
      async onStart() {
        if (this.debug) console.log("Confirm in onStart: Confirm pressed");
        try{
          this.user.isConfirmed = true
          delete this.user._id
          await Users.update(this.$route.query.user_id,this.user)
        } catch(err) {
          if (this.debug) console.log("Confirmation.onStart: ",err.message);
        }
        try {
          await this.$store.commit('setUser',this.user)
          await this.$store.commit('setUserID',this.user_id)
          await this.$store.commit('setProfilePicture',this.user.profile_picture)
          await this.$router.push({path:'/'} );
        } catch(err) {
          if (this.debug) console.log ("Confirmation.onStart: ", err.message)
        }
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style>
