import { API } from './API.js';
import axios from 'axios';

const url = API + '/users/';
const debug = false;

class Users {
    // get users
    // static get() {
    //     return new Promise ( async (resolve, reject) => {
    //         try {
    //             const res = await axios.get(url);
    //             const data = res.data;
    //             resolve(
    //                 data.map(user=> ({
    //                     ...user,
    //                     email: user.email
    //                 }))
    //             );
    //             console.log('data = ' + data)
    //         } catch(err) {
    //             reject(err);
    //         }
    //     })
    // }

    // get one user
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Users.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Users.getone: ',error)
            })
    }

    // get one from username
    static getoneFromEmail(email) {
        return axios.get(url)
            .then(function (response) {
                if (debug) console.log('Users.getoneFromEmail: ', response);
                let return_value = false;
                if ( response.data.length == 0 ) {
                    return return_value;
                }
                for ( var i= 0; i < response.data.length; i++) {
                    if (response.data[i].email === email) {
                        return_value = response.data[i];
                    } 
                }
                return return_value;
            })
            .catch(function (error) {
                if (debug) console.log('Users.getoneFromEmail : ', error);
                return false
            });
    }

    static doesExists(email) {
        return axios.get(url)
            .then(function (response) {
                if (debug) console.log('Users.doesExists: ',response);
                if ( response.data.length == 0 ) {
                    return false
                }
                for ( var i= 0; i < response.data.length; i++) {
                    if (response.data[i].email === email) {
                        return true
                    } else {
                        return false    
                    }
                }
            })
            .catch(function (error) {
                if (debug) console.log('Users.doesExists : ', error);
            return false
            });
    }

    // create user
    static create(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Users.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Users.create :',error)
            })
    }
    static update(id,postdata) {
        if (debug) console.log('Users.update: id=',id)
        if (debug) console.log('Users.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('User.update: ',response)
                return id
            })
            .catch(error => {
                console.log('User.update : ',error)
            })
    }
    
    // delete user
    static delete(id) {
        return axios.delete(`${url}${id}`)
    }
}

export default Users