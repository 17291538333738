import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        inSession: false,
        
        current_page: 'Dashboard',
        previous_page: 'Login',

        database:{},
        
        user:{},
        user_id:0,
        profile_picture:'',

        cellar:{},
        cellar_id:0,
        cellar_loaded:false,

        niches:[],
        niches_loaded:false,

        search_state: {},
    },
    getters:{
        inSession: state => {
            return state.inSession
        },
        currentDatabase: state => {
            return state.database
        },
        currentUser: state => {
            return state.user
        },
        currentUserID: state => {
            return state.user_id
        },
        profilePicture: state => {
            return state.profile_picture
        },
        currentCellar: state => {
            return state.cellar
        },
        currentCellarID: state => {
            return state.cellar_id
        },
        currentCellarLoaded: state => {
            return state.cellar_loaded
        },
        currentNiches: state => {
            return state.niches
        },
        currentNichesLoaded: state => {
            return state.niches_loaded
        },
        currentPage: state => {
            return state.current_page
        },
        previousPage: state => {
            return state.previous_page
        },
        currentSearchState: state => {
            return state.search_state
        },
        getBottle: state => {
            return state.bottle
        },
        getBottleID: state => {
            return state.bottle_id
        },
        getWine: state => {
            return state.wine
        },
        getWineID: state => {
            return state.wine_id
        },
        getCWine: state => {
            return state.c_wine
        },
        getCWineID: state => {
            return state.c_wine_id
        },
        getWishlist: state => {
            return state.wishlist
        },
        getWishlistID: state => {
            return state.wishlist_id
        },
    },
    mutations:{
        login:state=>{
            state.inSession = true
        },
        logout:state=>{
            state.inSession = false
        },
        setDatabase:(state,currentDatabase)=>{
            state.database = currentDatabase
        },
        setUser:(state,currentUser)=>{
            state.user = currentUser
        },
        setUserID:(state,userID)=>{
            state.user_id = userID
        },
        setProfilePicture:(state,profile_picture)=>{
            state.profile_picture = profile_picture
        },
        setCellar:(state,currentCellar)=>{
            state.cellar = currentCellar
        },
        setCellarID:(state,cellarID)=>{
            state.cellar_id = cellarID
        },
        setCellarLoaded:(state,cellarLoaded)=>{
            state.cellar_loaded = cellarLoaded
        },
        setNiches:(state,currentNiches)=>{
            state.niches = currentNiches
        },
        setNichesLoaded:(state,nichesLoaded)=>{
            state.niches_loaded = nichesLoaded
        },
        setPage:(state,page)=>{
            state.previous_page = state.current_page;
            state.current_page = page;
        },
        setSearchState:(state,currentSearchState)=>{
            state.search_state = currentSearchState
        },
        setBottle:(state,bottle)=>{
            state.bottle = bottle
        },
        setBottleID:(state,bottle_id)=>{
            state.bottle_id = bottle_id
        },
        setWine:(state,wine)=>{
            state.wine = wine
        },
        setWineID:(state,wine_id)=>{
            state.wine_id = wine_id
        }, 
        setCWine:(state,c_wine)=>{
            state.c_wine = c_wine
        },
        setCWineID:(state,c_wine_id)=>{
            state.c_wine_id = c_wine_id
        },
        setWishlist:(state,wishlist)=>{
            state.wishlist = wishlist
        },
        setWishlistID:(state,wishlist_id)=>{
            state.wishlist_id = wishlist_id
        },  
    }
})