<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-tabs class="w-tabs" v-model="tabIndex" fill>
            <div class="w-tabbox">
              <b-tab v-if="false" class="w-tab" title="from database" active>
                <LibrarySearchMobile/>
              </b-tab>
              <b-tab class="w-tab" title="from scratch">
                <FullDefineMobile/>
              </b-tab>
            </div>
          </b-tabs>
        </b-row>        
        <br>
        <b-row>
          <b-col style="text-align:center;padding:0">
            <b-button v-on:click="onBack()" class="w-button-pink-full">back</b-button>
          </b-col>
        </b-row>          
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <MenuMobile/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import MenuMobile from './MenuMobile.vue';

  import LibrarySearchMobile from './LibrarySearch_Mobile.vue';
  // import BarCodeSearch from './BarCodeSearch.vue';
  import FullDefineMobile from './FullDefine_WishList_Mobile.vue';

  export default {
    name: 'BottleEdit',
    components:{
      NoAccess,
      MenuMobile,
      LibrarySearchMobile,
      // BarCodeSearch,
      FullDefineMobile,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      Wishlist() {
        return this.$store.state.wishlist
      },
      WishlistID() {
        return this.$store.state.wishlist_id
      },
      Bottle() {
        return this.$store.state.bottle
      },
      BottleID() {
        return this.$store.state.bottle_id
      },
      CWine() {
        return this.$store.state.c_wine
      },
      CWineID() {
        return this.$store.state.c_wine_id
      },
    },
    data() {
      return {
        debug: false,

        isLoading: true,
        tabIndex:0,
      }
    },
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("=====> BottleEdit.mounted: START")
      // Read the STORE
      this.wishlist = this.Wishlist;
      this.wishlist_id = this.WishlistID;
      if (this.debug) console.log("=====> BottleEdit.mounted: store read wishlist =", JSON.stringify(this.wishlist, null, 2), "wishlist_id =", this.wishlist_id);

      this.bottle = this.Bottle;
      this.bottle_id = this.BottleID;
      if (this.debug) console.log("=====> BottleEdit.mounted: store read bottle =", JSON.stringify(this.bottle,null,2), "bottle_id =", this.bottle_id);

      this.c_wine = this.CWine;
      this.c_wine_id = this.CWineID;
      if (this.debug) console.log("=====> BottleEdit.mounted: store read c_wine =", JSON.stringify(this.c_wine,null,2), "c_wine_id =", this.c_wine_id);

      if (this.debug) console.log("=====> BottleEdit.mounted: STOP")
      this.isLoading = false;
    },
    methods: {
      onBack() {
        this.$router.push({path:'/WishList'});
      },
      gotoWishlist() {
        this.$store.commit('setPage','Wishlist')
        this.$router.push({path:'/Wishlist'});
      },
    }
  }
</script>

<style scoped>
/* Custom Dropzone */
.winedropzone {
  position: relative;
  top: -15vh;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;

  border-style: none;
  border-radius: 5px;

  color: transparent;

  transition: background-color .2s linear;
}

/* label {
  font-family:inherit;
  font-size:0.8rem;
  color:grey;
  margin:0;
  padding:0
}
input {
  color:black;
  font-family:inherit;
  font-weight: 400;
  font-size: 1rem;
} */

.custom-control-label {
 color:grey;
  font-size: 1 rem
}


.w-picture{
  height: 450px !important;
  width: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(234,202,81,0.1) !important;
}
</style>