import { API } from './API.js';

import axios from 'axios';

const url_bottles = API + '/cellar_countBottles/';
const url_country = API + '/cellar_perCountry/';
const url_region = API + '/cellar_perRegion/';
const url_appellation = API + '/cellar_perAppellation/';
const url_vintage = API + '/cellar_perVintage/';
const url_color = API + '/cellar_perColor/';
const url_transactions = API + '/cellar_Transactions/';
const debug = false;

class Queries {
    static cellar_perCountry(postdata) {
    if (debug) console.log('Query.cellar_perCountry: ',postdata)
    return axios.post(url_country, postdata)
        .then(response => {
            if (debug) console.log('Query.cellar_perCountry: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('Query.cellar_perCountry: ',error)
        })
    }

    static cellar_perRegion(postdata) {
    if (debug) console.log('Query.cellar_perRegion: ',postdata)
    return axios.post(url_region, postdata)
        .then(response => {
            if (debug) console.log('Query.cellar_perRegion: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('Query.cellar_perRegion: ',error)
        })
    }

    static cellar_perAppellation(postdata) {
        if (debug) console.log('Query.cellar_perAppellation: ',postdata)
        return axios.post(url_appellation, postdata)
            .then(response => {
                if (debug) console.log('Query.cellar_perAppellation: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Query.cellar_perAppellation: ',error)
            })
        }

    static cellar_perVintage(postdata) {
    if (debug) console.log('Query.cellar_perVintage: ',postdata)
    return axios.post(url_vintage, postdata)
        .then(response => {
            if (debug) console.log('Query.cellar_perVintage: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('Query.cellar_perVintage: ',error)
        })
    }

    static cellar_perColor(postdata) {
        if (debug) console.log('Query.cellar_perColor: ',postdata)
        return axios.post(url_color, postdata)
            .then(response => {
                if (debug) console.log('Query.cellar_perColor: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Query.cellar_perColor: ',error)
            })
    }
    

    static number_ofBottles(postdata) {
        if (debug) console.log('Query.number_ofBottles: ',postdata)
        return axios.post(url_bottles, postdata)
            .then(response => {
                if (debug) console.log('Query.number_ofBottles: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Query.number_ofBottles: ',error)
            })
    }

    static cellar_Transactions(postdata) {
        if (debug) console.log('Query.cellar_Transactions: ',postdata)
        return axios.post(url_transactions, postdata)
            .then(response => {
                if (debug) console.log('Query.cellar_Transactions: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Query.cellar_Transactions: ',error)
            })
    }
}

export default Queries