<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <EntryEditMobile  v-if="matches" />
    <EntryEditDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import EntryEditMobile from "./EntryEdit_Mobile.vue";
  import EntryEditDesktop from "./EntryEdit_Desktop.vue";
 
  export default {
    name: 'EntryEdit',
    components: {
      MatchMedia,
      EntryEditMobile,
      EntryEditDesktop,
    },
  }
</script> 