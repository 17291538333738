<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')""
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>
        <p class="w-pagetitle" style="margin-left:20px;margin-right:5px; text-align:right;">library</p>
        <b-row class="justify-content-center">
          <p class="t-title">search wine in wynemaker database</p>
        </b-row>
        <b-row>
          <b-col md="10" style="padding:0" align="center">
            <LibrarySearch_Mobile/>
          </b-col>
        </b-row>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <MenuMobile/>
          </template>
        </b-sidebar>      
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import MenuMobile from './MenuMobile.vue';
  import LibrarySearch_Mobile from './LibrarySearch_Mobile.vue';

  import Wines_Search from '../Wines_Search.js'

  export default {
    name: "Library",
    components: {
      NoAccess,
      MenuMobile,
      LibrarySearch_Mobile,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      }
    },
    data() {
      return {
        debug : false,        
        isLoading : true,

        wines: [],

        keyword_forName: '',
        search_forName:true,
        keyword_forRegion: '',
        search_forRegion:false,
        keyword_forAppellation: '',
        search_forAppellation:false,
        keyword_forGrapes: '',
        search_forGrapes:false,

        showSearchZone: true,
        showResultZone: false,

        // Validation Logic
        nameValid : true,
        nameFeedback : '',

        leftVisible:false,
        rightVisible:false,
        screenCount:0,
        wineCount:0,
        wines_perScreen:6,

        isValid : true,
      }
    }, 
    async mounted() {
      this.isLoading = false;
    },
    methods:{
      async onSearch() {
        if (this.debug) console.log("Library in onSearch: Search pressed")
        this.wines = [];
        this.screenCount = 0;
        this.leftVisible = false;
        this.rightVisible = false;

        let postdata = {
          "name": this.keyword_forName,
          "region":this.keyword_forRegion,
          "appellation":this.keyword_forAppellation,
          "grapes":this.keyword_forGrapes,
          "skip":0,
          "limit":this.wines_perScreen};
        await Wines_Search.count(postdata)
        .then(response=>{
          if (this.debug) console.log("Library in onSearch: succesful wines search count = ", response)
          this.wineCount = response
        })
        .catch(error=>{
          if (this.debug) console.log("Library in onSearch: catch Wines_Search.count error = ", error)
          return
        })

        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("Library in onSearch: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("Library in onSearch: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("Library in onSearch: wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.wineCount > (this.screenCount+1)*this.wines_perScreen) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
      },
      async onLeft() {
        if (this.debug) console.log("Library in onLeft: Left clicked")
        this.screenCount--;

        this.wines = [];
        let postdata = {
          "name": this.keyword_forName,
          "region":this.keyword_forRegion,
          "appellation":this.keyword_forAppellation,
          "grapes":this.keyword_forGrapes,
          "skip":this.screenCount*this.wines_perScreen,
          "limit":this.wines_perScreen};
        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("Library in onSearch: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("Library in onSearch: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("Library in onRight: wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.screenCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.wineCount > (this.screenCount+1)*this.wines_perScreen) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
      },
      async onRight() {
        if (this.debug) console.log("Library in onRight: Right clicked")
        this.screenCount++;

        this.wines = [];
        let postdata = {
          "name": this.keyword_forName,
          "region":this.keyword_forRegion,
          "appellation":this.keyword_forAppellation,
          "grapes":this.keyword_forGrapes,
          "skip":this.screenCount*this.wines_perScreen,
          "limit":this.wines_perScreen};
        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("Library in onSearch: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("Library in onSearch: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("Library in onRight: wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.screenCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.wineCount > (this.screenCount+1)*this.wines_perScreen) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
      }
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>