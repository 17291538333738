// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';
import { API } from './API.js';

import axios from 'axios';

// const maker = WEBSERVER + ':'+ PORT + '/api/send_maker/'
// const cellar = WEBSERVER + ':'+ PORT + '/api/send_cellar/'

const maker = API + '/send_maker/'
const cellar = API + '/send_cellar/'

class Mail {
    static sendMaker(postdata) {
        console.log('Mail.sendMaker: postdata=',postdata)
        return axios.post(maker,postdata)
        .then(response => {
            console.log('Mail.sendMaker: ',response)
        })
        .catch(error => {
            console.log('Mail.sendMaker: ',error)
        })
    }
    static sendCellar(postdata) {
        console.log('Mail.sendCellar: postdata=',postdata)
        return axios.post(cellar,postdata)
        .then(response => {
            console.log('Mail.sendCellar: ',response)
        })
        .catch(error => {
            console.log('Mail.sendCellar: ',error)
        })
    }
}

export default Mail