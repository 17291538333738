<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <div class="welcome-overlay">
            <h1 class="main-title"><span style="color: rgb(195,147,130)">WYNE</span>LOVER</h1>
            <p class="main-subtitle">TERMS and CONDITIONS</p>
          </div> 
        </b-col>
      </b-row> 
      <b-row>
         <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8"> 
          <br>
            <div class="legal-list">
              <h1>Introduction</h1>
                <ol>
                  <li>
                    These Terms and Conditions are legally binding for users of the WyneLover websites and apps like  Wynemaker, Wynecellar and Wynetaster.
                  </li>
                  <li>
                    The Platforms are owned by N’JOY Information Technology, registered in de Crossroads Bank for Enterprises under number BE0455.595.637, and having its registered seat at Karel Rogierstraat 27, B-2000 Antwerpen, Belgium, hereinafter referred to as "we", “us” of “our”.
                  </li>  
                </ol>  
              <h1>Definitons</h1>
              <ol>
                <li>
                  <b>Contact Person</b>: The person to address in case of (GDPR-)problems or questions named Luc Dooms, with adress Karel Rogierstraat 27, B-2000 Antwerpen, Belgium and e-mail luc@dooms.eu.
                </li>
                <li>
                  <b>Intellectual Property Rights</b>: For purposes of this Agreement, the term "Intellectual Property" means any patent, copyright, trademark, trade name, service mark, service name, brand mark, brand name, logo, corporate name, Internet domain name or industrial design, any registrations thereof and pending applications therefor (to the extent applicable), any other intellectual property right (including, without limitation, any know-how, trade secret, trade right, formula, conditional or proprietary report or information, customer or membership list, any marketing data, and any computer program, software, database or data right), and license or other contract (including without limitation license(s) to use specific telephone numbers and/or radio channels/frequencies) relating to any of the foregoing, and any goodwill associated with the Platforms 
                </li>
                <li>
                  <b>Personal data</b>: Any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
                </li>
                <li>
                  <b>User</b>: Every entity that accesses Wynemaker, Wynecellar or Wynetaster on the WyneLover websites or by the mobile applications Wynemaker, Wynecellar or Wynetaster.
                </li>
                <li>
                  <b>Wynemaker</b> is an international online datasharingplatform in website and mobile application format, that enables wine producers to share information on their products and promote their products because the consumer will consult the associated Wynecellar, Wynetaster and other platforms for the enhanced guarantee of the accuracy of the information published. The wine producer is responsible for the information provided and thus contributes to the quality assurance of the information published.
                </li>
                <li>
                  <b>Wynecellar</b> is an international online datasharingplatform in website and mobile application format, that enables wine collectors and wine enthusiasts to inventorize their wine-stock, with the guarantee of the accuracy of the information on their inventory.
                </li>
                <li>
                  <b>Wynetaster</b> is an international online datasharingplatform in website and mobile application format, that digitalizes winetasting events with the guarantee of the accuracy of the information on the tasted wines.
                </li>
              </ol>
              <h1>Scope</h1>
                <ol>
                  <li>
                    These general terms and conditions (hereafter referred to as “Terms and Conditions”) apply between Us and the User for all use of the Platforms. The User agrees with the Terms and Conditions described in these Terms and Conditions for using the Platforms as described herein. If the User does not agree with the Terms, he or she may not use the Platforms.
                  </li>
                </ol>
              <h1>Registration and Use of the Platforms</h1>
                <ol>
                  <li>
                    In order to use the Platforms, the User must sign in by filling out a form and create a profile. The information provided will never be made public without authorization by the User, except for the profile name. The User can manage the visibility of its personal information under Profile Settings.
                  </li>
                  <li>
                    The User must provide us with correct account information of name, address and e-mail address, etc.
                  </li>
                  <li>
                    The User that uses our Platform without signing up, will only be able to utilize limited features of the Platforms. In this case we will only collect his or here data based on his or here consent to our cookie policy that may be found here.
                  </li>
                  <li>
                    Using the Platforms or some of the functionalities may be prohibited or restricted in certain countries.
                  </li>
                  <li>
                    If we receive notice or reasonably believe that someone under the legal drinking age in the jurisdiction in which it’s resides has provided us with personal information, we will promptly delete the account and all personal information that has been provided to us.
                  </li>
                </ol>                
              <h1>Content of the Platforms</h1>
                <ol>
                  <li>
                    The User is aware that the Platform is not obliged to provide any background check or approval procedure towards the Users and/or the content of the Platform. In no event we shall be liable (directly or indirectly) for any losses and damages resulting from information published on the Platforms between Users.
                  </li>
                </ol>   
              <h1>Prohibitions and obligations for the User</h1>
                <ol>
                  <li>
                    It is not allowed to use the Platforms for other purposes and commercial exploitation than described in Articles 1.2 to 1.4.
                  </li>
                  <li>
                    It is forbidden for the User to publish content that is offensive, inappropriate or illegal, infringing another person’s rights (including intellectual property rights, and rights of privacy and publicity), or otherwise in conflict with these Terms and Conditions. 
                  </li>
                  <li>
                    The User must at all times respect and comply with these Terms and Conditions.
                  </li>
                  <li>
                    The infringement of the above may resolve in deactivation the User’s account and/or banishment from the Platforms and/or the functionalities with any notice or without notice. 
                  </li>
                  <li>
                    The User is responsible for complying with the laws and regulations of the territory from which the Platforms are accessed or used.
                  </li>
                </ol>
              <h1>Use of data</h1>
                <ol>
                  <li>
                    The User that shares contributions, exchanges information with other Users and visits the Platforms is aware that we are free to dispose of this data, without limitation in time nor payment.
                  </li>
                </ol>  
              <h1>Intellectual property</h1>
                <ol>
                  <li>
                    All our intellectual property rights and derived rights are and will remain our exclusive property. By these intellectual property rights are meant copyright, trademark, design and model rights and/or other (intellectual property rights, including patentable or non-patentable technical and/or commercial know-how, methods and concepts etc). These are works that are protected by the provisions of intellectual property law. 
                  </li>
                  <li>
                    Pursuant to these Terms and Conditions, no transfer of intellectual property rights shall take place. Full or partial reproduction, modification or use of these elements protected by restrictive rights for any reason is strictly prohibited and constitutes criminal and civil forgery. Any infringement will be prosecuted to the full extent of the law.
                  </li>
                </ol>  
              <h1>Liabilities and warranty</h1>
                <ol>
                  <li>
                    We do not provide any warranty unless specific and expressly stated. If in extraordinary cases We should be liable, such liability is limited to 10.000 EUR.
                  </li>
                  <li>
                    We are never liable for errors on the Platforms, technical malfunctioning or underlying software, the services or any information provided by Users. 
                  </li>
                  <li>
                    The limitations and exclusions in this section (Liabilities and warranty) apply to the maximum extent permitted by applicable law.
                  </li>
                </ol>  
              <h1>Privacy and GDPR</h1>
                <ol>
                  <li>
                    The Platform is committed to the secure, transparent and confidential collection and processing of personal data. In particular, we want to protect the data of our Customers and Suppliers from, among other things, loss, leakage, error, unauthorized access or unlawful processing. These General Terms and Conditions inform the User about the collection and processing of personal data. By submitting personal data the User expressly declares to have taken note of this article and also expressly agrees to it, as well as to the processing itself.
                  </li>
                  <li>
                    We are the data controller of your personal data. In the collection and processing of your personal data, we respect the Belgian regulations on the protection of personal data as well as the General Data Protection Regulation of 25 May 2018.
                  </li>
                  <li>
                    We will in no way sell or pass on your personal information to third parties: data is stored securely in a non-publicly accessible database. Newsletters are only sent after explicit consent and can be unsubscribed at any time.
                  </li>
                  <li>
                    According to the User’s activities and relationship to our Platforms, he or she provides us with the following personal data: identity, contact details (name, title, address, e-mail address, tel. and cell phone numbers, fax number) and possibly bank account number. The User is responsible for all data provided to us. If the data is no longer up-to-date, please inform us by return. Within the framework of our services, we collect and process the identity and contact details of our Users, their staff, employees, appointees and other useful contacts. In addition to the data from Users, we also process personal data from others, such as possible new customers, useful contacts within our sector, network contacts, expert contacts, etc,... The personal data communicated by the User will only be used for the following purposes: sharing information on the platform, to establish communication between Users and to analyze preferences. Providing incorrect or false personal data is considered a violation of the present General Terms and Conditions. 
                  </li>
                  <li>
                    The personal data of the Users will in any case be kept for a period of 5 years. If Users wish to remove their personal data after this period of 5 years, they shall inform the Platform of this by letter. The personal data that we are required to keep for a longer period based on a specific legislation or in case of ongoing litigation for which the personal data is still necessary, will be kept after a period of 5 years.
                  </li>
                  <li>
                    In accordance with the Belgian privacy legislation and the provision of the General Data Protection Regulation, we inform you that you have the following rights as a User:
                    <ul>
                      <p>
                        - Right of access and examination: you have the right to know, free of charge, the data that we hold about you and to verify what it is used for;
                      </p>
                      <p>
                        - Right to rectification: you have the right to obtain rectification (correction) of your incorrect personal data, as well as to complete incomplete personal data;
                      </p>
                      <p>
                        - Right to data erasure or restriction: you have the right to request us to erase your personal data or to restrict its processing in the circumstances and under the conditions stipulated by the General Data Protection Regulation. We may refuse the data erasure or restriction of any personal data that is necessary for us to exercise a legal obligation;
                      </p>
                      <p>
                        - Right to data portability: you have the right to obtain the personal data that you have provided to us in a structured, common and machine-readable form. You have the right to transfer this data to another controller;
                      </p>
                      <p>
                        - Right to object: you have the right to object to the processing of your personal data for serious and legitimate reasons. However, please note that you cannot object to the processing of personal data necessary for us to carry out a legal obligation, the performance of the Services on our legitimate interest, and this as long as these data are necessary for the purposes for which they were collected;
                      </p>
                      <p>
                        - Right of withdrawal of consent: if the processing of personal data is based on the prior consent, you have the right to withdraw this consent. These personal data will then only be processed if we have another legal form for this purpose;
                      </p>
                      <p>
                        - Automatic decisions and profiling: we confirm that the processing of personal data does not include profiling and that you will not be subject to fully automated decisions.
                      </p>
                      You can exercise the aforementioned rights by contacting the Platform. The contact person for this is Luc Dooms with contact details Karel Rogierstraat 27, B-2000 Antwerpen, Belgium..
                    </ul>
                  </li>
                  <li>
                    The websites use cookies. During a visit to these websites "cookies" may be placed on the hard drive of the User’s computer. Cookies allow the browser to be recognized and personal preferences to be recorded. We use technical cookies to optimize our site and to offer you more comfort such as the automatic filling in of forms. We use cookies to keep track of your personal settings for future visits and to collect data about the traffic on our website so we can improve our service. The Privacy and Cookie Policy can be found here. 
                  </li>
                  <li>
                    The Customer is responsible for keeping his login data confidential and for the use of his password. 
                  </li>
                  <li>
                    The Platform keeps online (anonymous) visitor statistics to be able to see which pages of the Internet site are visited to what extent. By using our website, you agree to our use of cookies. Reviews left by the Customer may be further used for commercial purposes, such as use of the reviews in print ads and other social media. 
                  </li>
                </ol>  
              <h1>Termination</h1>
                <ol>
                  <li>
                    We are entitled to terminate a User account immediately without prior notice or liability, for any reason whatsoever, including without limitation if the User violates these Terms and conditions. Our rights to User content shall not be affected by the termination of the Terms and Conditions or a User account.
                  </li>                  
                  <li>
                    Termination of a User account does not relieve the User from any obligation under any other agreement that has not been specifically terminated, such as the Terms and Conditions Cookie or Privacy Policy and all provisions of the Terms and Conditions by which their nature should survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. 
                  </li>
                </ol>  
              <h1>Changes</h1>
                <ol>
                  <li>
                    These Terms and Conditions may be amended at Our sole discretion, followed by a notification to the Users. Users are bound by any changes to the Terms and Conditions when using the Platforms after changes have been posted.
                  </li>
                </ol>  
              <h1>Nullity</h1>
                <ol>
                  <li>
                    If a clause in the Terms and Conditions is not enforceable, the unenforceability of that clause will not affect any other sections of the Terms
                  </li>
                </ol>  
              <h1>Jurisdiction and applicable law</h1>
                <ol>
                  <li>
                    Every dispute or claim arising out of or in connection with the use of the Platforms shall be settled in accordance with Belgian law and treated by the competent court of Antwerp.
                  </li>
                </ol>  
            </div>     
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" class="text-right"> 
          <b-button v-on:click="gotoBack()" class="w-button-green">back</b-button>
        </b-col>
      </b-row> 
      <br> 
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NoAccess',
    data() {
      return {
        isLoading : true,
      }
    }, 
    mounted() {
      this.isLoading = false
    },
    methods: {
      gotoBack() {
        this.$router.push({path:'/Register'});
      }
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}

.legal-list {
  counter-reset: section;
}
.legal-list h1 {
  font-size: 14px;
  font-weight: bold;
  color:rgb(112,112,112) !important;
  text-align:left;
}
.legal-list h1:before {
  counter-increment: section;
  content: counter(section) ". ";
  margin: 0 0.5em 0 0;
}
.legal-list ol {
  counter-reset: clause;
  list-style: none outside none;
  text-indent: -26px;
}
.legal-list ol li {
  font-size: 12px;
  font-weight:normal;
  color:black !important;
  counter-increment: clause;
  list-style-position: outside;
}
.legal-list ol li:before {
  content: counter(section) "." counters(clause, ".") ".";
  margin: 0 0.5em 0 0;
}

</style> 