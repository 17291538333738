export default function formatDate(date) {
    let Month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul','Aug','Sep','Oct','Nov','Dec']
    let d = date.getDate();
    let m = date.getMonth();
    let y = date.getFullYear();
    // console.log("TMP date =", date, " d = ", d, " m = ", m, " y = ", y)
    let format_date
    if (d < 10 ) {
        format_date = '0'+ d + ' ' + Month[m] + ' ' + y;
    } else {
        format_date = d + ' ' + Month[m] + ' ' + y;
    }
    return format_date
}