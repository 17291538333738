<template>
  <div v-if="this.isLoading" class="title-bar">
    <b-form>
      <br><br><br><br><br>
      <h5 style='color:white'>loading</h5>
      <br>
       <b-spinner style="color: rgb(109,122,82) !important"
        variant="warning"
      ></b-spinner>
    </b-form>
  </div>
  <div v-else>
    <b-row style="margin:0">
      <b-col md="8" style="height:40vh;padding-left:0!important">
        <div class="login-picture">
          <!-- <div class="photography">
            <p> photography : tim mossholder </p>
          </div> -->
          <div class="login-overlay">
            <h1 class="main-title"><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</h1>
            <p class="main-subtitle">a simple tool to manage your winecellar</p>
            <br>
            <!-- <p class="main-subtitle">ONLINE TEST ONLY - ALL DATA ENTERED TODAY CAN BE LOST</p> -->
            <!-- <p class="main-subtitle">WILL BE RELEASED ON OCT,15th 2022</p> -->
          </div>
          <div class="copyright">
             <p style="text-align:center">funware for wine lovers developed and ©copyrighted by N'JOY Information Technology</p>
          </div> 
        </div>
      </b-col>
      <b-col md="4">
        <div style="height:5vh">
        </div>
        <div v-if="!this.noServer">
          <b-form class="w-login">
            <b-form-group>
              <label class="w-label" for="input-username">username</label>
              <b-form-input
                id="input-username"
                class = "w-input"
                style = "background-color:white !important"
                v-model="form.email"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.emailValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="email"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                  {{emailFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <label class="w-label" for="input-password">password</label>
              <b-form-input
                v-if="passwordVisible"
                id="input-password"
                class = "w-input"
                style = "background-color:white !important"
                v-on:keyup.enter="onLogin()"
                v-model="form.password"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.passwordValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="text"
                required
              ></b-form-input>
              <b-form-input
                v-else
                id="input-password"
                class = "w-input"
                style = "background-color:white !important"
                v-on:keyup.enter="onLogin()"
                v-model="form.password"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.passwordValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="password"
                required
              ></b-form-input>
              <font-awesome-icon
                id="eye"
                v-bind:style="{'color':eye_color, 'font-size':'16px', 'float':'right', 'position':'relative', 'bottom':'22px','right': '5px'}"
                icon="eye"
                @click="toggleVisibilityPassword"/>
              <b-form-invalid-feedback id="input-live-feedback">
                  {{passwordFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-button v-on:click="onLogin()" class="w-button-green-full btn-block">login</b-button>
             <p style="font-size:12px; margin-top:5px" v-on:click="forgotPassword()" class="w-cursor-click"><u>forgot password?</u></p>
          </b-form>
          <div style="height:5vh">
          </div>
          <b-form class="w-login">
            <p style="font-size:12px;margin-bottom:5px" class="w-cursor-default">don't have a WyneLover account yet?</p>
            <b-button v-on:click="onRegister()" class="w-button-pink-full btn-block">register</b-button>
          </b-form>
        </div>
        
        <div v-else>
          <h1 class="w-title text-center">No Server Available</h1>
          <h2 class="w-subtitle text-center">Please try again later</h2>
        </div>
        <br>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';
  
  import Connection from '../Connection.js';
  import Authenticate from '../Authenticate.js';
  import Users from '../Users.js';

  export default {
    name: 'Login',
    data() {
      return {
        debug: false,
        form: {
          email: '',
          password: '',
        },
        user: {
           image_files:[],
        },
        user_id:0,
        profile_picture:'',

        emailValid : true,
        passwordValid : true,
        emailFeedback : '',
        passwordFeedback : '',
        isConfirmed: false,

        passwordVisible: false,
        eye_color:"lightgrey",

        isLoading: true,
        noServer: true,
      }
    },
    async mounted() {
      while (this.noServer) {
        if (this.debug) console.log("Login in mounted() : this.noServer = ", this.noServer)
        await Connection.test()
        .then(response=>{
          this.noServer = !response;
          this.isLoading = false;
        })
      }
    },
    methods: { 
      validate() {
        // validate email 
        let hasAt = false;
        let hasDot = false;
        for (let i=0;i < this.form.email.length;i++) {
            if (this.form.email[i] === '@') {
                hasAt = true;
            }
            if (this.form.email[i] === '.') {
                hasDot = true;
            }
        }
        this.emailValid = hasAt && hasDot
        if ( ! this.emailValid) {
            this.emailFeedback='This is an invalid email format';
            this.passwordValid = true;
        }
      },  
      async onLogin() {
        if ( this.noServer ) {
          return;
        }
        this.validate();

        let challenge = {"email": this.form.email, "hashed_password": this.CryptoJS.SHA256(this.form.password).toString()}
        if(this.emailValid) {
          let authOK = false;
          if (this.debug) console.log("Login in onLogin : challenge ", challenge);
          await Authenticate.getToken(challenge)
          .then(response=>{     
            if (this.debug) console.log("Login in onLogin : response ", response, "successfully fetched from DB");
            if (response === 0) {
              this.emailValid = false;
              this.emailFeedback = 'email is unknown, maybe you need to register';
              this.passwordValid = true;
            } else if (response === 1) {
              this.passwordValid = false;
              this.passwordFeedback = 'password incorrect';
            } else if (response === 2) {
              this.emailValid = false;
              this.emailFeedback = 'email is registered, but not confirmed, please check you mail'
              this.passwordValid = true;
            } else {
              this.user_id = response;
              authOK = true;
            }
          })
          .catch(error=>{
            if (this.debug) console.log("Login in onLogin : await Authenticate.getToken - ERROR ", error);
            return
          })

          if(authOK) {
            await Users.getone(this.user_id)
            .then(response=>{
              if (this.debug) console.log("Login in onLogin : await Users.getone reponse = ", response);
              this.user = response;
              delete this.user._id;
            })
            .catch(error=>{
              if (this.debug) console.log("Login in onLogin : await Users.getone - ERROR ", error);
            })

            if (this.user.cellar_id == 0 || typeof this.user.cellar_id == 'undefined') {
              this.$router.push({path:'/AddCellar2Registration', query: {user_id: this.user_id}} );
            } else {
              // HERE YOU FIRE OF THE ROUTER TO ACCESS THE APPLICATION
              try {
                this.$store.commit('login')
                this.$store.commit('setUser',this.user)
                this.$store.commit('setUserID',this.user_id)
                if ( this.user.image_files.length > 0) {
                  this.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
                }
                this.$store.commit('setProfilePicture',this.profile_picture)

                this.$store.commit('setCellar',{})
                this.$store.commit('setCellarID',0)
                this.$store.commit('setCellarLoaded',false)

                this.$store.commit('setNiches',[])
                this.$store.commit('setNichesLoaded',false)

                this.$store.commit('setSearchState',{})
                
                this.$store.commit('setPage','Dashboard')
                this.$router.push({path:'/Dashboard', query: {cellar_id:this.user.cellar_id }} );
              } catch(err) {
                alert("ERROR : "+ err)
              }
            }
          }
        } 
      },
      onRegister() {
        this.$router.push({path:'/Register'} );  
      },
      forgotPassword() {
        this.$router.push({path:'/ForgotPassword'} );
      },

      async toggleVisibilityPassword() {
        if (this.passwordVisible == true) {
          this.passwordVisible = false
          this.eye_color = "lightgrey"
        } else {
          this.passwordVisible = true
          this.eye_color = "rgb(109,122,82)"
        }
      },
    }
  }
</script>
<style scoped>
.login-picture {
    height:100% !important;
    width:100% !important;
    
    background-image: url("../assets/img/winecellar-mobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;
}
.login-overlay {
  position: relative;
  top: 13vh;
}

.copyright {
  position: absolute;
  font-size:clamp(8px,1.5vw, 14px)!important;
  top: 37vh;
  color: white;
}

.title-bar {
  text-align: center;
}

.bottom-center {
  width:100% !important;
  position: absolute;
  bottom: 5px;
  text-align:center;
  color:white;
  margin:0;
}

.photography {
  position:absolute;
  top : 10px;
  right : 10px;
  color :  rgb(195,147,130);
  font-size: clamp(8px, 1vw, 12px);
}
</style>
