<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <SearchMobile  v-if="matches" />
    <SearchDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import SearchMobile from "./Search_Mobile.vue";
  import SearchDesktop from "./Search_Desktop.vue";
 
  export default {
    name: 'Desktop',
    components: {
      MatchMedia,
      SearchMobile,
      SearchDesktop,
    },
  }
</script> 