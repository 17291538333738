<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
            <p v-if="niche_id == 0" class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;" >
              {{cellar.name}} > add unit
            </p>
            <p v-else class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">
              {{cellar.name}} > edit unit
            </p>
            <div class="w-tile text-center" style="width:300px;height:200px"> 
              <div v-if="niche.image_files[0]" 
                class="c-image-part" 
                v-bind:style="{ 'background-image': 'url(' + niche.image_files[0].dataURL +')' }"
              >
              </div>
              <div v-else
                class="c-image-part"
              />
              <div class="c-text-part">
                <p class="c-title" v-if="niche.code">{{niche.code}} - {{niche.name}}</p>
                <p class="c-title" v-else>{{niche.name}}</p>
                <p class="c-subtitle" v-if="niche.description">{{niche.description}}</p>
                <p class="c-bottles">{{niche.number_of_bottles}}</p>
              </div>
              <div class="dropbox-overlay text-center" style="width:300px;height:200px">
                <vue-dropzone 
                  ref="imgDropzone" id="profiledropzone" 
                  :options="dropzoneOptions"
                  @vdropzone-thumbnail="vfileAdded" 
                >
                </vue-dropzone>    
              </div>
            </div>
            <div class="m-box">
              <b-form autocomplete="false">
                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-name">name</label>
                  <b-form-input
                    id="input-firstname"
                    class = "w-input"
                    v-model="niche.name"
                    v-bind:class="{'is-invalid' : !this.nameValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{nameFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>
                          
                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-code">code</label>
                  <b-form-input
                    id="input-code"
                    class = "w-input"
                    v-model="niche.code"
                    v-bind:class="{'is-invalid' : !this.codeValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{codeFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-description">description</label>
                  <b-form-input
                    id="input-description"
                    class = "w-input"
                    v-model="niche.description"
                    v-bind:class="{'is-invalid' : !this.descriptionValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{descriptionFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-form>
            </div>
            <div v-if="ready4Save" class="m-box">
              <b-button v-on:click="onSave()" class="w-button-green-full">save</b-button>
              <b-button v-on:click="onBack()" class="w-button-pink-full" style="margin-top:10px">back</b-button>
            </div>
            <div v-else class="m-box">
              <b-button class="w-button-green-full">
                saving 
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  import Cellars from '../Cellars.js'
  import Niches from '../Niches.js'

  export default {
    name: 'NicheEdit',
    components:{
      NoAccess,
      Menu,
      vueDropzone: vue2Dropzone,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,

        niche: {
            name:'',
            code:'',
            description:'',
            cellar_id:0,
            entry_ids:[],
            image_files: [],
            number_of_bottles:0,
        },
        niche_id:0,
       
        cellar:{},
        cellar_id:0,

        // dropzone data
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          addRemoveLinks: true,
          thumbnailWidth: 100,
          thumbnailHeight: 100,
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : 'Drop your Unit Picture here or Click.',
          maxFilesize: 20,
          headers: { "My-Awesome-Header": "header value" }
        },
        dropzoneFilled: false,
        
        // Validation Logic
        isValid : true,

        nameValid : true,
        nameFeedback : '',

        codeValid : true,
        codeFeedback : '',

        descriptionValid : true,
        descriptionFeedback : '',

        exists : false,
        ready4Save : true,
      }
    },
    async mounted() {
      // Load the Cellar
      if (this.currentCellarLoaded) {
        if (this.debug) console.log("NicheShow.mounted: CURRENT CELLAR LOADED")
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (this.debug) console.log("NicheShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      }
      else {
        if (this.debug) console.log("NicheShow.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
        this.cellar_id = this.$route.query.cellar_id
        await Cellars.getone(this.cellar_id)
        .then(response => {
          this.cellar = response
          delete this.cellar._id
          if (this.debug) console.log("NicheShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
        })
        .catch(error =>{
          if (this.debug) console.log("NicheShow.mounted: catch Cellar.getone with error: ", error)
        })

        this.$store.commit('setCellar',this.cellar)
        this.$store.commit('setCellarID',this.cellar_id)
        this.$store.commit('setCellarLoaded',true)

        if (this.debug) console.log("NicheShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
      }

      if (typeof this.$route.query.niche_id !== "undefined") {
        this.niche_id = this.$route.query.niche_id;
        await Niches.getone(this.niche_id)
        .then(response=> {
          this.niche = response;
          delete this.niche._id
          if (this.debug) console.log( "Niche in mounted: Niche "+ this.niche.name+ " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("Niche in mounted: await Niche", error);
        })
      } else {
        this.niche_id = 0;
        if (this.debug) console.log("Niche in mounted: INFO niche_id = ", this.niche_id);
      }
      this.isLoading = false;
    },
    methods: {
      async validate(){
          if( this.niche.name.length >= 3) {
              this.nameValid = true;
          } else {
              this.nameValid = false;
              this.nameFeedback = 'name needs at least 3 characters'
          }
          this.isValid = this.nameValid;
      },
      async test_exists(niche_id){
        if(typeof niche_id === 'undefined') {
          this.exists = false
        } else {
          await Niches.getone(niche_id)
          .then(response => {
            if (this.debug) console.log("NicheEdit in test_exists: response = ", response)
            this.exists = true;
          })
          .catch(error=> {
            if (this.debug) console.log("NicheEdit in test_exists: Catch Niches.getone error = ", error)
            this.exists = false;
          })
        }
      },  
      async onSave() {
        if (this.debug) console.log("")
        if (this.debug) console.log("NicheEdit.onSave:START")
        this.ready4Save = false;

        this.niche_id = this.$route.query.niche_id;
        await this.test_exists(this.niche_id);
        await this.validate();
        this.cellar_id = this.$route.query.cellar_id
        this.niche.cellar_id = this.cellar_id;

        if(this.isValid){
          //THIS IS WHERE YOU SUBMIT DATA TO SERVER
          if (this.exists) {
            if (this.debug) console.log("NicheEdit.onSave: Niche exists, and has _id=", this.$route.query.niche_id)
            await Niches.update(this.niche_id,this.niche)
            .then(response=>{
               if (this.debug) console.log("NicheEdit.onSave: Niche succesfully updated : ", response);
            })
            .catch(error=>{
               if (this.debug) console.log("NicheEdit.onSave: catch await Niche.update ", error);
            })
          } else {
            console.log("NicheEdit.onSave: Niche does NOT exists")
            await Niches.create(this.niche)
            .then(response=>{
              this.niche_id = response;
              if (this.debug) console.log("NicheEdit.onSave: Niche succesfully mounted : id = ", response);
            })
            .catch(error=>{
               if (this.debug) console.log("Profile in mounted: catch await Niche.create ", error);
            }) 
          
            await Cellars.getone(this.cellar_id)
            .then(response => {
              this.cellar = response;
              if (this.debug) console.log("NicheEdit.onSave: this.cellar = ", this.cellar)
            })
            .catch(error=> {
              this.cellar = {};
              if (this.debug) console.log("NicheEdit.onSave: Catch Cellar.getone error = ", error)
              return;
            })
            if (this.debug) console.log("NicheEdit.onSave: Adding niche to niche_ids from cellar ", this.cellar)       
            this.cellar.niche_ids.push(this.niche_id);
            this.cellar_id = this.cellar._id;
            delete this.cellar._id;  
            
            await Cellars.update(this.cellar_id,this.cellar)
            .then(response=>{
              if (this.debug) console.log("NicheEdit.onSave: Cellar succesfully added niche : id = ", response);
            })
            .catch(error=>{
              if (this.debug) console.log("Profile in mounted: catch await Cellar.update ", error);
            })
            
            // Update the store
            this.$store.commit('setCellar',this.cellar)
          }

          this.ready4Save = true;
          if (this.debug) console.log("NicheEdit.onSave:STOP")
          
          this.$store.commit('setNichesLoaded',false)
          this.$router.push({path:'/Cellar', query: {user_id:this.$route.query.user_id,cellar_id:this.$route.query.cellar_id }});
          await this.$forceUpdate();
        } 
      },
      
      onReset() {
        // Reset our niche values
        this.niche.name = ''
        this.niche.code = ''
        this.niche.description = ''
      },

      onDelete() {
        if(this.isValid){
           if (this.debug) console.log("NicheEdit in onDelete - Delete Button pressed");
        } 
      },

      onBack() {
        this.$store.commit('setPage','Cellar')
        this.$router.push({path:'/Cellar', query: {user_id:this.$route.query.user_id,cellar_id:this.$route.query.cellar_id }});
      },

      async vfileAdded(new_image_file) {
        if ( !new_image_file.manuallyAdded ) {
           if (this.debug) console.log("niche picture URL: ", new_image_file);
          if (this.niche.image_files.length > 0) {
            var old_image_file = this.niche.image_files.pop();
            this.$refs.imgDropzone.removeFile(old_image_file);
          }
          let org_base64 = new_image_file.dataURL;
          let cc_base64 = await cc64(org_base64,2,1);
          new_image_file.dataURL = cc_base64;
          this.niche.image_files.push(new_image_file);
          this.hasChanged = true;
        }
      },
      vfileRemoved(image_file) {
        // this.niche.image_files.pop(image_file);
        for( var i = 0; i < this.niche.image_files.length; i++){
          if ( this.niche.image_files[i].dataURL === image_file.dataURL) { 
            this.niche.image_files.splice(i, 1); 
          }
        }
        this.hasChanged = true;
      },
      template: function () {
         return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                      <div class="dz-thumbnail" data-dz-thumbnail-bg></div>
                  </div>
                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                  <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                  <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                </div>`;
      },
      // template: function () {
      //    return `<div class="dz-preview dz-file-preview">
      //             <div class="dz-image">
      //                 <div class="dz-thumbnail" data-dz-thumbnail-bg></div>
      //                 <div class="thumbnail-overlay">
      //                   <div class="text" style="text-align: center; margin-top: 30px" data-dz-remove>remove</div>
      //                 </div>
      //             </div>
      //             <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      //             <div class="dz-error-message"><span data-dz-errormessage></span></div>
      //             <div class="dz-success-mark"><i class="fa fa-check"></i></div>
      //             <div class="dz-error-mark"><i class="fa fa-close"></i></div>
      //           </div>`;
      // },      
      thumbnail: function(file, dataUrl) {
        var j, len, ref, thumbnailElement;
        if (file.previewElement) {
            file.previewElement.classList.remove("dz-file-preview");
            ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
            for (j = 0, len = ref.length; j < len; j++) {
                thumbnailElement = ref[j];
                thumbnailElement.alt = file.name;
                thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
            }
            return setTimeout(((function() {
                return function() {
                    return file.previewElement.classList.add("dz-image-preview");
                };
            })(this)), 1);
        }
      } 
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
#profiledropzone {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;
  border-style: none;
  border-radius: 5px;

  color:white;

  transition: background-color .2s linear;
}

.c-image-part {
  background-image: url("../assets/img/niche-picture.jpg")
}
</style>