import { API } from './API.js';
import axios from 'axios';

const url = API + '/userid_from_email/';

const debug = false;

class UserID_from_Email {
    static getToken(postdata) {
    return axios.post(url, postdata)
        .then(response => {
            if (debug) console.log('UserID_from_Email.getToken: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('UserID_from_Email.getToken: ',error)
        })
    }
}

export default UserID_from_Email