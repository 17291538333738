<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
          <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <div>
        <br>
        <b-row style="height:40vh;margin:0" class="welcome-picture">
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" style="height:100%">
            <div class="welcome-overlay">
              <h1 class="main-title"><span style="color: rgb(195,147,130)">WYNE</span>CELLAR</h1>
              <p class="main-subtitle">RESET PASSWORD</p>
            </div> 
          </b-col>
        </b-row>
        <br>
        <br>
        <br> 
        <b-row>
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" class="text-left">
            <b-form v-if="!isUpdated">
              <b-form-group>
                <label class="w-label w-required" for="input-newpassword">new password</label>
                <b-form-input
                  id="input-newpassword"
                  class = "w-input"
                  type="password"
                  v-model="new_password"
                  v-bind:class="{'is-invalid' : !this.new_passwordValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  >
                </b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{new_passwordFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <label class="w-label  w-required" for="input-confirm_password">confirm new password</label>
                  <b-form-input
                  id="input-confirm_password"
                  class = "w-input"
                  type="password"
                  v-model="confirm_password"
                  v-bind:class="{'is-invalid' : !this.new_passwordValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{new_passwordFeedback}}
                  </b-form-invalid-feedback>
              </b-form-group>
            </b-form>
            <div v-else>
              <p class="w-title">Hey {{user.firstname}} {{user.lastname}}, </p>
              <p class="w-text">We succesfully reset the password of <strong>{{user.email}}</strong>.</p>
              <p class="w-text">Press start to login with your new credentials.</p>
              <br>
            </div>  
          </b-col>
        </b-row>  
        <b-row>
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" class="text-right"> 
            <b-button v-if="!isUpdated" v-on:click="onSubmit()" class="w-button-green">submit</b-button>
            <b-button v-else v-on:click="onStart()" class="w-button-green">start</b-button>
          </b-col>
        </b-row>
      </div>  
    </div>
  </div>
</template>

<script>
  import Users from '../Users.js';

  export default {
    name: 'ResetPassword',
    data() {
      return {
        debug: true,

        user: {
        },
        user_id: 0,

        new_password: '',
        confirm_password: '',

        old_passwordValid: true,
        old_passwordFeedback: '',
        
        new_passwordValid: true,
        new_passwordFeedback: '',

        isValid: false,
        isUpdated: false,

        isLoading: true,
      }
    }, 
    async mounted() {
      this.isLoading = false
      this.user_id = this.$route.query.user_id
    },
    methods: {
      validateNewPassword() { 
        let min8char = false  
        let hasUpper = false
        let hasLower = false
        let hasDigit = false
        
        if (this.new_password.length > 7) {
          min8char = true;
        }
        for (let i=0; i < this.new_password.length;i++) {
          let c = this.new_password[i]
          if (c >= '0' && c <= '9') {
            hasDigit = true;
          } else {
            if (c === c.toUpperCase() ) {
              hasUpper = true; 
            }
            if (c === c.toLowerCase() ) {
              hasLower = true; 
            }
          }
        }
        this.new_passwordValid = min8char && hasUpper && hasLower && hasDigit
        if ( !this.new_passwordValid ) {
          this.new_passwordFeedback = 'Password needs at least'
          if( !min8char ) {
            this.new_passwordFeedback += ' 8 characters'
          }
          if( !hasUpper ) {
            this.new_passwordFeedback += ' one uppercase'
          }
          if( !hasLower ) {
            this.new_passwordFeedback += ' one lowercase'
          }
          if( !hasDigit) {
            this.new_passwordFeedback += ' one digit'
          }
          if (this.debug) console.log("validateNewPassword: START")
          if (this.debug) console.log("validateNewPassword: min8char = ", min8char)
          if (this.debug) console.log("validateNewPassword: hasUpper = ", hasUpper)
          if (this.debug) console.log("validateNewPassword: hasLower = ", hasLower)
          if (this.debug) console.log("validateNewPassword: hasDigit = ", hasDigit)
          if (this.debug) console.log("validateNewPassword: --------------------")
          if (this.debug) console.log("validateNewPassword: new_passwordFeedback = ", this.new_passwordFeedback)
          if (this.debug) console.log("validateNewPassword: STOP")
        }
      },
      validate(){
        if (this.new_password != this.confirm_password) {
          this.isValid = false
          this.new_passwordValid = false
          this.new_passwordFeedback = 'The passwords did not correspond. Try again.'
        } else {
          this.validateNewPassword();
          this.isValid = this.new_passwordValid;
        }
      },
      
      async onSubmit() {
        this.validate();
        if (this.isValid) {
          await Users.getone(this.user_id)
          .then(response => {
            if (this.debug) console.log("ResetPassword in onSubmit: User ", response , " succesfully retreived!")
            this.user = response;
            delete this.user._id;
          })
          .catch(error => {
            if (this.debug) console.log("ResetPassword in onSubmit: Catch is await Users.getone error:", error)
          })
          
          this.user.hashed_password = this.CryptoJS.SHA256(this.new_password).toString();
          
          await Users.update(this.user_id,this.user)
          .then(response => {
            if (this.debug) console.log("ResetPassword in onSubmit: User ", response , " succesfully updated!")
            this.isUpdated = true;
          })
          .catch(error => {
            if (this.debug) console.log("ResetPassword in onSubmit: Catch is await Users.update error:", error)
          })
        } else {
          if (this.debug) console.log("ResetPassword in onSubmit: invalid password combination.")
        }
      },

      onStart() {
        this.$router.push({path:'/'} );
      }
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}

.over {
    position: relative;
    width:90%;
    top: -50px;
    left: 45%;
    z-index: 3;
    color: black;
    padding: 3% 15% 10% 10%;
    background-color:white;
    border-radius: 1%;
      -webkit-transform: translate3d(-50%,0,0);
         -moz-transform: translate3d(-50%,0,0);
          -ms-transform: translate3d(-50%,0,0);
           -o-transform: translate3d(-50%,0,0);
              transform: translate3d(-50%,0,0);
}

.main-title {
  font-family: 'Segoe UI';
  font-size: 44px;
  font-weight: bolder;
  color: rgb(112,112,112);
}

.main-text {
  font-family: 'Segoe UI';
  font-size: 16px;
  color: rgb(112,112,112);
}

.w-picture{
  height: 200px;
  width: auto;
}
.w-detail {

}
.w-title {
  font-size: 18px;
  margin: 10px 0 0 0;
}

.w-subtitle {
  font-size:14px;
  margin:0.5vm;
}

.w-text {
  margin:0;
  font-size: 12px;
}

.w-smalltext {
  padding-top : 10px;
  font-size: 10px;
}
</style> 