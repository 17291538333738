<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <ArchiveMobile  v-if="matches" />
    <ArchiveDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import ArchiveMobile from "./Archive_Mobile.vue";
  import ArchiveDesktop from "./Archive_Desktop.vue";
 
  export default {
    name: 'Desktop',
    components: {
      MatchMedia,
      ArchiveMobile,
      ArchiveDesktop,
    },
  }
</script> 