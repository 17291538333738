// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';
import { API } from './API.js';

import axios from 'axios';

// const url = WEBSERVER + ':' + PORT + '/api/transactions/';

const url = API + '/transactions/';
const debug = false;

class Transactions {
    // get transactions
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(transaction=> ({
                        ...transaction,
                        name: transaction.name
                    }))
                );
                if (debug) console.log("data = " + data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one transaction
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Transactions.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Transactions.getone: ',error)
            })
    }

    // create transaction
    static create(postdata) {
        if (debug) console.log('Transactions.create - url: ',url)
        if (debug) console.log('Transactions.create - postdata: ',postdata)
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Transactions.create SUCCESSFUL: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Transactions.create ERROR : ',error)
                return 0
            })
    }

    static update(id,postdata) {
        if (debug) console.log('Transactions.update: id=',id)
        if (debug) console.log('Transactions.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('Transactions.update: ',response)
                return id
            })
            .catch(error => {
                console.log('Transactions.update: ',error)
            })
    }

    // delete transaction
    static delete(id) {
        return axios.delete(`${url}${id}`)
    } 
}

export default Transactions