import { API } from './API.js';

import axios from 'axios';

const url = API + '/database/'
const debug = false;

class Database {
    // get database
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(tasting_note=> ({
                        ...tasting_note,
                        name: tasting_note.name
                    }))
                );
                if (debug) console.log("TastingNotes.get: data = ", data)
            } catch(err) {
                reject(err);
            }

        })
    }
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Database.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Database.getone: ',error)
            })
    }
}

export default Database