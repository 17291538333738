<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <div class="e-box" v-if="showSearchZone">
          <b-form aria-autocomplete="off">
            <b-form-group>
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-name">name</label>
                  <b-form-input
                    id="input-name"
                    class="w-input"
                    v-model="keyword_forName"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-name">region</label>
                  <b-form-input
                    id="input-name"
                    class="w-input"
                    v-model="keyword_forRegion"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-appellation">appellation</label>
                  <b-form-input
                    id="input-appellation"
                    class="w-input"
                    v-model="keyword_forAppellation"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group> 
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-grapes">grapes</label>
                  <b-form-input
                    id="input-grapes"
                    class="w-input"
                    v-model="keyword_forGrapes"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.isValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-row> 
              <b-col class="text-right">
                <b-button v-on:click="onSearch()" class="w-button-green">search</b-button>
              </b-col>
          </b-row>
          </b-form>
        </div>
        <div class="e-box" style="margin:0 10px 0 10px;padding:0 10px 0 10px!important">
          <b-row>
            <b-col class="text-left">
              <b-img v-if="leftVisible"
                width=30px
                height=30px
                :src="require('@/assets/img/left.png')"
                @click="onLeft"
              >
              </b-img>
            </b-col>
            <b-col xs="6">
              <p class="w-smalltext" style="text-align:center">this query has {{this.wineCount}} hits, page {{this.pageCount}}/{{this.totalPages}}</p>
            </b-col>
            <b-col class="text-right">
              <b-img v-if="rightVisible"
                width=30px
                height=30px
                :src="require('@/assets/img/right.png')"
                @click="onRight"
              >
              </b-img>
            </b-col>
          </b-row>
        </div>
        <div class="e-box" v-if="showResultZone"  style="margin:0 10px 0 10px;padding:0 10px 0 10px!important">
            <b-col
              v-for="(wine, index) in wines"
              v-bind:item="wine"
              v-bind:index="index"
              v-bind:key="wine._id" 
              class="w-tile m-2 d-inline-flex justify-content-center"
              xs="12" sm="6" md="4" lg="2" xl="2"
              style ="display:block; min-width:300px; margin:20px !important"
            >
              <b-row 
                style="width:95%"
                v-on:click="gotoWine(wine)"
              >
                <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                  <b-img center v-if="wine.image_files[0]"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="wine.image_files[0].dataURL" 
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </b-col>
                <b-col cols="9" class="d-table-cell">
                  <div>
                    <p class="t-title">{{wine.name}}</p>
                    <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                    <hr style="margin:5px 0 5px 0">
                    <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                    <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                    <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                  </div>

                  <div 
                    v-if="wine.certified==true"
                    style="position:absolute;top:168px;right: 0px;height: 28px;width: 28px;">
                    <font-awesome-icon :id="'q'+ index" style="font-size:24px;font-weight:bold;color:rgb(109, 122, 82)" icon="check" />
                    <b-tooltip :target="'q'+ index" custom-class="w-tooltip-good">certified by the WyneMaker</b-tooltip>
                  </div>
                  <div 
                    v-else
                    style="position:absolute;top:168px;right: 0px;height: 28px;width: 28px;">
                    <font-awesome-icon :id="'q'+ index" style="font-size:24px;font-weight:bold;color:rgb(195, 147, 130)" icon="question" />
                    <b-tooltip :target="'q'+ index" custom-class="w-tooltip-bad">not certified</b-tooltip>
                  </div>

                </b-col>
              </b-row>
            </b-col>
        </div>    
      </b-container>
    </div>
  </div>
</template>

<script>
  import Wines_Search from '../Wines_Search.js'

  export default {
    name: "LibrarySearch",
    components: {
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      searchState() {
        return this.$store.state.search_state
      },
      totalPages() {
        return Math.ceil(this.wineCount/this.wines_perPage)
      }
    },
    data() {
      return {
        debug: false,
        
        isLoading: true,

        wines: [],

        keyword_forName: '',
        search_forName:true,
        keyword_forRegion: '',
        search_forRegion:false,
        keyword_forAppellation: '',
        search_forAppellation:false,
        keyword_forGrapes: '',
        search_forGrapes:false,

        showSearchZone: true,
        showResultZone: false,

        // Validation Logic
        nameValid : true,
        nameFeedback : '',

        pageCount:0,
        wineCount:0,
        wines_perPage:6,
        leftVisible:false,
        rightVisible:false,

        isValid : true,
      }
    }, 
    async mounted() {
      if (Object.keys(this.searchState).length != 0) {        
        this.keyword_forName = this.searchState.keyword_forName;
        this.search_forName = this.searchState.search_forName;
        
        this.keyword_forRegion = this.searchState.keyword_forRegion;
        this.search_forRegion = this.searchState.search_forRegion;
        
        this.keyword_forAppellation = this.searchState.keyword_forAppellation;
        this.search_forAppellation = this.searchState.search_forAppellation;
        
        this.keyword_forGrapes = this.searchState.keyword_forGrapes;
        this.search_forGrapes = this.searchState.search_forGrapes;
       
        await this.onSearch();
      }
      this.isLoading = false;
    },
    methods:{
      async onSearch() {
        if (this.debug) console.log("")
        if (this.debug) console.log("LibrarySearch.onSearch: START")

        this.wines = [];
        this.pageCount = 0;
        this.leftVisible = false;
        this.rightVisible = false;

        let postdata = {
          "name": this.keyword_forName,
          "region": this.keyword_forRegion,
          "appellation": this.keyword_forAppellation,
          "grapes": this.keyword_forGrapes,
          "skip": 0,
          "limit": this.wines_perPage};
        
        if (this.debug) console.log("LibrarySearch.onSearch: postdata = ", postdata)
        await Wines_Search.count(postdata)
        .then(response=>{
          if (this.debug) console.log("LibrarySearch.onSearch: succesful wines search count = ", response)
          this.wineCount = response
        })
        .catch(error=>{
          if (this.debug) console.log("LibrarySearch.onSearch: catch Wines_Search.count error = ", error)
          return
        })

        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("LibrarySearch.onSearch: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("LibrarySearch.onSearch: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("LibrarySearch.onSearch: wines = ", this.wines)
        if (this.wines.length > 0) {
          this.pageCount = 1;
          this.showResultZone = true;
        }
        if (this.wineCount > this.pageCount*this.wines_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
        if (this.debug) console.log("LibrarySearch.onSearch: STOP")
      },

      async onLeft() {
        if (this.debug) console.log("")
        if (this.debug) console.log("LibrarySearch.onLeft: START")
        this.pageCount--;

        this.wines = [];
        let postdata = {
          "name": this.keyword_forName,
          "region":this.keyword_forRegion,
          "appellation":this.keyword_forAppellation,
          "grapes":this.keyword_forGrapes,
          "skip":(this.pageCount-1)*this.wines_perPage,
          "limit":this.wines_perPage};
        if (this.debug) console.log("LibrarySearch.onLeft: postdata = ", postdata)

        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("LibrarySearch.onLeft: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("LibrarySearch.onLeft: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("LibrarySearch.onLeft: wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.pageCount > 1) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.wineCount > this.pageCount*this.wines_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
        if (this.debug) console.log("LibrarySearch.onLeft: STOP")
      },

      async onRight() {
        if (this.debug) console.log("")
        if (this.debug) console.log("LibrarySearch.onRight: START")
        this.pageCount++;

        this.wines = [];
        let postdata = {
          "name": this.keyword_forName,
          "region":this.keyword_forRegion,
          "appellation":this.keyword_forAppellation,
          "grapes":this.keyword_forGrapes,
          "skip":(this.pageCount-1)*this.wines_perPage,
          "limit":this.wines_perPage};
        if (this.debug) console.log("LibrarySearch.onLeft: postdata = ", postdata)

        await Wines_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("LibrarySearch.onSearch: succesful wines search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.wines[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("LibrarySearch.onSearch: catch Wines_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("LibrarySearch.onRight: wines = ", this.wines)
        if (this.wines.length > 0) this.showResultZone = true;
        if (this.pageCount > 1) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.wineCount > this.pageCount*this.wines_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
        if (this.debug) console.log("LibrarySearch.onRight: STOP")
      },

      gotoWine(wine) {
        let local_debug = false;
        if (local_debug) console.log("LibrarySearch.gotoWine: START: wine = ", wine)
        let search_state = {
          search_forName:this.search_forName,
          keyword_forName:this.keyword_forName,
          search_forRegion:this.search_forRegion,
          keyword_forRegion:this.keyword_forRegion,
          search_forAppellation:this.search_forAppellation,
          keyword_forAppellation:this.keyword_forAppellation,
          search_forGrapes:this.search_forGrapes,
          keyword_forGrapes:this.keyword_forGrapes
        }
        this.$store.commit('setPage','WineShow')
        this.$store.commit('setSearchState',search_state)
        if (wine.winery_id) {
          this.$router.push({path:'/WineShow', query: {cellar_id:this.$route.query.cellar_id,niche_id:this.$route.query.niche_id,wine_id:wine._id}})
        } else {
          this.$router.push({path:'/WineShow', query: {cellar_id:this.$route.query.cellar_id,niche_id:this.$route.query.niche_id,c_wine_id:wine._id}})
        }
      }
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");

</style>

<style scoped>
</style>