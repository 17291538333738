<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <div class="e-box" v-if="showSearchZone">
          <b-form aria-autocomplete="off">
            <b-form-group>
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-name">name</label>
                  <b-form-input
                    id="input-name"
                    class="w-input"
                    v-model="keyword_forName"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.nameValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{nameFeedback}}
                    </b-form-invalid-feedback>                  
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-vintage">vintage</label>
                  <b-form-input
                    id="input-vintage"
                    class="w-input"
                    v-model="keyword_forVintage"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{vintageFeedback}}
                    </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-form-group>            
            <b-form-group>
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-name">region</label>
                  <b-form-input
                    id="input-name"
                    class="w-input"
                    v-model="keyword_forRegion"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.regionValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{grapesFeedback}}
                    </b-form-invalid-feedback>                  
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-appellation">appellation</label>
                  <b-form-input
                    id="input-appellation"
                    class="w-input"
                    v-model="keyword_forAppellation"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.appellationValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{appellationFeedback}}
                    </b-form-invalid-feedback>                  
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group> 
              <b-row class="d-flex">
                <b-col class="text-left">
                  <label class="w-label" for="input-grapes">grapes</label>
                  <b-form-input
                    id="input-grapes"
                    class="w-input"
                    v-model="keyword_forGrapes"
                    v-bind:class="{'form-control':true, 'is-invalid' : !this.grapesValid}"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{grapesFeedback}}
                    </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-form-group>
            <b-row> 
              <b-col class="text-right">
                <b-button v-on:click="onSearch()" class="w-button-green">search</b-button>
              </b-col>
          </b-row>
          </b-form>
        </div>
        <div class="e-box" style="margin:0 10px 0 10px;padding:0 10px 0 10px!important">
          <b-row>
            <b-col class="text-left">
              <b-img v-if="leftVisible"
                width=30px
                height=30px
                :src="require('@/assets/img/left.png')"
                @click="onLeft"
              >
              </b-img>
            </b-col>
            <b-col xs="6">
              <p class="w-smalltext" style="text-align:center">this query has {{this.entryCount}} hits, page {{this.pageCount}}/{{this.totalPages}}</p>
            </b-col>
            <b-col class="text-right">
              <b-img v-if="rightVisible"
                width=30px
                height=30px
                :src="require('@/assets/img/right.png')"
                @click="onRight"
              >
              </b-img>
            </b-col>
          </b-row>
        </div>
        <div class="e-box" v-if="showResultZone"  style="margin:0 10px 0 10px;padding:0 10px 0 10px!important">
            <b-col
              v-for="(entry, index) in entries"
              v-bind:item="entry"
              v-bind:index="index"
              v-bind:key="entry._id" 
              class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
              xs="12" sm="6" md="4" lg="2" xl="2"
              style ="display:block; margin:20px !important"
            >
              <b-row 
                style="width:100%"
                v-on:click="gotoEntry(entry)"
              >
                <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                  <b-img center v-if="entry.image_files[0]"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="entry.image_files[0].dataURL" 
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </b-col>
                <b-col cols="9" class="d-table-cell">
                  <div>
                    <p class="t-title">{{entry.vintage}}</p>
                    <p class="t-title2">{{entry.name}}</p>
                    <p class="t-subtitle" v-if="entry.type">{{entry.type}}</p>
                    <hr style="margin:5px 0 5px 0">
                    <p class="t-text" v-if="entry.appellation">{{entry.appellation}}</p>
                    <p class="t-text" v-if="entry.classification">{{entry.classification}}</p>
                    <div v-if="entry.grapes">
                      <p class="t-smalltext" v-if="entry.grapes.length < 95">
                        <b>grapes: </b>{{entry.grapes}}
                      </p>
                      <p class="t-smalltext" v-else>
                        <b>grapes: </b>{{entry.grapes.substring(0, 95)}}...
                      </p>
                    </div>
                    <p class="c-bottles">{{entry.number}}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import Entries_Search from '../Entries_Search.js'

  export default {
    name: "CellarSearch",
    components: {
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },
      searchState() {
        return this.$store.state.search_state
      },
      totalPages() {
        return Math.ceil(this.entryCount/this.entries_perPage)
      }
    },
    data() {
      return {
        debug: false,
        
        isLoading: true,

        entries: [],

        keyword_forName: '',
        search_forName:true,
        keyword_forVintage: '',
        search_forVintage:true,
        keyword_forRegion: '',
        search_forRegion:false,
        keyword_forAppellation: '',
        search_forAppellation:false,
        keyword_forGrapes: '',
        search_forGrapes:false,

        showSearchZone: true,
        showResultZone: false,

        // Validation Logic
        nameValid : true,
        nameFeedback : '',

        vintageValid: true,
        vintageFeedback: '',

        regionValid: true,
        regionFeedback: '',

        appellationValid: true,
        appellationFeedback: '',

        grapesValid: true,
        grapesFeedback: '',

        pageCount:0,
        entryCount:0,
        entries_perPage:6,
        leftVisible:false,
        rightVisible:false,

        isValid : true,
      }
    },
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("CellarSearch.mounted: START")

      if (this.currentCellarLoaded) {
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (this.debug) console.log("CellarSearch.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      } else {
        console.log("Search.mounted: FATAL ERROR cellar NOT loaded.")
      }

      if (Object.keys(this.searchState).length != 0) {        
        this.keyword_forName = this.searchState.keyword_forName;
        this.search_forName = this.searchState.search_forName;
        
        this.keyword_forVintage = this.searchState.keyword_forVintage;
        this.search_forVintage = this.searchState.search_forVintage;        
        
        this.keyword_forRegion = this.searchState.keyword_forRegion;
        this.search_forRegion = this.searchState.search_forRegion;
        
        this.keyword_forAppellation = this.searchState.keyword_forAppellation;
        this.search_forAppellation = this.searchState.search_forAppellation;
        
        this.keyword_forGrapes = this.searchState.keyword_forGrapes;
        this.search_forGrapes = this.searchState.search_forGrapes;
       
        await this.onSearch();
      }

      this.isLoading = false;
      if (this.debug) console.log("CellarSearch.mounted: STOP")
    },
    methods:{
      async onSearch() {
        if (this.debug) console.log("")
        if (this.debug) console.log("CellarSearch.onSearch: START")

        this.entries = [];
        this.pageCount = 0;
        this.entryCount = 0;
        this.leftVisible = false;
        this.rightVisible = false;

        let postdata = {};

        this.validate()
        if (this.isValid) {
          if ( this.keyword_forVintage != '' ) {
            postdata = {
              "cellar_id": this.cellar_id,
              "name": this.keyword_forName,
              "vintage": parseInt(this.keyword_forVintage),
              "region": this.keyword_forRegion,
              "appellation": this.keyword_forAppellation,
              "grapes": this.keyword_forGrapes,
              "skip": 0,
              "limit": this.entries_perPage
            };
          } else {
            postdata = {
              "cellar_id": this.cellar_id,
              "name": this.keyword_forName,
              "vintage": 0,
              "region": this.keyword_forRegion,
              "appellation": this.keyword_forAppellation,
              "grapes": this.keyword_forGrapes,
              "skip": 0,
              "limit": this.entries_perPage
            };
          }
          
          if (this.debug) console.log("CellarSearch.onSearch: postdata = ", postdata)
          await Entries_Search.count(postdata)
          .then(response=>{
            if (this.debug) console.log("CellarSearch.onSearch: succesful entries search count = ", response)
            this.entryCount = response
          })
          .catch(error=>{
            if (this.debug) console.log("CellarSearch.onSearch: catch Entries_Search.count error = ", error)
            return
          })

          await Entries_Search.list(postdata)
          .then(response=>{
            if (this.debug) console.log("CellarSearch.onSearch: succesful entries search response = ", response)
              for (let i = 0; i < response.length ; i++) {
                this.entries[i] = response[i]
              }
          })
          .catch(error=>{
            if (this.debug) console.log("CellarSearch.onSearch: catch Entries_Search.list error = ", error)
            return
          })

          if (this.debug) console.log("CellarSearch.onSearch: entries = ", this.entries)
          if (this.entries.length > 0) {
            this.pageCount = 1;
            this.showResultZone = true;
          }
          if (this.entryCount > this.pageCount*this.entries_perPage) {
            this.rightVisible = true;
          } else {
            this.rightVisible = false;
          }
        }

        this.$forceUpdate();
        if (this.debug) console.log("CellarSearch.onSearch: STOP")
      },

      validate() {
        let today = new Date();
        let this_year = today.getFullYear();
        
        if( this.keyword_forVintage.length == 4 && parseInt(this.keyword_forVintage) > 1900 && parseInt(this.keyword_forVintage) < this_year+1) {
          this.vintageValid = true;
        } else if (this.keyword_forVintage.length == 0 ) {
          this.vintageValid = true;
        }
        else {
            this.vintageValid = false;
            this.vintageFeedback = 'vintage needs exact 4 digits and must be between 1900 and ' + String(this_year)
        }
        this.isValid = this.vintageValid;
      },

      async onLeft() {
        if (this.debug) console.log("")
        if (this.debug) console.log("CellarSearch.onLeft: START")
        this.pageCount--;

        this.entries = [];
        let postdata = {};
        if ( this.keyword_forVintage != '' ) {
          postdata = {
            "cellar_id": this.cellar_id,
            "name": this.keyword_forName,
            "vintage": parseInt(this.keyword_forVintage),
            "region": this.keyword_forRegion,
            "appellation": this.keyword_forAppellation,
            "grapes": this.keyword_forGrapes,
            "skip":(this.pageCount-1)*this.entries_perPage,
            "limit":this.entries_perPage
          };
        } else {
          postdata = {
            "cellar_id": this.cellar_id,
            "name": this.keyword_forName,
            "vintage": 0,
            "region": this.keyword_forRegion,
            "appellation": this.keyword_forAppellation,
            "grapes": this.keyword_forGrapes,
            "skip":(this.pageCount-1)*this.entries_perPage,
            "limit":this.entries_perPage
          };
        }
        if (this.debug) console.log("CellarSearch.onLeft: postdata = ", postdata)

        await Entries_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("CellarSearch.onLeft: succesful entries search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.entries[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("CellarSearch.onLeft: catch Entries_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("CellarSearch.onLeft: entries = ", this.entries)
        if (this.entries.length > 0) this.showResultZone = true;
        if (this.pageCount > 1) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.entryCount > this.pageCount*this.entries_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
        if (this.debug) console.log("CellarSearch.onLeft: STOP")
      },

      async onRight() {
        if (this.debug) console.log("")
        if (this.debug) console.log("CellarSearch.onRight: START")
        this.pageCount++;

        this.entries = [];
        let postdata = {};
        if ( this.keyword_forVintage != '' ) {
          postdata = {
            "cellar_id": this.cellar_id,
            "name": this.keyword_forName,
            "vintage": parseInt(this.keyword_forVintage),
            "region": this.keyword_forRegion,
            "appellation": this.keyword_forAppellation,
            "grapes": this.keyword_forGrapes,
            "skip":(this.pageCount-1)*this.entries_perPage,
            "limit":this.entries_perPage
          };
        } else {
          postdata = {
            "cellar_id": this.cellar_id,
            "name": this.keyword_forName,
            "vintage": 0,
            "region": this.keyword_forRegion,
            "appellation": this.keyword_forAppellation,
            "grapes": this.keyword_forGrapes,
            "skip":(this.pageCount-1)*this.entries_perPage,
            "limit":this.entries_perPage
          };
        }
        if (this.debug) console.log("CellarSearch.onRight: postdata = ", postdata)

        await Entries_Search.list(postdata)
        .then(response=>{
          if (this.debug) console.log("CellarSearch.onRight: succesful entries search response = ", response)
            for (let i = 0; i < response.length ; i++) {
              this.entries[i] = response[i]
            }
        })
        .catch(error=>{
          if (this.debug) console.log("CellarSearch.onRight: catch Entries_Search.list error = ", error)
          return
        })

        if (this.debug) console.log("CellarSearch.onRight: entries = ", this.entries)
        if (this.entries.length > 0) this.showResultZone = true;
        if (this.pageCount > 1) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.entryCount > this.pageCount*this.entries_perPage) {
          this.rightVisible = true;
        } else {
          this.rightVisible = false;
        }
        this.$forceUpdate();
        if (this.debug) console.log("CellarSearch.onRight: STOP")
      },
      
      gotoEntry(entry) {
        if (this.debug) console.log("CellarSearch.gotoEntry: START")
        let search_state = {
          search_forName:this.search_forName,
          keyword_forName:this.keyword_forName,
          keyword_forVintage: this.keyword_forVintage,
          search_forVintage: this.search_forVintage, 
          search_forRegion:this.search_forRegion,
          keyword_forRegion:this.keyword_forRegion,
          search_forAppellation:this.search_forAppellation,
          keyword_forAppellation:this.keyword_forAppellation,
          search_forGrapes:this.search_forGrapes,
          keyword_forGrapes:this.keyword_forGrapes
        }
        this.$store.commit('setPage','EntryShow')
        this.$store.commit('setSearchState',search_state)
        
        if (this.debug) console.log("CellarSearch.gotoEntry: cellar_id = ", this.$route.query.cellar_id)
        if (this.debug) console.log("CellarSearch.gotoEntry: niche_id = ", entry.niche_id)
        if (this.debug) console.log("CellarSearch.gotoEntry: entry_id = ", entry._id)
        this.$router.push({path:'/EntryShow', query: {cellar_id:this.$route.query.cellar_id,niche_id:entry.niche_id,entry_id:entry._id}})
        if (this.debug) console.log("CellarSearch.gotoEntry: STOP")
      }
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>