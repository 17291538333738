<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WishListMobile  v-if="matches" />
    <WishListDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WishListMobile from "./WishList_Mobile.vue";
  import WishListDesktop from "./WishList_Desktop.vue";
 
  export default {
    name: 'WishList',
    components: {
      MatchMedia,
      WishListMobile,
      WishListDesktop,
    },
  }
</script> 