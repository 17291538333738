<template>
  <div>
    <b-row style="margin:0">
      <b-col class="text-center" style="height:20vh;padding:0!important">
        <div class="welcome-picture">
          <div class="welcome-overlay">
            <h1 class="local-title"><span style="color: rgb(195,147,130)">WYNE</span>CELLAR</h1>
            <p class="local-subtitle">a simple tool to manage your winecellar</p>
          </div>
        </div>
      </b-col>
    </b-row> 
    <b-container fluid>
      <b-form autocomplete="off">
        <b-row>
          <b-col sm="3">
          </b-col>
          <b-col sm="6" style="margin-left:10px" class="d-table">
            <div>
              <h1 class="w-title">Welcome back</h1>
              <br>
              <h1 class="w-subtitle">{{user.firstname}} {{user.lastname}} ({{user.email}})</h1>
              <p class="w-text" style="margin-bottom">We know you from WyneMaker.</p>
              <p class="w-text" style="margin-bottom">We understand you also want your account to be able to manage a wine cellar?</p>
              <p class="w-text" style="margin-bottom">Complete the form below and let's go!</p>
              <hr>
              <div>
                <h1 class="w-subtitle">wyne information</h1>
                <b-form-group>
                  <label class="w-label w-label-inactiv" for="input-wineryname">winery name</label>
                  <b-form-input
                  id="input-wineryname"
                  class = "w-input"
                  v-model="winery.name"
                  disabled  
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-row>
                    <b-col sm="4" style="padding-right:0">  
                      <label class="w-label w-required" for="input-cellarname">cellar name</label>
                    </b-col>
                    <b-col sm="8" class="text-right" style="padding-left:0">
                      <label class="w-error" v-if="!this.cellarnameValid">{{cellarnameFeedback}}</label>
                    </b-col>
                  </b-row>
                  <b-form-input
                  id="input-cellarname"
                  class = "w-input"
                  v-model="cellar.name"
                  v-bind:class="{'is-invalid' : !this.cellarnameValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
            </div>
            <hr>
            <b-row>
              <b-col class="text-right">
                <b-button class="w-button-green" v-on:click="onUpdate()">update</b-button>
              </b-col>
            </b-row>
            <br>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import Users from '../Users.js';
  import Mail from '../Mail.js';
  import Cellars from '../Cellars.js';
  import Wineries from '../Wineries.js';

  export default {
    name: 'AddCellar2Registration',
    data() {
      return {
        debug: false,

        //Working Objects
        user: {},
        user_id:0,
        
        cellar: {
            name : '',
            user_id : '',
            niche_ids:[],
            entry_ids: [],
            image_files: [],
            number_of_bottles : 0,
        },
        cellar_id:0,

        winery: {},
        winery_id:0,

        // Validation Logic
        cellarnameValid : true,
        cellarnameFeedback : '',

        formValid : false,
      }
    },
    async mounted() {
      this.user_id = this.$route.query.user_id;      
      if (this.user_id == null) return;

      await Users.getone(this.user_id)
      .then(response=>{
        if (this.debug) console.log("AddCellar2Registration.mounted : user succesfully retrieved form DB - ", response)
        this.user = response;
        delete this.user._id;
      })
      .catch(error=>{
        if (this.debug) console.log("AddCellar2Registration.mounted : catch Users.getone - ", error)
      })

      this.winery_id  = this.user.winery_id
      if ( this.winery_id != null) {
        this.isMaker = true;
        await Wineries.getone(this.winery_id)
        .then(response=>{
          if (this.debug) console.log("AddCellar2Registration.mounted : winery succesfully retrieved form DB - ", response)
          this.winery = response;
          delete this.winery._id;
        })
        .catch(error=> {
          if (this.debug) console.log("AddCellar2Registration.mounted : catch Winery.getone - ", error)
        })
      }
    },
    methods: {
        async validate(){
          //chack validity of name fields
          if (this.cellar.name.length < 3) {
            this.cellarnameValid = false
            this.cellarnameFeedback = 'cellar name should be at least 3 characters long'
          } else {
             this.cellarnameValid = true
          }
          this.formValid = this.cellarnameValid
          if (this.debug) console.log("AddCellar2Registration in validate(): form.valid=",this.formValid);
        },

        async onUpdate() {
          if (this.debug) console.log("AddCellar2Registration.onUpdate: Register pressed");

          await this.validate()
          if (this.debug) console.log("AddCellar2Registration.onUpdate: form.valid=",this.formValid);
          if (this.formValid) {
            // HERE COMES THE DB STUFF
            this.cellar.user_id = this.user_id
            await Cellars.create(this.cellar)
            .then(response=>{
              if (this.debug) console.log("AddCellar2Registration.onUpdate: Cellar succesfully created - ",response);
              this.user.cellar_id = response
            })
            .catch(error=>{
              if (this.debug) console.log("AddCellar2Registration.onUpdate: catch Cellar.create - ",error);
            })

            await Users.update(this.user_id,this.user)
            .then(response=>{
              if (this.debug) console.log("AddCellar2Registration.onUpdate: User succesfully updated - ",response);
            })
            .catch(error=>{
              if (this.debug) console.log("AddCellar2Registration.onUpdate: catch User.update - ",error);
            })

            let this_html = ""
            this_html += "<p>Hi " + this.user.firstname + "</p>"
            this_html += "<p>Next to your existing subscription to WyneMaker, you are now subscribed to WyneCellar.</p>"
            this_html += "<p>Enjoy !</p>"
            this_html += "<p>The Wyne Team</p>"
    
            let postdata = {};
            postdata.email = this.user.email;
            postdata.subject = "Welcome to WyneCellar";
            postdata.html = this_html;
            if (this.debug) console.log("AddCellar2Registration.onUpdate - Mailing postdata = ", JSON.stringify(postdata))

            await Mail.sendCellar(postdata)
            .then(response=>{
              if (this.debug) console.log("AddCellar2Registration.onUpdate - successfully transfered postdata to Mail - from Cellar ", response)
            })
            .catch(error=>{
              if (this.debug) console.log("AddCellar2Registration.onUpdate - catch Mail.sendCellar - ", error)
              return;
            })

            try {
              console.log("this.user = ", this.user, " this.user_id = ", this.user_id)
              await this.$store.commit('login')
              await this.$store.commit('setUser',this.user)
              await this.$store.commit('setUserID',this.user_id)
              if ( this.user.image_files.length > 0) {
                this.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
                await this.$store.commit('setProfilePicture',this.user.image_files[0].dataURL) 
              } else {
                await this.$store.commit('setProfilePicture','')
              }
              await this.$store.commit('setPage','Dashboard')
              await this.$router.push({path:'/Dashboard', query: {cellar_id:this.user.cellar_id }} );
            } catch(err) {
              alert("ERROR : ", err.message)
            }
          } 
        },
    }
  }
</script>

<style scoped>
.welcome-picture {
    height:100%;
    width:auto;

    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 6vh;
}
.local-title {
  font-size: clamp(24px, 10vw,48px)!important;
  color:white !important;
  text-align:center;
  margin:0;
}
.local-subtitle {
  font-size:clamp(12px,4vw,24px)!important;
  color: rgb(188,188,188);
  text-align:center;
  margin-top:-5px;
}
</style> 

<style>
</style>