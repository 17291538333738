<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <DashboardMobile  v-if="matches" />
    <DashboardDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import DashboardMobile from "./Dashboard_Mobile.vue";
  import DashboardDesktop from "./Dashboard_Desktop.vue";
 
  export default {
    name: 'Dashboard',
    components: {
      MatchMedia,
      DashboardMobile,
      DashboardDesktop,
    },
  }
</script> 