// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';
import { API } from './API.js';

import axios from 'axios';

// const url = WEBSERVER + ':3000/api/entries/';
// const url = WEBSERVER + ':'+ PORT + '/api/entries/';

const url = API + '/entries/';
const debug = false;

class Entries {
    // get entries
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(entry=> ({
                        ...entry,
                        name: entry.name
                    }))
                );
                if (debug) console.log("data = " + data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one entry
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Entries.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Entries.getone: ',error)
            })
    }

    // create entry
    static create(postdata) {
        if (debug) console.log('Entries.create - url: ',url)
        if (debug) console.log('Entries.create - postdata: ',postdata)
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Entries.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Entries.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('Entries.update: id=',id)
        if (debug) console.log('Entries.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('Entries.update: ',response)
                return id
            })
            .catch(error => {
                console.log('Entries.update: ',error)
            })
    }

    // delete entry
    static delete(id) {
        return axios.delete(`${url}${id}`)
    } 
}

export default Entries