// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';
import { API } from './API.js';

import axios from 'axios';

// const url = WEBSERVER + ':' + PORT + '/api/regions/'
// const url2 = WEBSERVER + ':' + PORT + '/api/geographies/'

const url = API + '/regions/'
const url2 = API + '/geographies/'
const debug = false;

class Regions {
    // get all regions
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(region=> ({
                        ...region,
                        name: region.name
                    }))
                );
                if (debug) console.log("Regions.get: data = ", data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one region
    static getone(id) {
        return axios.get(`${url2}${id}`)
            .then(response => {
                if (debug) console.log('Regions.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Regions.getone: ',error)
            })
    }

    // get from RegionID
    static get_fromCountryID(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Regions.get_fromCountryID: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Regions.get_fromCountryID: ',error)
            })
    }

    // create regions
    static create(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Regions.create: ',response)
                return response.data._id
            })
            .catch(error => {
                if (debug) console.log('Regions.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('Regions.update: id=',id)
        if (debug) console.log('Regions.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('Regions.update: ',response)
            })
            .catch(error => {
                if (debug) console.log('Regions.update: ',error)
            })
    }

    // delete country
    static delete(id) {
        return axios.delete(`${url}${id}`)
    }
}

export default Regions