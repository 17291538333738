 <template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <br>
            <div id="container_ofBottles">
              <h1 class="d-big text-center"><span style="color: rgb(109,122,82)">{{number_ofBottles}}</span></h1>
              <p class="d-label text-center">number of bottles in cellar</p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div id="container_perColor">
              <zingchart 
                ref="cellar_perColor"
                :data="cellar_perColor"
              ></zingchart>
              <div id="hide">
                <p style="color:white">Nothing</p>
                <p style="color:white">Nothing</p>
              </div>
            </div>
          </b-col>   
        </b-row>
        <!-- <b-row>
          <b-col>
            <div id="container_more" class="m-box">
              <b-button
                  class="w-button-green-full"  
                  @click="showModal_more" 
                  v-b-tooltip.hover title="more about your cellar"
                >
                more ...
              </b-button>
            </div>
          </b-col>
        </b-row>   -->
        <hr class="correct">
        <b-row> 
          <b-col lg="6">
            <div id="container_perCountry" class="m-box">
              <zingchart v-if="level==0"
                ref="cellar_perCountry"
                :data="cellar_perCountry" 
                @node_click="handle_countryClick"
              ></zingchart>
              <zingchart v-if="level==1"
                ref="cellar_perRegion"
                :data="cellar_perRegion" 
                @node_click="handle_regionClick"
              ></zingchart>
              <zingchart v-if="level==2"
                ref="cellar_perAppellation" 
                :data="cellar_perAppellation" 
              ></zingchart>
              <b-button v-if="level > 0" style="position:absolute; top:40px; right:-15px" v-on:click="onLevelBack()" class="c-button-pink">back</b-button>
            </div>
          </b-col>
        </b-row>
        <hr class="correct">
        <b-row>
          <b-col lg="6" >
            <div id="container_perVintage" class="m-box">
              <zingchart 
                ref="cellar_perVintage" 
                :data="cellar_perVintage"
              ></zingchart>
            </div>
          </b-col>
        </b-row>
        <hr class="correct">
        <b-row>
          <b-col lg="6">
            <div id="container_recentTransactions" class="m-box">
              <p class="dashboard-title">recent transactions</p>
              <b-table 
                ref="transactionsTable"
                class="w-table"
                style="overflow:scroll !important"
                sticky-header=true
                hover
                :items="transactions" 
                :fields="transaction_fields" 
              >
              </b-table>
            </div>
          </b-col>
        </b-row>        
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <MenuMobile/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <b-modal id="showMore" title="more about your cellar" @ok="closeProperly" @cancel="closeProperly">
      <b-container class="text-center justify-content-center">
        <div v-if="this.cellarLoaded == false">
          <h5>loading cellar</h5>
          <br>
          <b-spinner style="color: rgb(109,122,82) !important"
              variant="warning"
          ></b-spinner>         
        </div>
        <div v-else>
          <div id="download spreadsheet">
            <p class ="w-cursor-click" @click="downloadInventory"><u><b>download spreadsheet</b></u></p>  
          </div>
          <hr>
          <div id="calculate value of cellar">
            <p class ="w-cursor-click" @click="showModal_calcValue"><u><b>calculate value</b></u></p>
          </div> 
          <hr>
          <div id="check consistency">
            <p class ="w-cursor-click" @click="checkConsistency"><u><b>check consistency</b></u></p>
          </div>
          <hr>
          <div id="sync entries to wine library">
            <p class ="w-cursor-click" @click="syncEntries"><u><b>sync entries to wine library</b></u></p>
          </div>
        </div>                   
      </b-container>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="resetMore()">
              reset
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="showValue" title="cellar value" @ok="closeProperly" @cancel="closeProperly">
      <b-container v-if="pricesChecked == false" class="text-center justify-content-center">
        <b-row>
          <b-col>
            <p class="w-text">Calculating the inventory value of a cellar is only possible if you have been consistent with your input of purchase transactions.</p>
            <p class="w-text">Here are examples of proper inputs:</p>
            <p class="w-text" style="padding-left:10px">12.34 EUR</p>
            <p class="w-text" style="padding-left:10px">16.56 USD, incl.</p>
            <p class="w-text" style="padding-left:10px">13.32 GBP, excl.</p>
            <p class="w-text">All purchase must be in the same currency and should be consistent in incl. or excl. VAT.</p>
            <p class="w-text">When VAT status is not mentioned it is considered to be incl.</p>
            <p class="w-text">Let us check ...</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <br><br><br>
            <h5>checking cellar transaction consistency</h5>
            <br>
            <b-spinner style="color: rgb(109,122,82) !important"
              variant="warning"
            ></b-spinner>
          </b-col>
        </b-row>
      </b-container>

      <b-container v-else class="text-center justify-content-center">
        <b-row>
          <b-col>
            <p class="w-subtitle">We have checked your cellar on consistency in purchase transaction price input.</p>
            <hr>
            <p v-if="this.currencies.length == 1" class="w-text">You have used {{ this.currencies.length }} currencies uniformly, which is perfect.</p>
            <p v-else class="w-text">You have used {{ this.currencies.length }} different currencies:</p>
            <div
                v-for="(currency) in currencies"
            >
              <div v-if="currency.currency == null" style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>blank</b> in {{ currency.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithCurrency(currency.currency)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
                <p class="w-text">The entries with blank currency will be considered to use your main currency.</p>
              </div>
              <div v-else style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>{{ currency.currency }}</b> in {{ currency.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithCurrency(currency.currency)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
              </div>
              <p class="w-text"><b>{{currency_comment}}</b></p>  
          </div>
          <hr>
          <p v-if="this.vatStati.length == 1" class="w-text">You have used {{ this.vatStati.length }} VAT status uniformly, which is perfect.</p>  
          <p v-else class="w-text">You have used {{vatStati.length }} different VAT status:</p>
            <div
                v-for="(vatStatus) in vatStati"
            >
              <div v-if="vatStatus.vatStatus == null" style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>blank</b> in {{ vatStatus.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithVATStatus(vatStatus.vatStatus)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
                <p class="w-text">The entries with blank VAT status will be considered to use your main VAT status.</p>
              </div>
              <div v-else style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>{{ vatStatus.vatStatus }}</b> in {{ vatStatus.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithVATStatus(vatStatus.vatStatus)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
              </div>
              <p class="w-text"><b>{{vatStatus_comment}}</b></p>  
            </div>
            <hr>
            <p class="w-text">You have {{ this.zeroPrice }} zero-priced or blank-priced purchase transactions.</p>
            <hr>
            <div v-if="currency_OK && vatStatus_OK">
              <p class="w-subtitle">The value of your cellar is: <b style="font-size: 20px">{{formattedCellarValue}} {{ main_currency }}<span v-if="main_vatStatus != '' ">, {{ main_vatStatus }}</span></b></p>  
              <p v-if="this.inconsistent_entries.length > 0" class="w-text">However, you have {{this.inconsistent_entries.length}} entries with conflicting purchase entries that might cause errors in your results.</p>
            </div>
            <div v-else>
              <p class="w-subtitle">Please correct the issues described above and try again.</p>
            </div>  
          </b-col>
        </b-row>
      </b-container>   

      <b-form aria-autocomplete="off">
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    
    <b-modal id="entriesWithCurrency" :title="EWC_title">
      <b-container  class="text-center justify-content-center">
        <div
          v-for="(entry) in this.entries_with_currency"
        >
          <p class="w-text">&bull; {{ entry.name }} {{ entry.type }} {{entry.vintage}}: {{ entry.price }} {{ entry.currency }}, {{ entry.vatStatus }}</p>
      </div>
      </b-container>
      <b-form aria-autocomplete="off">
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="entriesWithVATStatus" :title="EWV_title">
      <b-container  class="text-center justify-content-center">
        <div
          v-for="(entry) in this.entries_with_vatStatus"
        >
          <p class="w-text">&bull; {{ entry.name }} {{ entry.type }} {{entry.vintage}}: {{ entry.price }} {{ entry.currency }}, {{ entry.vatStatus }}</p>
      </div>
      </b-container>
      <b-form aria-autocomplete="off">
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>    
  </div>
</template>

<script>
  import 'zingchart/es6';
  import zingchartVue from 'zingchart-vue';
  import Queries from '../Queries.js'

  import NoAccess from './NoAccess.vue';
  import MenuMobile from './MenuMobile.vue';

  import Cellars from '../Cellars.js';
  import Niches from '../Niches.js';
  import Entries from '../Entries.js';
  import Transactions from '../Transactions.js';

  import formatDate from '../assets/js/formatDate.js';
  import wait from '../assets/js/wait.js';

  export default {
    name: 'Dashboard',
    components:{
      NoAccess,
      MenuMobile,
      zingchart: zingchartVue
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      
      currentUserID() {
        return this.$store.state.user_id
      },
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },

      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },

      cellar_perCountry(){
        return {
          id: 'cellar_perCountry',
          type: 'bar',
          height: '90%',
          width: '100%',
          title:{
            text: this.title,
            'font-family': 'Segoe UI',
            'font-size': '24'
          },
          scaleX: {
            item: {
              fontAngle: 90,
              wrapText: false,
              maxChars: 15,
            },
            itemsOverlap: true,
            maxItems: 99,
            label: {
              text: 'countries'
            },
            labels: this.country_labels,
          },
          series: [{
            "background-color": "#6D7A52", 
            alpha: 0.8, 
            values: this.country_values
          }],
          gui : {
            contextMenu : {
              empty : true
            }
          }          
        }
      },
      cellar_perRegion(){
        return {
          id: 'cellar_perRegion',
          type: 'bar',
          height: '90%',
          width: '100%',          
          title:{
            text: this.title,
            'font-family': 'Segoe UI',
            'font-size': '24'
          },
          scaleX: {
            item: {
              fontAngle: 90,
              wrapText: false,
              maxChars: 15,
            },
            itemsOverlap: true,
            maxItems: 99,
            label: {
              text: 'regions'
            },
            labels: this.region_labels
          },
          series: [{
            "background-color": "#6D7A52", 
            alpha: 0.8, 
            values: this.region_values
          }],
          gui : {
            contextMenu : {
              empty : true
            }
          }          
        }
      },
      cellar_perAppellation(){
        return {
          id: 'cellar_perAppellation',
          type: 'bar',
          height: '90%',
          width: '100%',
          title:{
            text: this.title,
            'font-family': 'Segoe UI',
            'font-size': '24'
          },
          scaleX: {
            item: {
              fontAngle: 90,
              wrapText: false,
              maxChars: 15,
            },
            itemsOverlap: true,
            maxItems: 99,
            label: {
              text: 'appellations'
            },
            labels: this.appellation_labels
          },
          series: [{
            "background-color": "#6D7A52", 
            alpha: 0.8, 
            values: this.appellation_values
          }],
          gui : {
            contextMenu : {
              empty : true
            }
          }          
        }
      },
      cellar_perVintage(){
        return {
          id: 'cellar_perVintage',
          type: 'bar',
          title:{
            text: "vintages",
            'font-family': 'Segoe UI',
            'font-size': '24'
          },
          scaleX: {
            item: {
              fontAngle: 90,
            },
            itemsOverlap: true,
            maxItems: 99,
            label: {
              text: 'vintage',
            },
            labels: this.vintage_labels,
          },
          scaleY: {
            itemsOverlap: true,
          },
          series: [{
            "background-color": "#6D7A52", 
            alpha: 0.8, 
            values: this.vintage_values
          }],
          gui : {
            contextMenu : {
              empty : true
            }
          }          
        }
      },
      long_x_label(){
        return {
          id: 'long_x_label',
          type: 'bar',
          height: '90%',
          width: '100%',
          title:{
            text: "a long x label",
            'font-family': 'Segoe UI',
            'font-size': '24'
          },
          scaleX: {
            item: {
              fontAngle: 90,
              wrapText: false,
              maxChars: 15,
            },
            itemsOverlap: true,
            maxItems: 99,
            label: {
              text: 'letters',
            },
            labels: ["some a","some b","some c","some long x label here","some e","some f","some g","some h","some i","some j"],
          },
          scaleY: {
            itemsOverlap: true,
          },
          series: [{
            "background-color": "#6D7A52", 
            alpha: 0.8, 
            values: [0,1,2,3,4,5,6,7,8,9]
          }],
          gui : {
            contextMenu : {
              empty : true
            }
          }          
        }
      },
      cellar_perColor(){
        return {
          id: 'cellar_perColor',
          type: 'pie3d',
          series: this.color_series,
          height: "300px",
          gui : {
            contextMenu : {
              empty : true
            }
          }          
        }
      },
      formattedCellarValue() {
        return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.cellarValue);
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,

        user: {
        },
        user_id: 0,
        
        cellar: {
        },
        cellar_id: 0,

        number_ofBottles: 0,
        
        level: 0,
        title: 'countries',

        currentCountryID: '',
        currentCountry: '',
        country_ids: [],
        country_labels: [],
        country_values: [],

        currentRegionID: '',
        currentRegion: '',
        region_ids: [],
        region_labels: [],
        region_values: [],

        currentAppellationID: '',
        currentAppellation: '',
        appellation_ids: [],
        appellation_labels: [],
        appellation_values: [],

        vintage_labels: [],
        vintage_values: [],

        color_series: [],

        transaction_fields: [
          {key: 'date',sortable: true,
            formatter: (value) => {
              return(formatDate(new Date(value)))}, thClass: 'myClass10', tdClass: 'myClass10'},
          {key: 'number',label: '#', sortable: false, class: 'text-center', thClass: 'myClass10', tdClass: 'myClass10'},
          {key: 'wine',sortable: false, thClass: 'myClass30', tdClass: 'myClass30'},
          {key: 'vintage',sortable: false, class: 'text-center', thClass: 'myClass10', tdClass: 'myClass10'},
          {key: 'description',sortable: false, thClass: 'myClass30', tdClass: 'myClass30'},
          {key: 'price',sortable: false, class:'text-right',thClass: 'myClass10', tdClass: 'myClass10'},
        ],
        transactions:[],

        isChecking:false,

        pricesChecked:false,
        cellarLoaded:false,
        spreadsheetReady:false,

        entries:[],
        currencies:[],
        vatStati:[],
        zeroPrice:0,
        currency_OK:false,
        currency_comment:"",
        vatStatus_OK:false,
        vatStatus_comment:"",
        cellarValue:0,
        main_currency:"",
        main_vatStatus:"",

        entries_with_currency:[],
        EWC_title: "",
        entries_with_vatStatus:[],
        EWV_title:"",

        inconsistent_entries:[],
      }
    },
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("Dashboard.mounted: START")
          
      this.user_id = this.currentUserID
      this.user = this.currentUserID
      
      if (this.currentCellarLoaded) {
        if (this.debug) console.log("Dashboard.mounted: CURRENT CELLAR LOADED")
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (this.debug) console.log("Dashboard.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      }
      else {
        if (this.debug) console.log("Dashboard.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
        if (typeof this.$route.query.cellar_id == "undefined") {
          if (this.debug) console.log("Dashboard.mounted: FATAL ERROR - this.$route.query.cellar_id = ", this.$route.query.cellar_id)
          alert("Dashboard.mounted: FATAL ERROR - this.$route.query.cellar_id = " + this.$route.query.cellar_id)
          return
        }  
        this.cellar_id = this.$route.query.cellar_id
        await Cellars.getone(this.cellar_id)
        .then(response => {
          this.cellar = response
          delete this.cellar._id
          if (this.debug) console.log("Dashboard.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
        })
        .catch(error =>{
          if (this.debug) console.log("Dashboard.mounted: catch Cellar.getone with error: ", error)
        })

        this.$store.commit('setCellar',this.cellar)
        this.$store.commit('setCellarID',this.cellar_id)
        this.$store.commit('setCellarLoaded',true)

        if (this.debug) console.log("Dashboard.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
      }

      await this.showFigures();
      await this.showCountries();
      await this.showVintages();
      await this.showColors();
      await this.showTransactions();
      
      this.isLoading = false
      if (this.debug) console.log("Dashboard.mounted: STOP")
    },

    methods: {    
      async handle_countryClick(e) {
        if (this.debug) console.log("")
        if (this.debug) console.log("Dashboard in handle_countryClick: START")
        if (this.debug) console.log("Dashboard in handle_countryClick: nodeindex = ", e.nodeindex)
        if (this.debug) console.log("Dashboard in handle_countryClick: country = ", this.country_labels[e.nodeindex])
        if (this.debug) console.log("Dashboard in handle_countryClick: country_id = ", this.country_ids[e.nodeindex])

        this.currentCountryID = this.country_ids[e.nodeindex];
        this.currentCountry = this.country_labels[e.nodeindex];
        this.title = this.currentCountry;
        await this.showRegions(this.currentCountry);
        
        if (this.debug) console.log("Dashboard in handle_countryClick: STOP")
      },

      async handle_regionClick(e) {
        if (this.debug) console.log("")
        if (this.debug) console.log("Dashboard in handle_regionClick: START")
        if (this.debug) console.log("Dashboard in handle_regionClick: nodeindex = ", e.nodeindex)
        if (this.debug) console.log("Dashboard in handle_regionClick: region = ", this.region_labels[e.nodeindex])
        if (this.debug) console.log("Dashboard in handle_regionClick: region_id = ", this.region_ids[e.nodeindex])

        this.currentRegionID = this.region_ids[e.nodeindex];
        this.currentRegion = this.region_labels[e.nodeindex];
        this.title = this.currentRegion;
        await this.showAppellations(this.currentRegion);

        if (this.debug) console.log("Dashboard in handle_regionClick: STOP")
      },

      async onLevelBack() {
        if (this.level == 1) {
          await this.showCountries();
          this.title = "countries";
          this.level = 0;
        }
        if (this.level == 2) {
          await this.showRegions(this.currentCountry);
          this.title = this.currentCountry;
          this.level = 1;
        }
      },

      async showFigures() {
        if (this.debug) console.log("-----> showFigures: START");
        let postdata = {'cellar_id': this.cellar_id};
        await Queries.number_ofBottles(postdata)
        .then(response=>{
          if (this.debug) console.log("-----> showFigures: postdata ", postdata, " has response ", response);
          if (response !=0) {
            this.number_ofBottles = response.total;
          } else {
            this.number_ofBottles = 0;
          }
        })
        .catch(error=>{
          if (this.debug) console.log("-----> showFigures: catch await Queries: ", error);
        })
        if (this.debug) console.log("-----> showFigures: STOP");
      },

      async showVintages() {
        if (this.debug) console.log("-----> showVintages: START");
        let postdata = {'cellar_id': this.cellar_id};
        await Queries.cellar_perVintage(postdata)
        .then(response=>{
          if (this.debug) console.log("-----> showVintages: postdata ", postdata, " has response ", response);
          this.vintage_labels = [];
          this.vintage_values = [];
          for (let i = 0; i < response.length; i++) {
            // let label = {"text":response[i]._id};
            // this.vintage_labels.push(JSON.parse(label));
            this.vintage_labels.push(response[i]._id);
            this.vintage_values.push(response[i].total);
            if (this.debug) console.log("-----> showVintages: i=" + i + " vintage: ",response[i]._id, " total: ",response[i].total);
          }
        })
        .catch(error=>{
          if (this.debug) console.log("-----> showVintages: catch await Queries: ", error);
        })
        if (this.debug) console.log("-----> showVintages: STOP");
      },

      async showColors() {
        if (this.debug) console.log("-----> showColors: START");
        let postdata = {'cellar_id': this.cellar_id};
        await Queries.cellar_perColor(postdata)
        .then(response=>{
          if (this.debug) console.log("-----> showColors: postdata " + postdata + " has response ", response);
          let red_part = {};
          let white_part = {};
          let rose_part = {};
          let rest_part = {};
          for (let i = 0; i < response.length; i++) {
            if (this.debug) console.log("-----> showColors: i=" + i + " color: ",response[i]._id, " total: ",response[i].total);
            if (response[i]._id == "Red") {
              red_part.values = [response[i].total]
              red_part.text = red_part.values + " Red (" +  Math.round(100*red_part.values/this.number_ofBottles,0) + "%)"
              red_part.backgroundColor = "#b11226"
              if (this.debug) console.log("------> showColors: red = " + red_part.values);
            } else if (response[i]._id == "White") {
              white_part.values = [response[i].total]
              white_part.text = white_part.values + " White (" +  Math.round(100*white_part.values/this.number_ofBottles,0) + "%)"
              white_part.backgroundColor = "#eccd13"
              if (this.debug) console.log("------> showColors: white = " + white_part.values);
            } else if (response[i]._id == "Rosé") {
              rose_part.values = [response[i].total]
              rose_part.text = rose_part.values + " Rosé (" +  Math.round(100*rose_part.values/this.number_ofBottles,0) + "%)"
              rose_part.backgroundColor = "#d4a1b1"
              if (this.debug) console.log("------> showColors: rose = " + white_part.values);
            } else {
              rest_part.values = [response[i].total]
              rest_part.text = rest_part.values + " Unknown (" +  Math.round(100*rest_part.values/this.number_ofBottles,0) + "%)"
              rest_part.backgroundColor = "grey"
            }
            
          }
          this.color_series = [red_part,white_part,rose_part, rest_part]
        })
        .catch(error=>{
          if (this.debug) console.log("-----> showColors: catch await Queries: ", error);
        })
        if (this.debug) console.log("-----> showColors: STOP");
      },

      async showCountries() {
        if (this.debug) console.log("-----> showCountries: START");
        let postdata = {'cellar_id': this.cellar_id};
        await Queries.cellar_perCountry(postdata)
        .then(response=>{
          if (this.debug) console.log("-----> showCountries: postdata ", postdata ," has response ", response);
          this.country_ids=[];
          this.country_labels = [];
          this.country_values = [];
          for (let i = 0; i < response.length; i++) {
            this.country_ids.push(response[i]._id);
            this.country_labels.push(response[i].country);
            this.country_values.push(response[i].total);
            if (this.debug) console.log("-----> showCountries: i=" + i + " country: ",response[i]._id, " total: ",response[i].total);
          }
        })
        .catch(error=>{
          if (this.debug) console.log("-----> showCountries: catch await Queries: ", error);
        })
        if (this.debug) console.log("-----> showCountries: STOP");
      },

      async showRegions(country) {
        if (this.debug) console.log("-----> showRegions: START");
        let postdata = {'cellar_id': this.cellar_id,'country': country};
        await Queries.cellar_perRegion(postdata)
        .then(response=>{
          if (this.debug) console.log("-----> showRegions: postdata ", postdata, " has response ", response);
          this.region_ids=[];
          this.region_labels = [];
          this.region_values = [];
          for (let i = 0; i < response.length; i++) {
            this.region_ids.push(response[i]._id);
            this.region_labels.push(response[i].region);
            this.region_values.push(response[i].total);
            if (this.debug) console.log("-----> showRegions: i=" + i + " region: ",response[i].region, " total: ",response[i].total);
          }
          this.level = 1;
        })
        .catch(error=>{
          if (this.debug) console.log("-----> showRegions: catch await Queries: ", error);
        })
        if (this.debug) console.log("-----> showRegions: STOP");
      },

      async showAppellations(region) {
        if (this.debug) console.log("-----> showAppellations: START");
        let postdata = {'cellar_id': this.cellar_id,'region': region};
        await Queries.cellar_perAppellation(postdata)
        .then(response=>{
          if (this.debug) console.log("-----> showAppellations: postdata ", postdata, " has response ", response);
          this.appellation_ids=[];
          this.appellation_labels = [];
          this.appellation_values = [];
          for (let i = 0; i < response.length; i++) {
            this.appellation_ids.push(response[i]._id);
            this.appellation_labels.push(response[i].region);
            this.appellation_values.push(response[i].total);
            if (this.debug) console.log("-----> showAppellations: i=" + i + " appellation: ",response[i].region, " total: ",response[i].total);
          }
        })
        .catch(error=>{
          if (this.debug) console.log("-----> showAppellations: catch await Queries: ", error);
        })
        this.level = 2;
        if (this.debug) console.log("-----> showAppellations: STOP");
      },

      async showTransactions() {
        if (this.debug) console.log("-----> showTransactions: START");
        let postdata = {'cellar_id': this.cellar_id};
        await Queries.cellar_Transactions(postdata)
        .then(response =>{
          if (this.debug) console.log("-----> showTransactions: postdata ", postdata, " has response ", response);
          for ( let i = 0 ; i < response.length ; i++ ) {
            this.transactions.push(response[i])
          }
        })
        .catch(error => {
          if (this.debug) console.log("-----> showTransactions: catch await Queries.cellar_Transactions: ", error);
        })

        this.transactions.sort(function(a, b) {
          if(a.date < b.date) { return 1; } 
          if(a.date > b.date) { return -1; }
          return 0;
        });

        if (this.debug) console.log("-----> showTransactions: STOP");
      },

      async showModal_more() {
        this.$root.$emit("bv::show::modal", "showMore");
        if ( !this.cellarLoaded ) {
          await this.loadCellar()
        }
      },

      async showModal_calcValue() {
        this.isChecking = true
        this.pricesChecked = false
        await this.$root.$emit("bv::show::modal", "showValue");  
        await wait(3000)   
        if (!this.pricesChecked) {
          await this.checkPricingConsistency() 
        }
        this.isChecking  = false
        if (this.currency_OK && this.vatStatus_OK) await this.calculateValue()
      },

      async loadCellar() {
        let local_debug = false

        let parsed_price = {}
        
        this.niches = []
        this.entries = []

        this.currencies = []
        this.vatStati = []
        this.zeroPrice = 0

        if (local_debug || this.debug) console.log("-----> loadCellar: START");
        
        if (typeof this.cellar === "object" && Array.isArray(this.cellar.niche_ids)) {
          for (let i = 0; i < this.cellar.niche_ids.length; i++) {
            await Niches.getone(this.cellar.niche_ids[i])  
            .then(response=> {
              if (typeof response == "object") {
                this.niches.push(response);
                if (local_debug || this.debug) console.log("----> loadCellar: Niche ", response, " with id ", response._id, " succesfully fetched from DB");
              } else {
                if (local_debug || this.debug) console.log("----> loadCellar: Niche with id ", this.cellar.niche_ids[i], " NOT FOUND in DB.");
              } 
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("----> loadCellar: await Entries.getone", error);
            })
          }
        }

        if (typeof this.cellar === "object" && Array.isArray(this.cellar.entry_ids)) {
          for (let i = 0; i < this.cellar.entry_ids.length; i++) {
            await Entries.getone(this.cellar.entry_ids[i])  
            .then(response=> {
              if (typeof response == "object") {
                response.purchases = []
                this.entries.push(response);
                if (local_debug || this.debug) console.log("----> loadCellar: Entry ", response, " with id ", response._id, " succesfully fetched from DB");
              } else {
                if (local_debug || this.debug) console.log("----> loadCellar: Entry with id ", this.cellar.entry_ids[i], " NOT FOUND in DB.");
              } 
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("----> loadCellar: await Entries.getone", error);
            })
          }
          
          for (let i=0; i < this.entries.length; i++ ) {
            if (Array.isArray(this.entries[i].transaction_ids)) {
              for (let j=0;  j < this.entries[i].transaction_ids.length; j++) {
                await Transactions.getone(this.entries[i].transaction_ids[j])
                .then(response => {
                  if (typeof response == "object") {
                    if (response.number > 0){
                      this.entries[i].purchases.push({number:response.number,price_string:response.price})
                      if (local_debug || this.debug) console.log("----> loadCellar: Purchase ", response.price, " added to ", this.entries[i].name, " ", this.entries[i].type," ", this.entries[i].vintage);
                    }
                  } else {
                    if (local_debug || this.debug) console.log("----> loadCellar: Transaction with id ", this.entries[i].transaction_ids[j], " NOT FOUND in DB.");
                  }
                })
                .catch(error => {
                  if (local_debug || this.debug) console.log("----> loadCellar: await Transactions.getone", error);
                })
              }
              if ( Array.isArray(this.entries[i].purchases) ) {
                let N  = 0
                let NxP = 0
                for (let j=0; j < this.entries[i].purchases.length; j++) {
                  parsed_price = await this.parsePriceString(this.entries[i].purchases[j].price_string)
                  this.entries[i].purchases[j].price = parsed_price.price
                  this.entries[i].purchases[j].currency = parsed_price.currency
                  this.entries[i].purchases[j].vatStatus = parsed_price.vatStatus

                  if ( j== 0 ) {
                    this.entries[i].currency = parsed_price.currency
                    this.entries[i].vatStatus = parsed_price.vatStatus
                    this.entries[i].consistent = true
                  } else {
                    if ( this.entries[i].currency != parsed_price.currency || this.entries[i].vatStatus != parsed_price.vatStatus ) {
                      this.entries[i].consistent = false
                    }
                  }

                  if (!this.currencies.some(c => c.currency === parsed_price.currency)) {
                    this.currencies.push({currency: parsed_price.currency, number: 1});
                  } else {
                    for (let k = 0; k < this.currencies.length; k++) {
                        if (this.currencies[k].currency === parsed_price.currency) {
                            this.currencies[k].number++;
                            break; 
                        }
                    }
                  }

                  if (!this.vatStati.some(c => c.vatStatus === parsed_price.vatStatus)) {
                    this.vatStati.push({vatStatus: parsed_price.vatStatus, number: 1});
                  } else {
                    for (let k = 0; k < this.vatStati.length; k++) {
                        if (this.vatStati[k].vatStatus === parsed_price.vatStatus) {
                          this.vatStati[k].number++;
                            break; 
                        }
                    }
                  }

                  if (parsed_price.price == 0) this.zeroPrice++

                  if (typeof this.entries[i].purchases[j].number === "number") {
                    N += this.entries[i].purchases[j].number
                    NxP +=  this.entries[i].purchases[j].number * this.entries[i].purchases[j].price
                  }

                  if (local_debug || this.debug) console.log("----> loadCellar: --- ", parsed_price)
                }
                let average_price = NxP/N
                this.entries[i].average_price = average_price
                if ( this.entries[i].consistent == false ) {
                  this.inconsistent_entries.push(this.entries[i])
                }
              }
            } else {
              if (local_debug || this.debug) console.log("----> loadCellar: ERROR entry ", JSON.stringify(this.entries[i]), " HAS PROBLEMS.")
            }
          }

          if (local_debug || this.debug) console.log("----> loadCellar: Used currencies: ", JSON.stringify(this.currencies))
          if (local_debug || this.debug) console.log("----> loadCellar: Used vatStati: ", JSON.stringify(this.vatStati))
          if (local_debug || this.debug) console.log("----> loadCellar: Number of zero of null price transactions: ", this.zeroPrice)

        } else {
          if (local_debug || this.debug) console.log("----> loadCellar: ERROR cellar ", JSON.stringify(this.cellar), " HAS PROBLEMS.")
        }

        // for (let i=0; i < this.entries.length; i++) {
        //   if (this.entries[i].purchases.length > 1) console.log("TMP: ", i , " ", this.entries[i].name, " ", this.entries[i].type, " ", this.entries[i].vintage, " ",this.entries[i].average_price ," ",JSON.stringify(this.entries[i].purchases))
        // }

        this.isChecking = true
        this.pricesChecked = false

        this.cellarLoaded = true
        if (local_debug || this.debug) console.log("-----> loadCellar: STOP");
      },

      async checkPricingConsistency() {
        let local_debug = false

        if (local_debug || this.debug) console.log("-----> checkPricingConsistency: START");

        let count_currencies = 0
        for (let i = 0; i < this.currencies.length; i++) {
          if (this.currencies[i].currency != null) {
            count_currencies++
          }
        }
        
        if (count_currencies > 1) {
          this.currency_OK = false
          this.currency_comment = "Your currencies are NOT consitant enough to evaluate the price of your cellar. Please correct and reduce the number of used currencies to one."
        } else {
          this.currency_OK = true
          this.currency_comment = "Your currencies are consitant enough to evalute the price of your cellar."
        }

        let count_vatStatus = 0
        for (let i = 0; i < this.vatStati.length; i++) {
          if (this.vatStati[i].vatStatus != null) {
            count_vatStatus++
          }
        }
        if (count_vatStatus > 1) {
          this.vatStatus_OK = false
          this.vatStatus_comment = "Your VAT status are NOT consitant enough to evaluate the price of your cellar. Please correct and reduce the number of used VAT status to one."
        } else {
          this.vatStatus_OK = true
          this.vatStatus_comment = "Your VAT status are consitant enough to evalute the price of your cellar."
        }

        this.pricesChecked = true

        if (local_debug || this.debug) console.log("-----> checkPricingConsistency: STOP");
      },

      async calculateValue() {
        let local_debug = false

        if (!this.currency_OK || !this.vatStatus_OK) {
          if (local_debug || this.debug) console.log("-----> calculateValue: Cannot calculate value, since pricing is not consistent in currency or vat status.");
          return
        }

        this.cellarValue = 0
        if (local_debug || this.debug) console.log("-----> calculateValue: START");
        
        for (let i = 0; i < this.entries.length; i++) {
          if (local_debug || this.debug) console.log("-----> calculateValue: ", i, ": ",this.entries[i].number, " ", this.entries[i].name, " ", this.entries[i].type, " ", this.entries[i].vintage, " at ", this.entries[i].purchases[0].price," or " ,this.entries[i].average_price)
          this.cellarValue += this.entries[i].number*this.entries[i].average_price
        }
        this.main_currency = ""
        for ( let i = 0; i < this.currencies.length; i++ ){
          if (typeof this.currencies[i].currency == "string") {
            this.main_currency = this.currencies[i].currency
          }
        }

        this.main_vatStatus = ""
        for (let i = 0; i < this.vatStati.length; i++) {
          if ( typeof this.vatStati[i].vatStatus == "string")
          this.main_vatStatus = this.vatStati[i].vatStatus
        }

        if (local_debug || this.debug) console.log("-----> calculateValue: STOP");
      },

      async showEntriesWithCurrency(currency) {
        this.entries_with_currency = []
        for (let i = 0; i < this.entries.length; i++) {
          if (this.entries[i].currency == currency) this.entries_with_currency.push(this.entries[i])
        }
        this.EWC_title = "entries with currency "+ currency
        this.$root.$emit("bv::show::modal", "entriesWithCurrency");
      },

      async showEntriesWithVATStatus(vatStatus) {
        this.entries_with_vatStatus = []
        for (let i = 0; i < this.entries.length; i++) {
          if (this.entries[i].vatStatus == vatStatus) this.entries_with_vatStatus.push(this.entries[i])
        }
        this.EWV_title = "entries with VAT status "+ vatStatus
        this.$root.$emit("bv::show::modal", "entriesWithVATStatus");
      },
   
      async parsePriceString(input) {
        // Regex to capture the price, currency, and VAT status separately
        const priceRegex = /(\d+(\.\d{1,2})?)/;
        const currencyRegex = /([A-Z]{3})/;
        const vatRegex = /(incl[a-zA-Z.]*|excl[a-zA-Z.]*)[.,]?\s*$/i;

        const priceMatch = input.match(priceRegex);
        const currencyMatch = input.match(currencyRegex);
        const vatMatch = input.match(vatRegex);

        let price = 0;
        let currency = null;
        let vatStatus = null;

        if (priceMatch) {
            price = parseFloat(priceMatch[1]);
            // console.log(`Price match found. Input: ${input}, Parsed price: ${price}`);
        } else {
            // console.log(`No price match found. Defaulting to zero. Input: ${input}`);
        }

        if (currencyMatch) {
            currency = currencyMatch[0].toUpperCase();
            // console.log(`Currency match found. Input: ${input}, Parsed currency: ${currency}`);
        } else {
            // console.log(`No currency match found. Input: ${input}`);
        }

        if (vatMatch) {
            if (vatMatch[1].toLowerCase().startsWith('incl')) {
                vatStatus = 'incl';
            } else if (vatMatch[1].toLowerCase().startsWith('excl')) {
                vatStatus = 'excl';
            }
            // console.log(`VAT status match found. Input: ${input}, VAT status: ${vatStatus}`);
        } else {
            // console.log(`No VAT status match found. Input: ${input}`);
        }

        // console.log(`Input: ${input}, Parsed price: ${price}, currency: ${currency}, VAT status: ${vatStatus}`);

        return {
            price: price,
            currency: currency,
            vatStatus: vatStatus
        };
     },

     closeProperly() {
        this.isChecking = false
        this.pricesChecked = false
     },

     resetMore() {
      this.cellarLoaded = false
      this.spreadsheetReady = false
      if (!this.cellarLoaded) {
        this.loadCellar()
      }
     },

     checkConsistency() {
      alert("in development")
     },

     async syncEntries() {
      let local_debug = false
      if (local_debug || this.debug) console.log("-----> syncEntries: START");

      let c_wine
      let existing_c_wine

      for (let i=0 ; i < this.entries.length ; i++) {9
        c_wine = {}
        if (local_debug || this.debug) console.log("-----> syncEntries: handling entries[",i,"] = ",this.entries[i].name);
        c_wine.name = this.entries[i].name
        c_wine.type = this.entries[i].type

        c_wine.country_id = this.entries[i].country_id
        c_wine.country = this.entries[i].country
        c_wine.region_id = this.entries[i].region_id
        c_wine.region = this.entries[i].region
        c_wine.appellation_id = this.entries[i].appellation_id
        c_wine.appellation = this.entries[i].appellation
        c_wine.classification_id = this.entries[i].classification_id
        c_wine.classification = this.entries[i].classification
        
        c_wine.color = this.entries[i].color
        c_wine.sparkling = this.entries[i].sparkling
        c_wine.sweet = this.entries[i].sweet

        c_wine.grapes = this.entries[i].grapes
        c_wine.vines = this.entries[i].vines
        c_wine.longevity = this.entries[i].longevity
        c_wine.vinification = this.entries[i].vinification
        if (this.entries[i].alcohol) c_wine.alcohol = this.entries[i].alcohol
        if (this.entries[i].aging) c_wine.aging = this.entries[i].aging

        c_wine.image_files = this.entries[i].image_files
        c_wine.user_id =  this.currentUserID
        c_wine.entry_id = this.entries[i]._id

        // if (local_debug || this.debug) {
        //   const jsonString = JSON.stringify(c_wine, (key, value) => {
        //     if (key === "image_files") {
        //       return undefined; // Exclude this property
        //     }
        //     return value;
        //   }, 2);

        //   if (local_debug || this.debug) console.log("-----> syncEntries: c_wine =", jsonString);
        // }

        // if ( c_wine with entry_id does not exist) {}

        existing_c_wine = undefined
        await C_Wine_from_Entry_id.get(c_wine.entry_id)
        .then(response => {
          if ( response ) {
            existing_c_wine = response 
            if (local_debug || this.debug) console.log("-----> syncEntries: c_wine found", response)
          } else {
            if (local_debug || this.debug) console.log("-----> syncEntries: c_wine NOT found, we'll create one.")
          }
        })
        .catch(error => {
          if (local_debug || this.debug) console.log(error)
        })


        if (existing_c_wine == undefined) {
          await C_Wines.create(c_wine)
          .then(response => {
            if (local_debug || this.debug) console.log("----> syncEntries: C_Wine succesfully created : id = ", response);
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("----> syncEntries: catch await C_Wines.create ERROR :", error);
          })
        } else {
          await C_Wines.update(existing_c_wine._id, c_wine)
          .then(response => {
            if (local_debug || this.debug) console.log("----> syncEntries: C_Wine with id = ", existing_c_wine._id,"succesfully updated with response = ", response);
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("----> syncEntries: catch await C_Wines.create ERROR :", error);
          })
        }
      }
      if (local_debug || this.debug) console.log("-----> syncEntries: STOP");
     },

     async downloadInventory() {
      let local_debug = false;
      this.spreadsheetReady = false;

      const Excel = require("exceljs");
      if (local_debug || this.debug) console.log("-----> downloadInventory: START");

      if (!this.pricesChecked) {
        await this.checkPricingConsistency()
      }

      let workbook = new Excel.Workbook();

      let titleSheet = workbook.addWorksheet('Title');
      
      const currentDateTime = new Date().toLocaleString();
      const title = `${this.cellar.name}`;
      titleSheet.mergeCells('D11:M11');
      titleSheet.mergeCells('D12:M12');
      titleSheet.mergeCells('D13:M13');
      titleSheet.mergeCells('D14:M14');
      titleSheet.mergeCells('D15:M15');
      titleSheet.getCell('D11').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
      titleSheet.getCell('D12').value = 'WYNECELLAR';
      titleSheet.getCell('D12').alignment = { horizontal: 'center' };
      titleSheet.getCell('D12').font = { size: 24, bold: true, color: { argb: 'FFFFFFFF' } };
      titleSheet.getCell('D12').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
      titleSheet.getCell('D13').value = title;
      titleSheet.getCell('D13').alignment = { horizontal: 'center' };
      titleSheet.getCell('D13').font = { size: 18, bold: true, color: { argb: 'FFFFFFFF' } };
      titleSheet.getCell('D13').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
      titleSheet.getCell('D14').value = `${currentDateTime}`;
      titleSheet.getCell('D14').alignment = { horizontal: 'center' };
      titleSheet.getCell('D14').font = { size: 16, bold: true, color: { argb: 'FFFFFFFF' } };
      titleSheet.getCell('D14').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
      titleSheet.getCell('D15').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };

      titleSheet.pageSetup = {
        paperSize: 9, // A4 paper
        orientation: 'landscape',
        printArea: 'A1:P15',
        fitToPage: true,
        fitToWidth: 1,
        fitToHeight: 1
      };

      const headerStyle = {
        font: {
          color: { argb: 'FFFFFFFF' }, 
          bold: true,
          size: 14
        },
        fill: {
          type: 'pattern',
          pattern: 'solid', // Background fill is solid
          fgColor: { argb: 'FF6D7A52' } // Background color set to rgb(109,122,82)
        }
      };

      const dataStyle = {
        font: {
          color: { argb: 'FF6D7A52' }, // Font color set to rgb(109,122,82)
          bold: false,
          size: 12
        },
        fill: {
          type: 'pattern',
          pattern: 'none' // No background fill
        },
      };
      
      let units = workbook.addWorksheet("Units")
      let units_header =  [
                            // {header:'_id', key:'_id'},
                            {header:'name', key:'name'},
                            {header:'code',key:'code'}
                          ]

      let units_list = []
      for ( let i = 0 ; i < this.niches.length ; i++ ) {
        units_list.push({
                          // _id:this.niches[i]._id, 
                          name:this.niches[i].name, 
                          code:this.niches[i].code
                        })
      }
      units_list.sort(function(a, b) {
          if(a.code < b.code) { return -1; }
          if(a.code > b.code) { return 1; }
          return 0;
      });

      units.columns = units_header.map(column => ({
        ...column,
        style: headerStyle 
      }));

      units.columns = units_header.map(column => ({
        ...column,
      }));

      units.getRow(1).eachCell((cell) => {
        cell.style = headerStyle;
      });

      units_list.forEach((row) => {
        const newRow = units.addRow(row); // Add the row and get the reference to the new row object
        newRow.eachCell({ includeEmpty: true }, (cell) => {
          cell.style = dataStyle; // Apply the data style to each cell in the row
        });
      });

      units.pageSetup = {
        paperSize: 9, // A4 paper
        orientation: 'landscape',
        printArea: 'A1:J' + (units_list.length + 1),
        fitToPage: true,
        fitToWidth: 1,
        fitToHeight: 0,
        printTitlesRow: '1:1'
      };

      let entries = workbook.addWorksheet("Entries")
      let entries_header =  [
                              // {header:'_id',key:'_id'},
                              // {header:'unit_name',key:'unit_name'},
                              // {header:'unit_code',key:'unit_code'},
                              {header:'name',key:'name'},
                              {header:'type',key:'type'},
                              {header:'vintage',key:'vintage'},
                              {header:'number',key:'number'},
                              {header:'average_price',key:'average_price'},
                              {header:'currency',key:'currency'},
                              {header:'vatStatus',key:'vatStatus'},
                              {header:'color',key:'color'},
                              {header:'country',key:'country'},
                              {header:'region',key:'region'},
                              {header:'appellation',key:'appellation'},
                              {header:'classification',key:'classification'},
                              {header:'sparking',key:'sparkling'},
                              {header:'sweet',key:'sweet'},
                              {header:'grapes',key:'grapes'},
                              {header:'vines',key:'vines'},
                              {header:'yields',key:'yields'},
                              {header:'vinification',key:'vinification'},
                            ]
        let entries_list = []
        for ( let i = 0 ; i < this.entries.length ; i++ ) {
          if (this.entries[i].number >0) entries_list.push({
                            // _id:this.entries[i]._id, 
                            // unit_name:this.entries[i].unit_name, 
                            // unit_code:this.entries[i].unit_code,
                            name:this.entries[i].name,
                            type:this.entries[i].type,
                            vintage:this.entries[i].vintage,
                            number:this.entries[i].number,
                            average_price: parseFloat(this.entries[i].average_price).toFixed(2),
                            currency:this.entries[i].currency,
                            vatStatus:this.entries[i].vatStatus,
                            color:this.entries[i].color,
                            country:this.entries[i].country,
                            region:this.entries[i].region,
                            appellation:this.entries[i].appellation,
                            classification:this.entries[i].classification,
                            sparking:this.entries[i].sparking,
                            sweet:this.entries[i].sweet,
                            grapes:this.entries[i].grapes,
                            vines:this.entries[i].vines,
                            yields:this.entries[i].yields,
                            vinification:this.entries[i].vinification,
                          })
        }

        entries_list.sort((a, b) => {
          if (a.country < b.country) {
            return -1;
          }
          if (a.country > b.country) {
            return 1;
          }
          // If countries are the same, sort by region
          if (a.region < b.region) {
            return -1;
          }
          if (a.region > b.region) {
            return 1;
          }
          // If regions are the same, sort by appellation
          if (a.appellation < b.appellation) {
            return -1;
          }
          if (a.appellation > b.appellation) {
            return 1;
          }
          // If appellations are the same, sort alphabetically by name
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        entries.columns = entries_header.map(column => ({
          ...column,
        }));

        entries.getRow(1).eachCell((cell) => {
          cell.style = headerStyle;
        });

        entries_list.forEach((row) => {
          const newRow = entries.addRow(row); // Add the row and get the reference to the new row object
          newRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.style = dataStyle; // Apply the data style to each cell in the row
          });
        });

        entries.pageSetup = {
          paperSize: 9, // A4 paper
          orientation: 'landscape',
          printArea: 'A1:K' + (entries_list.length + 1),
          fitToPage: true,
          fitToWidth: 1,
          fitToHeight: 0,
          printTitlesRow: '1:1'
        };

        if (local_debug || this.debug) console.log('----> downloadInventory: Excel workbook ready: ', workbook);

        let buff = workbook.xlsx.writeBuffer().then((data) => {
          var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');

          // Format the date as YYYY-MM-DD
          const formattedDate = new Date().toISOString().slice(0, 10);

          // Set the download filename as date followed by cellarName
          anchor.download = `${formattedDate}-${this.cellar.name}.xlsx`;

          anchor.href = url;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
        
        this.spreadsheetReady = true;
      
      if (local_debug || this.debug) console.log("-----> downloadInventory: STOP");
     },

      // async updateComment() {
      //   let cellar_id = "64f855a406bde70146f64cff"
      //   let cellar = {}
      //   let entries = []
      //   let transactions = []
        
      //   await Cellars.getone(cellar_id)
      //   .then(response => {
      //     if (typeof response == "object") {
      //       cellar = response
      //       console.log("updateComment: Cellar ", cellar.name, " FOUND.")
      //     } else {
      //       console.log("updateComment: Cellar NOT FOUND.")
      //     }
      //   })
      //   .catch(error => {
      //     console.log("updateComment: Cellar:", error)
      //   })

      //   for (let i = 0; i < cellar.entry_ids.length ; i++) {
      //     await Entries.getone(cellar.entry_ids[i])
      //     .then(response => {
      //       if (typeof response == "object") {
      //         entries.push(response)
      //         console.log("updateComment: Entry ",i, " ",response.name,"FOUND.")
      //       } else {
      //         console.log("updateComment: Entry with id ",cellar.entry_ids[i],"NOT FOUND.")
      //       }
      //     })
      //     .catch(error => {
      //       console.log("updateComment: Entry:", error)
      //     })
      //   }

      //   for (let i = 0; i < entries.length; i++ ){
      //     for (let j = 0; j < entries[i].transaction_ids.length; j++) {
      //       await Transactions.getone(entries[i].transaction_ids[j])
      //       .then (response => {
      //         if (typeof response == "object") {
      //           transactions.push(response)
      //           console.log("updateComment: Transaction ",i, " ",response.price,"FOUND.")
      //         } else {
      //           console.log("updateComment: Transaction with id ",entries[i].transaction_ids[j],"NOT FOUND.")
      //         }
      //       })
      //       .catch(error => {
      //         console.log("updateComment: Transaction:", error)
      //       })
      //     }
      //   }

      //   let transaction_id = 0
      //   for (let i =0; i < transactions.length; i++) {
      //     transaction_id = transactions[i]._id,
      //     transactions[i].description = "inventaris juli 2024"
      //     delete transactions[i]._id
      //     console.log("updateComment: updating ", transaction_id, " ", JSON.stringify(transactions[i]))
      //     await Transactions.update(transaction_id, transactions[i])
      //   }
        
      //   this.isLoading = false
      //   this.isChecking = false
      //   this.pricesChecked = true
      // },
      


    }
  }  
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.c-button-pink {
  background-color: rgb(195,147,130) !important;
  border-color: rgb(195,147,130) !important;
  color:white;
  font-size: clamp(10px,1vw,11px) !important;
  font-weight: bold !important;
  width: clamp(50px,5vw,80px);
}

.c-button-green {
  background-color: rgb(109,122,82) !important;
  border-color: rgb(109,122,82) !important;
  color:white;
  font-size: clamp(12px,1.2vw,16px) !important;
  font-weight: bold !important;
  width: clamp(120px,10vw,160px);
}

#container_ofBottles {

}
#container_perColor {
  position: relative;
  top: -10vh;
  left:0;
  z-index:-1;
}

#hide {
  position: relative;
  bottom: 3vh;
  background-color: white;
}

#container_more {
  background-color:white;
  position: relative;
  top: -45vh;
  margin-top:10px;
  z-index:0;
}

#container_perCountry {
  background-color:white;
  position: relative;
  top: -45vh;
  z-index:0;
}

#container_perVintage {
  position: relative;
  top: -45vh;
  z-index:0;
}

#container_recentTransactions{
  position: relative;
  top: -45vh;
  z-index:0;
}

.correct{
  position: relative;
  top: -45vh;
  margin-block-start: 0;
  margin-block-end: 0;
}

</style>