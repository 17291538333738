<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <br>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">search</p>
            <b-row class="justify-content-center">
              <p class="t-title">search bottles in {{cellar.name}}</p>
            </b-row>
            <br>
            <div class="w-box" style="width:80%">
              <CellarSearch_Desktop/>
            </div>
          </b-col>
        </b-row>      
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import Menu from './MenuDesktop.vue';
  import CellarSearch_Desktop from './CellarSearch_Desktop.vue';

  export default {
    name: "Search",
    components: {
      NoAccess,
      Menu,
      CellarSearch_Desktop,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },

      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("Search.mounted: START")
      if (this.currentCellarLoaded) {
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (this.debug) console.log("Search.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      } else {
        console.log("Search.mounted: FATAL ERROR cellar NOT loaded.")
      }
      this.isLoading = false;
      if (this.debug) console.log("Search.mounted: STOP")
    },
    methods:{
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>