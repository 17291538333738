<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>

        <p v-if="progress_count !== undefined && cellar && cellar.entry_ids && entries && (cellar.entry_ids.length + entries.length > 0)" >
          {{ Math.floor(progress_count * 100 / (cellar.entry_ids.length + entries.length + 10)) }}%
        </p>
        <p v-else-if="progress_count !== undefined && cellar && cellar.entry_ids && entries">
          10%
        </p>
        <p v-else>
          5%
        </p>

      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="padding:0" align="center">
            <br>
              <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">tools</p>
              <b-row class="justify-content-center">
                <p class="t-title">extra tools for your cellar '{{cellar.name }}'</p>
              </b-row>
              <br><br><br>
              <b-row style="width:300px">
                <b-col class ="w-cursor-click" @click="downloadInventory">
                  <div class="option-picture">
                    <b-avatar :src="require('@/assets/img/download-spreadsheet.jpg')" size="100px"></b-avatar>
                  </div>
                  <p class="t-title text-center" style="font-size:12px">download spreadsheet</p>
                </b-col>
                <b-col class ="w-cursor-click" @click="showModal_calcValue">
                  <div class="option-picture">
                    <b-avatar :src="require('@/assets/img/calculate-value.jpg')" size="100px"></b-avatar>
                  </div>
                  <p class="t-title text-center" style="font-size:12px">calculate value</p>
                </b-col>
              </b-row>
              <br><br><br>
              <b-row style="width:300px">
                <b-col class ="w-cursor-click" @click="checkConsistency">
                  <div class="option-picture">
                    <b-avatar :src="require('@/assets/img/check-consistency.jpg')" size="100px"></b-avatar>
                  </div>
                  <p class="t-title text-center" style="font-size:12px">check consistency</p>
                </b-col>
                <b-col class ="w-cursor-click" @click="syncEntries">
                  <div class="option-picture">
                    <b-avatar :src="require('@/assets/img/sync-entries.jpg')" size="100px"></b-avatar>
                  </div>
                  <p class="t-title text-center" style="font-size:12px">sync entries to wyne library</p>
                </b-col>
              </b-row>
              <br>
          </b-col>
        </b-row>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>

    <b-modal id="showValue" title="cellar value" @ok="closeProperly" @cancel="closeProperly">
      <b-container v-if="pricesChecked == false" class="text-center justify-content-center">
        <b-row>
          <b-col>
            <p class="w-text">Calculating the inventory value of a cellar is only possible if you have been consistent with your input of purchase transactions.</p>
            <p class="w-text">Here are examples of proper inputs:</p>
            <p class="w-text" style="padding-left:10px">12.34 EUR</p>
            <p class="w-text" style="padding-left:10px">16.56 USD, incl.</p>
            <p class="w-text" style="padding-left:10px">13.32 GBP, excl.</p>
            <p class="w-text">All purchase must be in the same currency and should be consistent in incl. or excl. VAT.</p>
            <p class="w-text">When VAT status is not mentioned it is considered to be incl.</p>
            <p class="w-text">Let us check ...</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <br><br><br>
            <h5>checking cellar transaction consistency</h5>
            <br>
            <b-spinner style="color: rgb(109,122,82) !important"
              variant="warning"
            ></b-spinner>
          </b-col>
        </b-row>
      </b-container>

      <b-container v-else class="text-center justify-content-center">
        <b-row>
          <b-col>
            <p class="w-subtitle">We have checked your cellar on consistency in purchase transaction price input.</p>
            <hr>
            <p v-if="this.currencies.length == 1" class="w-text">You have used {{ this.currencies.length }} currencies uniformly, which is perfect.</p>
            <p v-else class="w-text">You have used {{ this.currencies.length }} different currencies:</p>
            <div
                v-for="(currency) in currencies"
            >
              <div v-if="currency.currency == null" style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>blank</b> in {{ currency.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithCurrency(currency.currency)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
                <p class="w-text">The entries with blank currency will be considered to use your main currency.</p>
              </div>
              <div v-else style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>{{ currency.currency }}</b> in {{ currency.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithCurrency(currency.currency)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
              </div>
              <p class="w-text"><b>{{currency_comment}}</b></p>  
          </div>
          <hr>
          <p v-if="this.vatStati.length == 1" class="w-text">You have used {{ this.vatStati.length }} VAT status uniformly, which is perfect.</p>  
          <p v-else class="w-text">You have used {{vatStati.length }} different VAT status:</p>
            <div
                v-for="(vatStatus) in vatStati"
            >
              <div v-if="vatStatus.vatStatus == null" style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>blank</b> in {{ vatStatus.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithVATStatus(vatStatus.vatStatus)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
                <p class="w-text">The entries with blank VAT status will be considered to use your main VAT status.</p>
              </div>
              <div v-else style="text-align:left">
                <p class="w-text" style="display:inline-block">&bull; <b>{{ vatStatus.vatStatus }}</b> in {{ vatStatus.number }} purchase transactions</p>
                <p 
                  class="w-text w-cursor-click"
                  style="display:inline-block; margin-left:5px; color:rgb(109,122,82)"
                  @click="showEntriesWithVATStatus(vatStatus.vatStatus)"
                >
                  <b><u>(click here to show)</u></b>
                </p>
              </div>
              <p class="w-text"><b>{{vatStatus_comment}}</b></p>  
            </div>
            <hr>
            <p class="w-text">You have {{ this.zeroPrice }} zero-priced or blank-priced purchase transactions.</p>
            <hr>
            <div v-if="currency_OK && vatStatus_OK">
              <p class="w-subtitle">The value of your cellar is: <b style="font-size: 20px">{{formattedCellarValue}} {{ main_currency }}<span v-if="main_vatStatus != '' ">, {{ main_vatStatus }}</span></b></p>  
              <p v-if="this.inconsistent_entries.length > 0" class="w-text">However, you have {{this.inconsistent_entries.length}} entries with conflicting purchase entries that might cause errors in your results.</p>
            </div>
            <div v-else>
              <p class="w-subtitle">Please correct the issues described above and try again.</p>
            </div>  
          </b-col>
        </b-row>
      </b-container>   

      <b-form aria-autocomplete="off">
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="entriesWithCurrency" :title="EWC_title">
      <b-container  class="text-center justify-content-center">
        <div
          v-for="(entry) in this.entries_with_currency"
        >
          <p class="w-text">&bull; {{ entry.name }} {{ entry.type }} {{entry.vintage}}: {{ entry.price }} {{ entry.currency }}, {{ entry.vatStatus }}</p>
      </div>
      </b-container>
      <b-form aria-autocomplete="off">
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="entriesWithVATStatus" :title="EWV_title">
      <b-container  class="text-center justify-content-center">
        <div
          v-for="(entry) in this.entries_with_vatStatus"
        >
          <p class="w-text">&bull; {{ entry.name }} {{ entry.type }} {{entry.vintage}}: {{ entry.price }} {{ entry.currency }}, {{ entry.vatStatus }}</p>
      </div>
      </b-container>
      <b-form aria-autocomplete="off">
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="checkConsistancy" title="check cellar consistancy">
      <b-container class="text-center justify-content-center">
        <!-- <p class="w-text" style="padding-left:10px">This cellar has {{cellar.entry_ids.length}} entries.</p> -->
        <p class="w-text" style="padding-left:10px" v-if="cellar && cellar.entry_ids">
          This cellar has {{ cellar.entry_ids.length }} entries.
        </p>
        
        <p class="w-text" style="padding-left:10px">The units of this cellar contain {{count_entries_in_niche}} entries.</p>
        <hr>
        <p class="w-subtitle" v-if="cellar_entries_NOT_in_niche.length >0">Entries found in cellar, not found in niche</p>
        <div
          v-for="(entry, index) in cellar_entries_NOT_in_niche"
          v-bind:item="entry"
          v-bind:index="index"
          v-bind:key="entry._id" 
        >
          <div style="display:flex">
              <div style="order:1; width:100%; font-style:bold ;" class=" w-text">
                {{entry.number}} x {{entry.name}}
                <span v-if="entry.type"> ({{entry.type}})</span>
                <span v-if="entry.vintage"> {{entry.vintage}}</span>
              </div>
              <p 
                v-if="entry.number != 0"
                style="order:2;text-align:right;margin-left:5px;flex-basis: 10%"
                class="w-item-end w-cursor-click"
                v-b-tooltip.hover title="repair this entry"
                v-on:click.stop="onRepair_CellarEntry(entry)"
              >
                <font-awesome-icon icon="wrench" />
              </p>
              <p
                v-else 
                style="order:2;text-align:right;margin-left:5px;flex-basis: 10%"
                class="w-item-end w-cursor-click"
                v-b-tooltip.hover title="archive this entry"
                v-on:click.stop="onArchive(entry)"
              >
                <font-awesome-icon icon="archive" />
              </p>
            </div>
        </div>
        <p class="w-subtitle" v-if="niche_entries_NOT_in_cellar.length >0">Entries found in niche, not found in cellar</p>
        <div
          v-for="(entry, index) in niche_entries_NOT_in_cellar"
          v-bind:item="entry"
          v-bind:index="index"
          v-bind:key="entry._id" 
        >
          <div style="display:flex">
              <div style="order:1; width:100%; font-style:bold ;" class=" w-text">
                {{entry.number}} x {{entry.name}}
                <span v-if="entry.type"> ({{entry.type}})</span>
                <span v-if="entry.vintage"> {{entry.vintage}}</span>
              </div>
              <p 
                v-if="entry.number != 0"
                style="order:2;text-align:right;margin-left:5px;flex-basis: 10%"
                class="w-item-end w-cursor-click"
                v-b-tooltip.hover title="repair this entry"
                v-on:click.stop="onRepair_NicheEntry(entry)"
              >
                <font-awesome-icon icon="wrench" />
              </p>
              <p
                v-else 
                style="order:2;text-align:right;margin-left:5px;flex-basis: 10%"
                class="w-item-end w-cursor-click"
                v-b-tooltip.hover title="archive this entry"
                v-on:click.stop="onArchive(entry)"
              >
                <font-awesome-icon icon="archive" />
              </p>
            </div>
        </div>
      </b-container>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="syncEntries" title="sync entries to wyne library">
      <b-container class="text-center justify-content-center">
        <h5>in development</h5>
      </b-container>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right"  style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>  
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import Menu from './MenuMobile.vue';

  import Cellars from '../Cellars.js'
  import Niches from '../Niches.js'
  import Entries from '../Entries.js';
  import Transactions from '../Transactions.js';

  import C_Wines from '../C_Wines.js';
  import C_Wine_from_Entry_id from '../C_Wine_from_Entry_id.js'

  import wait from '../assets/js/wait.js';

  export default {
    name: "Cellar",
    components: {
      NoAccess,
      Menu
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      },
      currentNiches() {
        return this.$store.state.niches
      },
      currentNichesLoaded() {
        // Hack to be reviewed later
        // return this.$store.state.niches_loaded
        return false
      },
      formattedCellarValue() {
        return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.cellarValue);
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        cellar: {},
        cellar_id:0,

        niches: [],

        isChecking:false,

        pricesChecked:false,
        cellarLoaded:false,
        spreadsheetReady:false,

        cellarDoesNotExist:false,
        cellarInconsitent:false,

        entries:[],
        currencies:[],
        vatStati:[],
        zeroPrice:0,
        currency_OK:false,
        currency_comment:"",
        vatStatus_OK:false,
        vatStatus_comment:"",
        cellarValue:0,
        main_currency:"",
        main_vatStatus:"",

        entries_with_currency:[],
        EWC_title: "",
        entries_with_vatStatus:[],
        EWV_title:"",

        inconsistent_entries:[],
        count_entries_in_niche:0,

        cellar_entries_NOT_in_niche: [],
        niche_entries_NOT_in_cellar: [],

        progress_count:0,
      }
    }, 

    async mounted() {
      let local_debug = false

      if (local_debug || this.debug) console.log( "")
      if (local_debug || this.debug) console.log( "Tools.mounted: START")

      this.progress_count = this.progress_count + 10

      if (this.currentCellarLoaded) {
        if (local_debug || this.debug) console.log("Tools.mounted: CURRENT CELLAR LOADED")
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (local_debug || this.debug) console.log("Tools.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      } else {
        if (local_debug || this.debug) console.log("Tools.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
        if (typeof this.$route.query.cellar_id == "undefined") {
          if (local_debug || this.debug) console.log("Tools.mounted: FATAL ERROR - this.$route.query.cellar_id = ", this.$route.query.cellar_id)
          alert("Tools.mounted: FATAL ERROR - this.$route.query.cellar_id = " + this.$route.query.cellar_id)
          return
        }  
        this.cellar_id = this.$route.query.cellar_id
        await Cellars.getone(this.cellar_id)
        .then(response => {
          this.cellar = response
          delete this.cellar._id
          if (local_debug || this.debug) console.log("Tools.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
        })
        .catch(error =>{
          if (local_debug || this.debug) console.log("Tools.mounted: catch Cellar.getone with error: ", error)
        })
        this.progress_count = this.progress_count + 10

        this.$store.commit('setCellar',this.cellar)
        this.$store.commit('setCellarID',this.cellar_id)
        this.$store.commit('setCellarLoaded',true)

        if (local_debug || this.debug) console.log("Tools.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
      }

      await this.loadCellar()

      this.isLoading = false
      if (local_debug || this.debug) console.log( "Tools.mounted: START")
    },

    methods: {
      async loadCellar() {
        let local_debug = false

        let parsed_price = {}
        
        this.niches = []
        this.entries = []

        this.currencies = []
        this.vatStati = []
        this.zeroPrice = 0

        this.count_entries_in_niche = 0

        this.progress_count = 0

        let updateCellar = false
        let updateNiche = false

        if (local_debug || this.debug) console.log("--> loadCellar: START");
        
        if (Array.isArray(this.cellar.niche_ids)) {
          for (let i = 0; i < this.cellar.niche_ids.length; i++) {
            await Niches.getone(this.cellar.niche_ids[i])  
            .then(response=> {
              if (typeof response == "object") {
                this.count_entries_in_niche += response.entry_ids.length
                response.entries = []
                this.niches.push(response);
                if (local_debug || this.debug) console.log("--> loadCellar: Niche ", response, " with id ", response._id, " succesfully fetched from DB");
              } else {
                if (local_debug || this.debug) console.log("--> loadCellar: Niche with id ", this.cellar.niche_ids[i], " NOT FOUND in DB.");
              } 
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("--> loadCellar: await Niches.getone", error);
            })
            for (let j = 0; j < this.niches[i].entry_ids.length; j++) {
              await Entries.getone(this.niches[i].entry_ids[j])  
              .then(response=> {
                if (typeof response == "object") {
                  this.niches[i].entries.push(response);
                  if (local_debug || this.debug) console.log("--> loadCellar: Entry in Niche ", response, " with id ", response._id, " succesfully fetched from DB");
                } else {
                  if (local_debug || this.debug) console.log("--> loadCellar: Entry in Niche with id ", this.niches[i].entry_ids[j], " NOT FOUND in DB. We remove this id from niche.entry_ids.");
                  updateNiche = true
                  this.niches[i].entry_ids.splice(j, 1)
                } 
              })
              .catch(error=> {
                if (local_debug || this.debug) console.log("--> loadCellar: in Niche await Entries.getone", error);
              })
              if (updateNiche == true) {
                let niche_id = this.niches[i]._id
                delete this.niches[i]._id
                await Niches.update(niche_id,this.niches[i])
                .then(response => {
                  if (local_debug || this.debug) console.log("--> loadCellar: Niche succesfully updated.")
                })
                .catch(error => {
                  if (local_debug || this.debug) console.log("--> loadCellar: ERROR in Niches.update - ", error)
                })
                this.niches[i]._id = niche_id
                this.count_entries_in_niche--
                updateNiche = false
              }
            }
          }
        }
        
        if (Array.isArray(this.cellar.entry_ids)) {
          if (this.count_entries_in_niche != this.cellar.entry_ids.length) {
            if (local_debug || this.debug) console.log("--> loadCellar: The number of entries in niches is ", this.count_entries_in_niche, " while the number of entries in the cellar is ", this.cellar.entry_ids.length,".");
            if (local_debug || this.debug) console.log("--> loadCellar: This is INCONSISTENT.")
            if (local_debug || this.debug) console.log("--> loadCellar: STOP");
            this.cellarInconsitent = true
            this.cellarLoaded = true
            return
          } else {
            if (local_debug || this.debug) console.log("--> loadCellar: Entry count CONSISTENT.");
          }

          for (let i = 0; i < this.cellar.entry_ids.length; i++) {
            await Entries.getone(this.cellar.entry_ids[i])  
            .then(response=> {
              if (typeof response == "object") {
                response.purchases = []
                this.entries.push(response);
                if (local_debug || this.debug) console.log("--> loadCellar: Entry in Cellar ", response, " with id ", response._id, " succesfully fetched from DB");
              } else {
                if (local_debug || this.debug) console.log("--> loadCellar: Entry in Cellar with id ", this.cellar.entry_ids[i], " NOT FOUND in DB. We remove this id from cellar.entry_ids.");
                updateCellar = true
                this.cellar.entry_ids.splice(i, 1);
              }
              this.progress_count++ 
            })
            .catch(error=> {
              if (local_debug || this.debug) console.log("--> loadCellar: in Cellar await Entries.getone", error);
            })
          }
          if (updateCellar == true) {
            await Cellars.update(this.cellar_id,this.cellar)
            .then(response => {
              if (local_debug || this.debug) console.log("--> loadCellar: Cellar succesfully updated.")
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("--> loadCellar: ERROR in Cellars.update - ", error)
            })
            updateCellar = false
          }
          
          for (let i=0; i < this.entries.length; i++ ) {
            if (local_debug || this.debug) console.log("--> loadCellar: Analyzing entry", this.entries[i].name, " ",this.entries[i].type," ", this.entries[i].vintage);
            if (Array.isArray(this.entries[i].transaction_ids)) {
              for (let j=0;  j < this.entries[i].transaction_ids.length; j++) {
                await Transactions.getone(this.entries[i].transaction_ids[j])
                .then(response => {
                  if (typeof response == "object") {
                    if (response.number > 0){
                      this.entries[i].purchases.push({number:response.number,price_string:response.price})
                      if (local_debug || this.debug) console.log("--> loadCellar: -- Purchase ", response.number," x ",response.price, " added.");
                    }
                  } else {
                    if (local_debug || this.debug) console.log("--> loadCellar: -- Transaction with id ", this.entries[i].transaction_ids[j], " NOT FOUND in DB.");
                  }
                })
                .catch(error => {
                  if (local_debug || this.debug) console.log("--> loadCellar: await Transactions.getone", error);
                })
              }
              if ( Array.isArray(this.entries[i].purchases) ) {
                let N  = 0
                let NxP = 0
                for (let j=0; j < this.entries[i].purchases.length; j++) {
                  parsed_price = await this.parsePriceString(this.entries[i].purchases[j].price_string)
                  this.entries[i].purchases[j].price = parsed_price.price
                  this.entries[i].purchases[j].currency = parsed_price.currency
                  this.entries[i].purchases[j].vatStatus = parsed_price.vatStatus

                  if ( j== 0 ) {
                    this.entries[i].currency = parsed_price.currency
                    this.entries[i].vatStatus = parsed_price.vatStatus
                    this.entries[i].consistent = true
                  } else {
                    if ( this.entries[i].currency != parsed_price.currency || this.entries[i].vatStatus != parsed_price.vatStatus ) {
                      this.entries[i].consistent = false
                    }
                  }

                  if (!this.currencies.some(c => c.currency === parsed_price.currency)) {
                    this.currencies.push({currency: parsed_price.currency, number: 1});
                  } else {
                    for (let k = 0; k < this.currencies.length; k++) {
                        if (this.currencies[k].currency === parsed_price.currency) {
                            this.currencies[k].number++;
                            break; 
                        }
                    }
                  }

                  if (!this.vatStati.some(c => c.vatStatus === parsed_price.vatStatus)) {
                    this.vatStati.push({vatStatus: parsed_price.vatStatus, number: 1});
                  } else {
                    for (let k = 0; k < this.vatStati.length; k++) {
                        if (this.vatStati[k].vatStatus === parsed_price.vatStatus) {
                          this.vatStati[k].number++;
                            break; 
                        }
                    }
                  }

                  if (parsed_price.price == 0) this.zeroPrice++

                  if (typeof this.entries[i].purchases[j].number === "number") {
                    N += this.entries[i].purchases[j].number
                    NxP +=  this.entries[i].purchases[j].number * this.entries[i].purchases[j].price
                  }
                }
                let average_price = NxP/N
                this.entries[i].average_price = average_price
                if (local_debug || this.debug) console.log("--> loadCellar: -- average_price", average_price)
                if ( this.entries[i].consistent == false ) {
                  this.inconsistent_entries.push(this.entries[i])
                }
              }
              this.progress_count++
            } else {
              if (local_debug || this.debug) console.log("--> loadCellar: ERROR entry ", JSON.stringify(this.entries[i]), " HAS PROBLEMS.")
            }
          }

          if (local_debug || this.debug) console.log("--> loadCellar: Used currencies: ", JSON.stringify(this.currencies))
          if (local_debug || this.debug) console.log("--> loadCellar: Used vatStati: ", JSON.stringify(this.vatStati))
          if (local_debug || this.debug) console.log("--> loadCellar: Number of zero of null price transactions: ", this.zeroPrice)

        } else {
          if (local_debug || this.debug) console.log("--> loadCellar: ERROR cellar ", JSON.stringify(this.cellar), " HAS PROBLEMS.")
        }

        // for (let i=0; i < this.entries.length; i++) {
        //   if (this.entries[i].purchases.length > 1) console.log("TMP: ", i , " ", this.entries[i].name, " ", this.entries[i].type, " ", this.entries[i].vintage, " ",this.entries[i].average_price ," ",JSON.stringify(this.entries[i].purchases))
        // }

        this.isChecking = true
        this.pricesChecked = false

        this.cellarLoaded = true
        if (local_debug || this.debug) console.log("--> loadCellar: STOP");
      },

      async downloadInventory() {
        let local_debug = false;
        this.spreadsheetReady = false;

        const Excel = require("exceljs");
        if (local_debug || this.debug) console.log("--> downloadInventory: START");

        if (!this.pricesChecked) {
          await this.checkPricingConsistency()
        }

        let workbook = new Excel.Workbook();

        let titleSheet = workbook.addWorksheet('Title');
        
        const currentDateTime = new Date().toLocaleString();
        const title = `${this.cellar.name}`;
        titleSheet.mergeCells('D11:M11');
        titleSheet.mergeCells('D12:M12');
        titleSheet.mergeCells('D13:M13');
        titleSheet.mergeCells('D14:M14');
        titleSheet.mergeCells('D15:M15');
        titleSheet.getCell('D11').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
        titleSheet.getCell('D12').value = 'WYNECELLAR';
        titleSheet.getCell('D12').alignment = { horizontal: 'center' };
        titleSheet.getCell('D12').font = { size: 24, bold: true, color: { argb: 'FFFFFFFF' } };
        titleSheet.getCell('D12').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
        titleSheet.getCell('D13').value = title;
        titleSheet.getCell('D13').alignment = { horizontal: 'center' };
        titleSheet.getCell('D13').font = { size: 18, bold: true, color: { argb: 'FFFFFFFF' } };
        titleSheet.getCell('D13').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
        titleSheet.getCell('D14').value = `${currentDateTime}`;
        titleSheet.getCell('D14').alignment = { horizontal: 'center' };
        titleSheet.getCell('D14').font = { size: 16, bold: true, color: { argb: 'FFFFFFFF' } };
        titleSheet.getCell('D14').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };
        titleSheet.getCell('D15').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6D7A52' } };

        titleSheet.pageSetup = {
          paperSize: 9, // A4 paper
          orientation: 'landscape',
          printArea: 'A1:P15',
          fitToPage: true,
          fitToWidth: 1,
          fitToHeight: 1
        };

        const headerStyle = {
          font: {
            color: { argb: 'FFFFFFFF' }, 
            bold: true,
            size: 14
          },
          fill: {
            type: 'pattern',
            pattern: 'solid', // Background fill is solid
            fgColor: { argb: 'FF6D7A52' } // Background color set to rgb(109,122,82)
          }
        };

        const dataStyle = {
          font: {
            color: { argb: 'FF6D7A52' }, // Font color set to rgb(109,122,82)
            bold: false,
            size: 12
          },
          fill: {
            type: 'pattern',
            pattern: 'none' // No background fill
          },
        };
        
        let units = workbook.addWorksheet("Units")
        let units_header =  [
                              // {header:'_id', key:'_id'},
                              {header:'name', key:'name'},
                              {header:'code',key:'code'}
                            ]

        let units_list = []
        for ( let i = 0 ; i < this.niches.length ; i++ ) {
          units_list.push({
                            // _id:this.niches[i]._id, 
                            name:this.niches[i].name, 
                            code:this.niches[i].code
                          })
        }
        units_list.sort(function(a, b) {
            if(a.code < b.code) { return -1; }
            if(a.code > b.code) { return 1; }
            return 0;
        });

        units.columns = units_header.map(column => ({
          ...column,
          style: headerStyle 
        }));

        units.columns = units_header.map(column => ({
          ...column,
        }));

        units.getRow(1).eachCell((cell) => {
          cell.style = headerStyle;
        });

        units_list.forEach((row) => {
          const newRow = units.addRow(row); // Add the row and get the reference to the new row object
          newRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.style = dataStyle; // Apply the data style to each cell in the row
          });
        });

        units.pageSetup = {
          paperSize: 9, // A4 paper
          orientation: 'landscape',
          printArea: 'A1:J' + (units_list.length + 1),
          fitToPage: true,
          fitToWidth: 1,
          fitToHeight: 0,
          printTitlesRow: '1:1'
        };

        let entries = workbook.addWorksheet("Entries")
        let entries_header =  [
                                // {header:'_id',key:'_id'},
                                // {header:'unit_name',key:'unit_name'},
                                // {header:'unit_code',key:'unit_code'},
                                {header:'name',key:'name'},
                                {header:'type',key:'type'},
                                {header:'vintage',key:'vintage'},
                                {header:'number',key:'number'},
                                {header:'average_price',key:'average_price'},
                                {header:'currency',key:'currency'},
                                {header:'vatStatus',key:'vatStatus'},
                                {header:'color',key:'color'},
                                {header:'country',key:'country'},
                                {header:'region',key:'region'},
                                {header:'appellation',key:'appellation'},
                                {header:'classification',key:'classification'},
                                {header:'sparking',key:'sparkling'},
                                {header:'sweet',key:'sweet'},
                                {header:'grapes',key:'grapes'},
                                {header:'vines',key:'vines'},
                                {header:'yields',key:'yields'},
                                {header:'vinification',key:'vinification'},
                              ]
          let entries_list = []
          for ( let i = 0 ; i < this.entries.length ; i++ ) {
            if (this.entries[i].number >0) entries_list.push({
                              // _id:this.entries[i]._id, 
                              // unit_name:this.entries[i].unit_name, 
                              // unit_code:this.entries[i].unit_code,
                              name:this.entries[i].name,
                              type:this.entries[i].type,
                              vintage:this.entries[i].vintage,
                              number:this.entries[i].number,
                              average_price: parseFloat(this.entries[i].average_price).toFixed(2),
                              currency:this.entries[i].currency,
                              vatStatus:this.entries[i].vatStatus,
                              color:this.entries[i].color,
                              country:this.entries[i].country,
                              region:this.entries[i].region,
                              appellation:this.entries[i].appellation,
                              classification:this.entries[i].classification,
                              sparking:this.entries[i].sparking,
                              sweet:this.entries[i].sweet,
                              grapes:this.entries[i].grapes,
                              vines:this.entries[i].vines,
                              yields:this.entries[i].yields,
                              vinification:this.entries[i].vinification,
                            })
          }

          entries_list.sort((a, b) => {
            if (a.country < b.country) {
              return -1;
            }
            if (a.country > b.country) {
              return 1;
            }
            // If countries are the same, sort by region
            if (a.region < b.region) {
              return -1;
            }
            if (a.region > b.region) {
              return 1;
            }
            // If regions are the same, sort by appellation
            if (a.appellation < b.appellation) {
              return -1;
            }
            if (a.appellation > b.appellation) {
              return 1;
            }
            // If appellations are the same, sort alphabetically by name
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          entries.columns = entries_header.map(column => ({
            ...column,
          }));

          entries.getRow(1).eachCell((cell) => {
            cell.style = headerStyle;
          });

          entries_list.forEach((row) => {
            const newRow = entries.addRow(row); // Add the row and get the reference to the new row object
            newRow.eachCell({ includeEmpty: true }, (cell) => {
              cell.style = dataStyle; // Apply the data style to each cell in the row
            });
          });

          entries.pageSetup = {
            paperSize: 9, // A4 paper
            orientation: 'landscape',
            printArea: 'A1:K' + (entries_list.length + 1),
            fitToPage: true,
            fitToWidth: 1,
            fitToHeight: 0,
            printTitlesRow: '1:1'
          };

          if (local_debug || this.debug) console.log('--> downloadInventory: Excel workbook ready: ', workbook);

          let buff = workbook.xlsx.writeBuffer().then((data) => {
            var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');

            // Format the date as YYYY-MM-DD
            const formattedDate = new Date().toISOString().slice(0, 10);

            // Set the download filename as date followed by cellarName
            anchor.download = `${formattedDate}-${this.cellar.name}.xlsx`;

            anchor.href = url;
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          
          this.spreadsheetReady = true;
        
        if (local_debug || this.debug) console.log("--> downloadInventory: STOP");
      },

      async parsePriceString(input) {
        // Regex to capture the price, currency, and VAT status separately
        const priceRegex = /(\d+(\.\d{1,2})?)/;
        const currencyRegex = /([A-Z]{3})/;
        const vatRegex = /(incl[a-zA-Z.]*|excl[a-zA-Z.]*)[.,]?\s*$/i;

        const priceMatch = input.match(priceRegex);
        const currencyMatch = input.match(currencyRegex);
        const vatMatch = input.match(vatRegex);

        let price = 0;
        let currency = null;
        let vatStatus = null;

        if (priceMatch) {
            price = parseFloat(priceMatch[1]);
            // console.log(`Price match found. Input: ${input}, Parsed price: ${price}`);
        } else {
            // console.log(`No price match found. Defaulting to zero. Input: ${input}`);
        }

        if (currencyMatch) {
            currency = currencyMatch[0].toUpperCase();
            // console.log(`Currency match found. Input: ${input}, Parsed currency: ${currency}`);
        } else {
            // console.log(`No currency match found. Input: ${input}`);
        }

        if (vatMatch) {
            if (vatMatch[1].toLowerCase().startsWith('incl')) {
                vatStatus = 'incl';
            } else if (vatMatch[1].toLowerCase().startsWith('excl')) {
                vatStatus = 'excl';
            }
            // console.log(`VAT status match found. Input: ${input}, VAT status: ${vatStatus}`);
        } else {
            // console.log(`No VAT status match found. Input: ${input}`);
        }

        // console.log(`Input: ${input}, Parsed price: ${price}, currency: ${currency}, VAT status: ${vatStatus}`);

        return {
            price: price,
            currency: currency,
            vatStatus: vatStatus
        };
      },

      async checkPricingConsistency() {
        let local_debug = false

        if (local_debug || this.debug) console.log("--> checkPricingConsistency: START");

        let count_currencies = 0
        for (let i = 0; i < this.currencies.length; i++) {
          if (this.currencies[i].currency != null) {
            count_currencies++
          }
        }
        
        if (count_currencies > 1) {
          this.currency_OK = false
          this.currency_comment = "Your currencies are NOT consitant enough to evaluate the price of your cellar. Please correct and reduce the number of used currencies to one."
        } else {
          this.currency_OK = true
          this.currency_comment = "Your currencies are consitant enough to evalute the price of your cellar."
        }

        let count_vatStatus = 0
        for (let i = 0; i < this.vatStati.length; i++) {
          if (this.vatStati[i].vatStatus != null) {
            count_vatStatus++
          }
        }
        if (count_vatStatus > 1) {
          this.vatStatus_OK = false
          this.vatStatus_comment = "Your VAT status are NOT consitant enough to evaluate the price of your cellar. Please correct and reduce the number of used VAT status to one."
        } else {
          this.vatStatus_OK = true
          this.vatStatus_comment = "Your VAT status are consitant enough to evalute the price of your cellar."
        }

        this.pricesChecked = true

        if (local_debug || this.debug) console.log("--> checkPricingConsistency: STOP");
      },

      async showModal_calcValue() {
        this.isChecking = true
        this.pricesChecked = false
        await this.$root.$emit("bv::show::modal", "showValue");  
        await wait(3000)   
        if (!this.pricesChecked) {
          await this.checkPricingConsistency() 
        }
        this.isChecking  = false
        if (this.currency_OK && this.vatStatus_OK) await this.calculateValue()
      },

      async calculateValue() {
        let local_debug = false

        if (!this.currency_OK || !this.vatStatus_OK) {
          if (local_debug || this.debug) console.log("--> calculateValue: Cannot calculate value, since pricing is not consistent in currency or vat status.");
          return
        }

        this.cellarValue = 0
        if (local_debug || this.debug) console.log("--> calculateValue: START");
        
        for (let i = 0; i < this.entries.length; i++) {
          if (local_debug || this.debug) console.log("--> calculateValue: ", i, ": ",this.entries[i].number, " ", this.entries[i].name, " ", this.entries[i].type, " ", this.entries[i].vintage, " at " ,this.entries[i].average_price)
          this.cellarValue += this.entries[i].number*this.entries[i].average_price
        }
        this.main_currency = ""
        for ( let i = 0; i < this.currencies.length; i++ ){
          if (typeof this.currencies[i].currency == "string") {
            this.main_currency = this.currencies[i].currency
          }
        }

        this.main_vatStatus = ""
        for (let i = 0; i < this.vatStati.length; i++) {
          if ( typeof this.vatStati[i].vatStatus == "string")
          this.main_vatStatus = this.vatStati[i].vatStatus
        }

        if (local_debug || this.debug) console.log("--> calculateValue: STOP");
      },

      async showEntriesWithCurrency(currency) {
        this.entries_with_currency = []
        for (let i = 0; i < this.entries.length; i++) {
          if (this.entries[i].currency == currency) this.entries_with_currency.push(this.entries[i])
        }
        this.EWC_title = "entries with currency "+ currency
        this.$root.$emit("bv::show::modal", "entriesWithCurrency");
      },

      async showEntriesWithVATStatus(vatStatus) {
        this.entries_with_vatStatus = []
        for (let i = 0; i < this.entries.length; i++) {
          if (this.entries[i].vatStatus == vatStatus) this.entries_with_vatStatus.push(this.entries[i])
        }
        this.EWV_title = "entries with VAT status "+ vatStatus
        this.$root.$emit("bv::show::modal", "entriesWithVATStatus");
      },      

      async checkConsistency() {
        let local_debug = false

        let niche_entries_ids = []
        let all_cellar_entries_are_in_niche = true
        let all_niche_entries_are_in_cellar = true
        
        this.cellar_entries_NOT_in_niche = []
        this.niche_entries_NOT_in_cellar = []
        
        if (local_debug || this.debug) console.log("--> checkConsistency: START");
        await this.showModal_checkConsistancy()

        for ( let i = 0 ; i < this.niches.length ; i++ ) {
          for ( let j = 0 ; j < this.niches[i].entry_ids.length ; j++) {
            niche_entries_ids.push(this.niches[i].entry_ids[j])
          }
        }

        if (local_debug || this.debug) console.log("--> checkConsistency: Pass 1 - Are all cellar entries are in niche?");

        for ( let i = 0 ; i < this.cellar.entry_ids.length ; i++ ) {
          if ( niche_entries_ids.includes(this.cellar.entry_ids[i]) ) {
            // console.log("TMP - ",i, " ",this.cellar.entry_ids[i], " is found.")
          } else {
            // console.log("TMP - ",i, " ", this.cellar.entry_ids[i], " is NOT found in niche.")
            all_cellar_entries_are_in_niche = false
            await Entries.getone(this.cellar.entry_ids[i])
            .then(response => {
              if (typeof response == "object") {
                this.cellar_entries_NOT_in_niche.push(response)
                if (local_debug || this.debug) console.log("--> checkConsistency: cellar entry with id ", this.cellar.entry_ids[i]," ", response.number," x ",response.name," ",response.type," ",response.vintage," is found but does NOT belong to a niche.");
              } else {
                if (local_debug || this.debug) console.log("--> checkConsistency: cellar entry with id ", this.cellar.entry_ids[i] , " is not an object");
              }
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("--> checkConsistency: await Entries i=", i , " ", error);
            })
          }
        }

        if (local_debug || this.debug) console.log("--> checkConsistency: Pass 2 - Are all niche entries are in cellar?");

        for ( let i = 0 ; i < niche_entries_ids.length ; i++ ) {
          if ( this.cellar.entry_ids.includes(niche_entries_ids[i]) ) {
            // console.log("TMP - ",i, " ",niche_entries_ids[i], " is found.")
          } else {
            // console.log("TMP - ",i, " ", niche_entries_ids[i], " is NOT found in cellar.")
            all_niche_entries_are_in_cellar = false
            await Entries.getone(niche_entries_ids[i])
            .then(response => {
              if (typeof response == "object") {
                this.niche_entries_NOT_in_cellar.push(response)
                if (local_debug || this.debug) console.log("--> checkConsistency: cellar entry with id ", niche_entries_ids[i]," ", response.number," x ", response.name," ",response.type," ",response.vintage," is found but does NOT belong to a niche.");
              } else {
                if (local_debug || this.debug) console.log("--> checkConsistency: cellar entry with id ", niche_entries_ids[i] , " is not an object");
              }
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("--> checkConsistency: await Entries i=", i , " ", error);
            })
          }
        }

        if (local_debug || this.debug) console.log("--> checkConsistency: STOP");
      },

      async showModal_checkConsistancy() {
        this.$root.$emit("bv::show::modal", "checkConsistancy");
      },

      async syncEntries() {
        let local_debug = false
        if (local_debug || this.debug) console.log("--> syncEntries: START");

        let c_wine
        let existing_c_wine

        await this.$root.$emit("bv::show::modal", "syncEntries");

        for (let i=0 ; i < this.entries.length ; i++) {9
          c_wine = {}
          if (local_debug || this.debug) console.log("--> syncEntries: handling entries[",i,"] = ",this.entries[i].name);
          c_wine.name = this.entries[i].name
          c_wine.type = this.entries[i].type

          c_wine.country_id = this.entries[i].country_id
          c_wine.country = this.entries[i].country
          c_wine.region_id = this.entries[i].region_id
          c_wine.region = this.entries[i].region
          c_wine.appellation_id = this.entries[i].appellation_id
          c_wine.appellation = this.entries[i].appellation
          c_wine.classification_id = this.entries[i].classification_id
          c_wine.classification = this.entries[i].classification
          
          c_wine.color = this.entries[i].color
          c_wine.sparkling = this.entries[i].sparkling
          c_wine.sweet = this.entries[i].sweet

          c_wine.grapes = this.entries[i].grapes
          c_wine.vines = this.entries[i].vines
          c_wine.longevity = this.entries[i].longevity
          c_wine.vinification = this.entries[i].vinification
          if (this.entries[i].alcohol) c_wine.alcohol = this.entries[i].alcohol
          if (this.entries[i].aging) c_wine.aging = this.entries[i].aging

          c_wine.image_files = this.entries[i].image_files
          c_wine.user_id =  this.currentUserID
          c_wine.entry_id = this.entries[i]._id

          // if (local_debug || this.debug) {
          //   const jsonString = JSON.stringify(c_wine, (key, value) => {
          //     if (key === "image_files") {
          //       return undefined; // Exclude this property
          //     }
          //     return value;
          //   }, 2);

          //   if (local_debug || this.debug) console.log("--> syncEntries: c_wine =", jsonString);
          // }

          // if ( c_wine with entry_id does not exist) {}

          existing_c_wine = undefined
          await C_Wine_from_Entry_id.get(c_wine.entry_id)
          .then(response => {
            if ( response ) {
              existing_c_wine = response 
              if (local_debug || this.debug) console.log("--> syncEntries: c_wine found", response)
            } else {
              if (local_debug || this.debug) console.log("--> syncEntries: c_wine NOT found, we'll create one.")
            }
          })
          .catch(error => {
            if (local_debug || this.debug) console.log(error)
          })


          if (existing_c_wine == undefined) {
            await C_Wines.create(c_wine)
            .then(response => {
              if (local_debug || this.debug) console.log("----> syncEntries: C_Wine succesfully created : id = ", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("----> syncEntries: catch await C_Wines.create ERROR :", error);
            })
          } else {
            await C_Wines.update(existing_c_wine._id, c_wine)
            .then(response => {
              if (local_debug || this.debug) console.log("----> syncEntries: C_Wine with id = ", existing_c_wine._id,"succesfully updated with response = ", response);
            })
            .catch(error => {
              if (local_debug || this.debug) console.log("----> syncEntries: catch await C_Wines.create ERROR :", error);
            })
          }
        }
        if (local_debug || this.debug) console.log("--> syncEntries: STOP");
      },

      closeProperly() {
        this.isChecking = false
        this.pricesChecked = false
      },

      async onRepair_CellarEntry(entry) {
        let local_debug = false
        let niche = {}
        if (local_debug || this.debug) console.log("--> onRepair_CellarEntry: START");
        if (entry.niche_id.length == 24) {
          await Niches.getone(entry.niche_id)
          .then(response => {
            if ( typeof response == "object") {
              niche = response
              if (local_debug || this.debug) console.log("--> onRepair_CellarEntry: niche with niche_id = ",entry.niche_id,  " FOUND.")
            } else {
              if (local_debug || this.debug) console.log("--> onRepair_CellarEntry: ERROR - niche with niche_id = ",entry.niche_id,  " returns ", response)
            }
          })
          .catch(error => {
            if (local_debug || this.debug) console.log('--> onRepair_CellarEntry: catch await Niches.getone ERROR : ' + error);
          })

          if (! niche.entry_ids.includes(entry._id) ) { 
            niche.entry_ids.push(entry._id)
            console.log("TMP entry.niche_id = ", entry.niche_id)
            // Update this.niche array
            for ( let i = 0 ; i < this.niches.length ; i++ ) {
              if ( this.niches[i]._id == entry.niche_id) {
                this.niches[i] = niche
              }
            }
            // Update database
            delete niche._id
            await Niches.update(entry.niche_id, niche)
            .then(response => {
              if (local_debug || this.debug) console.log("--> onRepair_CellarEntry: niche ",niche," with id ", entry.niche_id, " updated with response : ", response)
            })
            .catch(error => {
              if (local_debug || this.debug) console.log('--> onRepair_CellarEntry: catch await Niches.update ERROR : ' + error);
            })

          } else {
            if (local_debug || this.debug) console.log('--> onRepair_CellarEntry: entry with id ',entry._id, ' is already part of the niche.entry_ids = ', JSON.stringify(niche.entry_ids));
          }

        } else {
          if (local_debug || this.debug) console.log("--> onRepair_CellarEntry: ERROR - INVALID this.entry.niche_id ",entry.niche_id)
        }

        if (local_debug || this.debug) console.log("--> onRepair_CellarEntry: STOP");
        
        await this.checkConsistency()
      },

      async onRepair_NicheEntry(entry) {
        let local_debug = false
       
        if (local_debug || this.debug) console.log("--> onRepair_NicheEntry: START");

        if (!this.cellar.entry_ids.includes(entry._id)) {
          // Update this.cellar.entry_ids array
          this.cellar.entry_ids.push(entry._id)
          // Update store
          this.$store.commit('setCellar',this.cellar)
          // Update database
          await Cellars.update(this.cellar_id,this.cellar)
          .then(response => {
            if (local_debug || this.debug) console.log("--> onRepair_NicheEntry: cellar ",this.cellar," with id ", this.cellar_id, " updated with response : ", response)
          })
          .catch(error => {
            if (local_debug || this.debug) console.log('--> onRepair_NicheEntry: catch await Cellars.update ERROR : ' + error);
          }) 

        } else {
          if (local_debug || this.debug) console.log('--> onRepair_NicheEntry: entry with id ',entry._id, ' is already part of the this.cellar.entry_ids = ', JSON.stringify(this.cellar.entry_ids));
        }

        if (local_debug || this.debug) console.log("--> onRepair_NicheEntry: STOP");

        await this.checkConsistency()
      },

      async onArchive(entry) {
        let local_debug = false
        if (local_debug || this.debug) console.log("--> onRepair_CellarEntry: START");
        
        // 1. Add the entry to archive_ids
        // console.log("TMP1 this.cellar.archive_ids.length = ", this.cellar.archive_ids.length)
        this.cellar.archive_ids.push(entry._id)
        // console.log("TMP2 this.cellar.archive_ids.length = ", this.cellar.archive_ids.length)

        // 2. Remove the entry from entry_ids
        console.log("TMP1 this.cellar.entry_ids.length = ", this.cellar.entry_ids.length)
        let index = this.cellar.entry_ids.indexOf(entry._id)
        if (index !== -1) {
          this.cellar.entry_ids.splice(index,1)
        } else {
          if (local_debug || this.debug) console.log("--> onArchive: ERROR ", entry_id," NOT FOUND in entry_ids.");
        }
        console.log("TMP2 this.cellar.entry_ids.length = ", this.cellar.entry_ids.length)

        // 3. Save cellar in DB and store
        await Cellars.update(this.cellar_id, this.cellar)
        .then(response => {
          if (local_debug || this.debug) console.log("--> onArchive: cellar ",this.cellar," with id ", this.cellar_id, " updated with response : ", response)
        })
        .catch(error => {
          if (local_debug || this.debug) console.log('--> onArchive: catch await Cellars.update ERROR : ' + error);
        })
        this.$store.commit('setCellar',this.cellar)

        // 4. Check entry.archived_date
        if (typeof entry.archived_date == "undefined" || entry.archived_date.length != 10) {
          let today = new Date();
          entry.archived_date = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");
          entry.niche_id = 'archived'
          let entry_id = entry._id
          delete entry._id 
          await Entries.update(entry_id,entry)
          .then(response => {
            if (local_debug || this.debug) console.log("--> onArchive: entry ",entry," with id ", entry_id, " updated with response : ", response)
          })
          .catch(error => {
            if (local_debug || this.debug) console.log('--> onArchive: catch await Entries.update ERROR : ' + error);
          })
        }

        await this.checkConsistency()
        
        if (local_debug || this.debug) console.log("--> onArchive: STOP");
      },

      doNothing() {
      }, 
    }
  }       
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.c-image-part {
  background-image: url("../assets/img/niche-picture.jpg")
}
</style>
